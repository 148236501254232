import { Period } from "@backend/enums/period";
import { Sheet } from "../types/sheet";

let apiUrl: string = "http://localhost:3000/";
if (process.env.REACT_APP_ENV === "production") {
  apiUrl = "https://www.boybou.nl/api/";
} else if (process.env.REACT_APP_ENV === "testing") {
  apiUrl = "https://www.boybou.nl:5001/api/";
}

export const getDistinctAccountNumbersEndpoint = `${apiUrl}account-data-points/distinct-account-numbers`;

export const getDistinctAccountTradeAccountNumbersEndpoint = `${apiUrl}account-trades/distinct-account-numbers`;
export const getAccountTradesByAccountNumberEndpoint = (
  accountNumber: number
) => `${apiUrl}accounts/${accountNumber}/trades`;

export function getAccountDataPointsByAccountNumberEndpoint(
  accountNumber: number
) {
  return `${apiUrl}account-data-points/${accountNumber}`;
}

export const googleLoginEndpoint = `${apiUrl}authorization/google-login`;

export const loginEndpoint = `${apiUrl}authorization/login`;

export const getHealthEndpoint = `${apiUrl}health`;

export const getSymbolsEndpoint = `${apiUrl}symbols/`;

// export function getTradesEndpoint(marketId,maxTradeDuration,startMonth,wpFilter,plFilter){
//     return `${apiUrl}seasonal/trades/${marketId}/${maxTradeDuration}/${startMonth}/${wpFilter}/${plFilter}`
// }

export const getTradesEndpoint = `${apiUrl}trades/`;

export function getTradeEndpoint(id: string) {
  return `${apiUrl}trades/${id}`;
}

export const getRealDistinctTradesEndpoint = `${apiUrl}trades/distinct`;

export const putTradesWithYearsEndpoint = `${apiUrl}trades/with-years`;

export const getDistinctTradesEndpoint = `${apiUrl}trades/distinctTrades`;

export const getTradeTableColumnsEndpoint = `${apiUrl}users/settings/trade-table-columns`;

export const putUserMeEndpoint = `${apiUrl}users/me`;
export const getUserMeEndpoint = `${apiUrl}users/me`;
export const postRiskProfileEndpoint = `${apiUrl}users/me/risk-profile`;
export function putRiskProfileEndpoint(id: number) {
  return `${apiUrl}users/me/risk-profile/${id}`;
}
export function deleteRiskProfileEndpoint(id: number) {
  return `${apiUrl}users/me/risk-profile/${id}`;
}

export function putUserEndpoint(id: number) {
  return `${apiUrl}users/${id}`;
}

export const getUsersEndpoint = `${apiUrl}users/`;
export const postUserEndpoint = `${apiUrl}users/`;
export const postTokenEndpoint = `${apiUrl}users/me/token`;
export const deleteTokenEndpoint = `${apiUrl}users/me/token`;

export const getMarketsEndpoint = `${apiUrl}markets`;

export const getMarketsWithQuotesEndpoint = `${apiUrl}markets/with-quotes`;

export const postMarketsEndpoint = `${apiUrl}markets`;

export function putMarketEndpoint(id: number) {
  return `${apiUrl}markets/${id}`;
}

export function getMarketEndpoint(id: number) {
  return `${apiUrl}markets/${id}`;
}

export const postMarketCategoryEndpoint = `${apiUrl}market-categories/`;

export const calculateMarketsEndpoint = `${apiUrl}markets/calculate`;

export function calculateMarketEndpoint(id: number) {
  return `${apiUrl}markets/calculate/${id}`;
}

export const getSimpleFxMarketsEndpoint = `https://simplefx.com/utils/instruments.json`;

export const getSimpleFxMarketsViaBackendEndpoint = `${apiUrl}misc/simplefx-markets`;
export const getParsedSimpleFxMarketsViaBackendEndpoint = `${apiUrl}misc/parsed-simplefx-markets`;

export const getMrSheetTokenEndpoint = `${apiUrl}authorization/mr-sheet-token`;

export function getCryptoXUsdPriceEndpoint(id: string) {
  return `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`;
}

export const postEquitiesEndpoint = `${apiUrl}equity/`;

export const getMarketsLastUpdatedEndpoint = `${apiUrl}markets/last-updated`;

export function deleteMarketEndpoint(id: number) {
  return `${apiUrl}markets/${id}`;
}

export function getTradeWithCalculationsEndpoint(id: string) {
  return `${apiUrl}trades/${id}/with-calculations`;
}

export function getTradeWithRelationsEndpoint(id: string) {
  return `${apiUrl}trades/${id}/with-relations`;
}

export function getLatestEquityBySheetIdAndDateEndpoint(
  sheetId: number,
  dateString: string
) {
  return `${apiUrl}equity/${sheetId}/${dateString}/latest`;
}

export function getEarliestEquityBySheetIdAndDateEndpoint(
  sheetId: number,
  dateString: string
) {
  return `${apiUrl}equity/${sheetId}/${dateString}/earliest`;
}

export function getLatestAccountDataPointByAccountNumberAndDateEndpoint(
  accountNumber: number,
  dateString: string
) {
  return `${apiUrl}account-data-points/${accountNumber}/${dateString}/latest`;
}

export function getEarliestAccountDataPointByAccountNumberAndDateEndpoint(
  accountNumber: number,
  dateString: string
) {
  return `${apiUrl}account-data-points/${accountNumber}/${dateString}/earliest`;
}
export function getAccountPerformanceEndpoint(accountNumber: number) {
  return `${apiUrl}account-data-points/${accountNumber}/performance`;
}

export const getEquitiesEndpoint = `${apiUrl}equity/`;

export const getDistinctSheetIdsEndpoint = `${apiUrl}equity/distinct-sheet-ids`;

export function getEquityBySheetIdEndpoint(sheetId: number) {
  return `${apiUrl}equity/${sheetId}`;
}

export const postRefreshTokenEndpoint = `${apiUrl}authorization/refresh-token`;

export const getCorrelationsEndpoint = `${apiUrl}markets/correlations`;

export const calculateCorrelationsEndpoint = `${apiUrl}markets/calculate-correlation`;

export const getSheetTradesEndpoint = `${apiUrl}sheet-trades/`;
export const deleteSheetTradeEndpoint = (sheetId: number, tradeId: string) =>
  `${apiUrl}sheet-trades/${sheetId}/${tradeId}`;

export const getCustomCorrelationsEndpoint = `${apiUrl}markets/custom-correlations`;

export function getQuotesByMarketIdAndPeriodEndpoint(
  marketId: number,
  period: Period
) {
  return `${apiUrl}quotes/?marketId=${marketId}&period=${period}`;
}

export const getUsdExchangeRatesEndpoint = `${apiUrl}misc/usd-exchange-rates`;

export function getBestTradesOfMarketEndpoint(marketId: number) {
  return `${apiUrl}markets/${marketId}/best-trades`;
}

export function getAveragePercentageChangeEndpoint(
  marketId: number,
  length: number
) {
  return `${apiUrl}markets/${marketId}/average-percentage-change/${length}`;
}
export function getAverageAbsolutePercentageChangeEndpoint(
  marketId: number,
  length: number
) {
  return `${apiUrl}markets/${marketId}/average-absolute-percentage-change/${length}`;
}

export const toggleQuoteCollectionEndpoint = (quoteCollectionId: number) =>
  `${apiUrl}quote-collections/${quoteCollectionId}/toggle`;
export const getQuoteCollectionsEndpoint = `${apiUrl}quote-collections/`;
export const getQuoteCollectionsMetadataEndpoint = `${apiUrl}quote-collections/metadata`;

export const postQuoteCollectionEndpoint = `${apiUrl}quote-collections/`;

export const getDataSourcesEndpoint = `${apiUrl}data-sources/`;

export const getDataSourcesMetadataEndpoint = `${apiUrl}data-sources/metadata`;

export const postDataSourceEndpoint = `${apiUrl}data-sources/`;

export const postRefreshDataSourceMetadataEndpoint = `${apiUrl}data-sources/refresh-metadata`;

export function putDataSourceEndpoint(dataSourceId: number) {
  return `${apiUrl}data-sources/${dataSourceId}`;
}

export function deleteDataSourceEndpoint(dataSourceId: number) {
  return `${apiUrl}data-sources/${dataSourceId}`;
}
export const postQuotesEndpoint = `${apiUrl}quotes/`;

export function getQuoteCollectionEndpoint(
  quoteCollectionId: number,
  quotes = false
) {
  return `${apiUrl}quote-collections/${quoteCollectionId}?${
    quotes ? "quotes=true" : ""
  }`;
}

export function getDataSourceEndpoint(id: number) {
  return `${apiUrl}data-sources/${id}`;
}

export const getQuoteCollectionsHealthEndpoint = `${apiUrl}health/quote-collections`;

export const postMergeQuoteCollectionsEndpoint = `${apiUrl}quote-collections/merge`;

export const postQuoteCollectionsMatchesCountEndpoint = `${apiUrl}quote-collections/matches-count`;
export const postQuoteCollectionsSimilarityEndpoint = `${apiUrl}quote-collections/similarity`;
export const compareQuoteCollectionsEndpoint = `${apiUrl}quote-collections/compare`;

export const postDividendEndpoint = `${apiUrl}dividend/`;

export const souhaibV1TradeSelectionEndpoint = (sheetId: number, date: Date) =>
  `${apiUrl}trade-selection/souhaib-v1/${sheetId}/${date.toISOString()}`;
export const sheetETradeSelectionEndpoint = `${apiUrl}trade-selection/sheet-e`;
export const sheetDTradeSelectionEndpoint = `${apiUrl}trade-selection/sheet-d`;
export const sheetSouhaibV1TradeSelectionEndpoint = (
  date: Date,
  sheet: Sheet,
  blackListedTradeIds: string
) =>
  `${apiUrl}trade-selection/souhaib-v1?sheetId=${
    sheet.id
  }&date=${date.toISOString()}&blackListedTradeIds=${blackListedTradeIds}&minAvgBars=${
    sheet.minAvgBars
  }&minNumberOfTrades=${sheet.minNumberOfTrades}&minPlRatio=${
    sheet.minPlRatio
  }&minWinPercentage=${sheet.minWinPercentage}&maxBiggestLossStreak=${
    sheet.maxBiggestLossStreak
  }`;

export const getSwapSymbolsEndpoint = `${apiUrl}swap-entries/symbols`;
export const postSwarmEndpoint = `${apiUrl}swarms/`;
export const getMeSwarmsEndpoint = `${apiUrl}swarms/me`;
export const putSwarmEndpoint = (swarmId: number) =>
  `${apiUrl}swarms/${swarmId}`;
export const getSwarmInstanceEndpoint = (swarmId: number) =>
  `${apiUrl}swarms/${swarmId}/instance`;
export const postAgentCommandEndpoint = `${apiUrl}swarms/agent-command`;
export const postAgentCommandEndpointString = `${apiUrl}swarms/agent-command-string`;
export const getSwarmLogsEndpoint = (swarmId: number, limit: number = 0) =>
  `${apiUrl}swarms/${swarmId}/logs${limit !== 0 ? `?limit=${limit}` : ""}`;
export const getSwarmWithLogsEndpoint = (swarmId: number) =>
  `${apiUrl}swarms/${swarmId}/with-logs`;
export const getSwarmEndpoint = (swarmId: number) =>
  `${apiUrl}swarms/${swarmId}`;
export const postSwarmRestartEndpoint = (swarmId: number) =>
  `${apiUrl}swarms/${swarmId}/restart`;
