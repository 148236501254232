import {
  setQuoteCollections,
  setQuoteCollectionsMetadata,
} from "../reducers/quote-collections-reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  getQuoteCollections,
  getQuoteCollectionsMetadata,
} from "../requests/quote-collections-requests";

export function getQuoteCollectionsAction() {
  return (dispatch: Dispatch) => {
    getQuoteCollections()
      .then((response) => dispatch(setQuoteCollections(response.data.data)))
      .catch((error) => console.error(error));
  };
}

export function getQuoteCollectionsMetadataAction() {
  return (dispatch: Dispatch) => {
    getQuoteCollectionsMetadata()
      .then((response) =>
        dispatch(setQuoteCollectionsMetadata(response.data.data))
      )
      .catch((error) => console.error(error));
  };
}
