import React, { useEffect, useState } from "react";
import { postQuoteCollectionSimilarity } from "../../../requests/quote-collections-requests";

export default function QuoteCollectionSimilarity({
  sourceQuoteCollectionId,
  targetQuoteCollectionId,
}: {
  sourceQuoteCollectionId: number;
  targetQuoteCollectionId: number;
}) {
  const [similarity, setSimilarity] = useState<number>(0);

  useEffect(() => {
    postQuoteCollectionSimilarity({
      sourceQuoteCollectionId,
      targetQuoteCollectionId,
    }).then((response) => setSimilarity(response.data.data));
  }, [sourceQuoteCollectionId, targetQuoteCollectionId]);

  if (similarity)
    return (
      <span style={similarity > 50 ? { color: "green" } : { color: "red" }}>
        {similarity.toPrecision(4)}%
      </span>
    );
  return <></>;
}
