import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../..";

export default function MultipleQuoteCollectionsSelect({
  selectedOptions,
  setSelectedOptions,
  filterFunction,
}: any) {
  const quoteCollectionOptions = useSelector((state: RootState) => {
    let options: any = filterFunction
      ? state.quoteCollectionsState.quoteCollections
          .filter(filterFunction)
          .map((quoteCollection) => {
            let market = state.marketsState.markets.find(
              (market) => market.id === quoteCollection.marketId
            );
            let dataSource = state.dataSourcesState.dataSources.find(
              (dataSource) => dataSource.id === quoteCollection.dataSourceId
            );

            return {
              value: quoteCollection.id,
              label: `${quoteCollection.id},${market?.symbol},${quoteCollection.period},${dataSource?.name}`,
            };
          })
      : state.quoteCollectionsState.quoteCollections.map((quoteCollection) => {
          let market = state.marketsState.markets.find(
            (market) => market.id === quoteCollection.marketId
          );
          let dataSource = state.dataSourcesState.dataSources.find(
            (dataSource) => dataSource.id === quoteCollection.dataSourceId
          );

          return {
            value: quoteCollection.id,
            label: `${quoteCollection.id},${market?.symbol},${dataSource?.name}`,
          };
        });
    options = options.reverse();
    options.push({
      value: options.filter((option: any) => option.label !== "ALL"),
      label: "ALL",
    });
    return options.reverse();
  });
  return (
    <Select
      isMulti={true}
      value={selectedOptions}
      onChange={(newSelectedOptions) => {
        let allOption = newSelectedOptions.find(
          (option) => option.label === "ALL"
        );
        if (allOption) {
          setSelectedOptions(allOption.value);
        } else {
          setSelectedOptions(newSelectedOptions);
        }
      }}
      options={quoteCollectionOptions}
    />
  );
}
