import React, { FormEvent, useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import stringSimilarity from "string-similarity";
import QuotesTable from "../tables/quotes-table";
import { RootState } from "../..";
import SimpleFxSymbolSelect from "../selects/simple-fx-symbol-select";
import { createMarket } from "../../requests/markets-requests";
import { Market } from "@backend/entities/market";

export function MarketCreate() {
  const dispatch = useDispatch();

  const parsedSimpleFxMarkets = useSelector(
    (state: RootState) => state.simpleFxState.parsedSimpleFxMarkets
  );

  const [symbol, setSymbol] = useState("");
  const [security, setSecurity] = useState("");
  const [quotes, setQuotes] = useState<any>(null);

  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
    encodig: "",
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/*<CsvDropzone onFilesReceived={handleFilesReceived} />*/}
        {/*<input placeholder={"enter symbol name"} onChange={(event)=>onChangeFunctions.symbol(event.target.value)} value={formValues.symbol}/>*/}
        <SimpleFxSymbolSelect value={symbol} setValue={handleSymbolChange} />

        <label>security: {security}</label>

        <input type={"submit"} value={"create"} />
        {/*<QuotesTable quotes={quotes} />*/}
      </form>
    </div>
  );

  function handleSymbolChange(value: any) {
    setSymbol(value);
    let market = parsedSimpleFxMarkets.find((market) => {
      return market.symbol === value;
    });
    let security = market ? market.security : "";
    setSecurity(security);
  }

  function handleFilesReceived(files: any) {
    let fileName = files[0].name.split(".csv")[0].toUpperCase();
    let csvContent = Papa.parse(files[0].data, papaparseOptions);
    setQuotes(csvContent.data);

    let symbols = parsedSimpleFxMarkets.map((simpleFxMarket) => {
      return simpleFxMarket.symbol;
    });
    let bestMatchObject = stringSimilarity.findBestMatch(fileName, symbols);
    if (bestMatchObject.bestMatch) {
      handleSymbolChange(bestMatchObject.bestMatch.target);
    }


  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (symbol && security) createMarket({ symbol, security } as Market);
  }
}
