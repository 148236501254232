import React, { useEffect, useState } from "react";
import md5 from "md5";
// import { Role } from "@backend/entities/user";
import { putUser } from "../../../requests/users-requests";
import RoleSelect from "../../selects/role-select";
import { User } from "@backend/entities/user";
import { useDispatch } from "react-redux";
import { getUsersAction } from "../../../actions/users-actions";
import { digestMessageSHA256 } from "../../../functions/hash";

export default function UserDetail({ user }: { user: User }) {
  const [editing, setEditing] = useState(false);

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState<any>("test");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUsername(user.username);
      setRole(user.role);
    }
  }, [user]);

  if (editing) {
    return (
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>
          <input
            type={"email"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </td>
        <td>
          <input
            type={"text"}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </td>
        <td>
          <RoleSelect value={role} onChange={(value) => setRole(value)} />
        </td>
        <td>
          <input
            type={"password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </td>
        <td>
          <button onClick={update}>save</button>
        </td>
      </tr>
    );
  }

  return (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>{user.email}</td>
      <td>{user.username}</td>
      <td>{user.role}</td>
      <td>...</td>
      <td>
        <button onClick={() => setEditing((old) => !old)}>edit</button>
      </td>
    </tr>
  );

  async function update() {
    let updatedUser = Object.assign({}, user, { email, username, role });
    if (password) {
      updatedUser.password = await digestMessageSHA256(password);
    }

    putUser(user.id, updatedUser)
      .then((response) => {
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function reset() {
    setEmail("");
    setUsername("");
    setPassword("");
    setRole("test");
    setEditing(false);
    dispatch(getUsersAction());
  }
}
