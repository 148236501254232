import { Market } from "@backend/entities/market";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type MarketsState = {
  markets: Market[];
};

const initialState = {
  markets: [],
} as MarketsState;

export const marketsStateSlice = createSlice({
  name: "marketsState",
  initialState,
  reducers: {
    setMarkets(state, action: PayloadAction<Market[]>) {
      state.markets = action.payload;
    },
    deleteMarket(state, action: PayloadAction<number>) {
      state.markets = state.markets.filter(
        (market) => market.id !== action.payload
      );
    },
  },
});

export const marketsState = marketsStateSlice.reducer;
export const { setMarkets, deleteMarket } = marketsStateSlice.actions;
