import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpinnerIcon } from "../icons/spinner-icon";
import BasicEditableDetailView from "../detail-view/basic-editable-detail-view";
import DataSourceMarketCoverage from "./data-source-market-coverage";
import {
  getDataSource,
  putDataSource,
} from "../../requests/data-source-requests";
import { DataSource } from "@backend/entities/data-source";

export default function DataSourceDetailView() {
  const { dataSourceId } = useParams();

  const [dataSource, setDataSource] = useState<DataSource | null>(null);

  useEffect(() => {
    if (dataSourceId)
      getDataSource(parseInt(dataSourceId))
        .then((response) => setDataSource(response.data.data))
        .catch((error) => console.error(error));
  }, []);

  return <div>{renderDataSource()}</div>;

  function renderDataSource() {
    if (!dataSource) return <SpinnerIcon />;
    return (
      <div>
        <h1>Data Source:</h1>
        <BasicEditableDetailView
          entity={dataSource}
          config={{
            maxArrayLength: 10,
            changeableFields: ["link", "name"],
            editable: true,
            saveFunction: async (changedEntity: DataSource) => {
              if (!dataSourceId) return false;
              await putDataSource(parseInt(dataSourceId), changedEntity);
              return true;
            },
          }}
        />
        <DataSourceMarketCoverage dataSource={dataSource} />
      </div>
    );
  }
}
