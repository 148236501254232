import { QuoteCollection } from "@backend/entities/quote-collection";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../..";
import { toggleQuoteCollection } from "../../requests/quote-collections-requests";

export default function QuoteCollectionRow({
  quoteCollection,
}: {
  quoteCollection: QuoteCollection;
}) {
  const market = useSelector((state: RootState) =>
    state.marketsState.markets.find(
      (market) => market.id === quoteCollection.marketId
    )
  );
  const quoteCollectionMetadata = useSelector((state: RootState) =>
    state.quoteCollectionsState.quoteCollectionsMetadata.find(
      (quoteCollectionMetadata) =>
        quoteCollectionMetadata.id === quoteCollection.id
    )
  );

  const dataSource = useSelector((state: RootState) => {
    return state.dataSourcesState.dataSources.find(
      (dataSource) => dataSource.id === quoteCollection.dataSourceId
    );
  });

  return (
    <tr
      style={{
        textDecorationLine: quoteCollection.disabled ? "line-through" : "none",
      }}
    >
      <td>{quoteCollection.id}</td>
      <td>{market?.symbol}</td>
      <td>{quoteCollection.period}</td>
      <td>{dataSource?.name}</td>
      {quoteCollectionMetadata && quoteCollectionMetadata.earliestQuote && (
        <>
          <td>{quoteCollectionMetadata?.earliestQuote?.toString()}</td>
          <td>{quoteCollectionMetadata?.latestQuote?.toString()}</td>
          <td>
            {new Date(quoteCollectionMetadata.latestQuote).getFullYear() -
              new Date(quoteCollectionMetadata.earliestQuote).getFullYear()}
          </td>
        </>
      )}

      <td>
        <Link to={`/quote-collections/${quoteCollection.id}`}>view</Link>
      </td>
      <td>
        <button
          onClick={(event) => {
            toggleQuoteCollection(quoteCollection.id)
              .then((response) => {})
              .catch((error) => console.log(error));
          }}
        >
          {quoteCollection.disabled ? "enable" : "disable"}
        </button>
      </td>
    </tr>
  );
}
