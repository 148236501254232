import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InformationState = {
  marketsLastUpdated: Date | null;
};

const initialState = {
  marketsLastUpdated: null,
} as InformationState;

const informationalStateSlice = createSlice({
  name: "informationalState",
  initialState,
  reducers: {
    setMarketsLastUpdated(state, action: PayloadAction<Date>) {
      state.marketsLastUpdated = action.payload;
    },
  },
});

export const informationalState = informationalStateSlice.reducer;
export const { setMarketsLastUpdated } = informationalStateSlice.actions;
