import { Dispatch } from "@reduxjs/toolkit";
import { setSimpleFxMarkets } from "../reducers/simple-fx-reducer";
import {
  getParsedSimpleFxMarkets,
  getSimpleFxMarkets,
} from "../requests/simplefx-requests";

export function getSimpleFxMarketsAction() {
  return (dispatch: Dispatch) => {
    // getSimpleFxMarkets()
    //   .then((response) => dispatch(setSimpleFxMarkets(response.data.data)))
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
}

export function getParsedSimpleFxMarketsAction() {
  return (dispatch: Dispatch) => {
    getParsedSimpleFxMarkets()
      .then((response) => dispatch(setSimpleFxMarkets(response.data.data)))
      .catch((error) => {
        console.error(error);
      });
  };
}

// export const [getParsedSimpleFxMarketsSuccess,getParsedSimpleFxMarketsFailed,getParsedSimpleFxMarketsRequest] = createActionTriangle("GET_PARSED_SIMPLE_FX_MARKETS");
//
// export function getParsedSimpleFxMarkets(){
//
//     return dispatch=>{
//         axios.get(getParsedSimpleFxMarketsEndpoint)
//             .then(response=>dispatch(getParsedSimpleFxMarketsSuccess(response.data)))
//             .catch(error=>{
//                 dispatch(getParsedSimpleFxMarketsFailed(error))
//                 console.log(error);
//                 console.error(error);
//             })
//     }
// }
