import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "..";

export default function UseCurrencyConversion() {
  const usdExchangeRates = useSelector(
    (state: RootState) => state.exchangeRatesState.usdRates
  );

  function convertCurrencyToDollar(amount: number, sourceCurrency: string) {
    return amount / usdExchangeRates[sourceCurrency];
  }

  function convertDollarToCurrency(amount: number, targetCurrency: string) {
    return amount * usdExchangeRates[targetCurrency];
  }

  return [convertCurrencyToDollar, convertDollarToCurrency];
}
