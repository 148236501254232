import React, { FormEvent, useState } from "react";
import { postSwarm } from "../../requests/swarm-requests";
import UsersSelect, { UserOption } from "../selects/users-select";
import "./create-swarms.scss";

export default function CreateSwarms() {
  const [selectedUserOptions, setSelectedUserOptions] = React.useState<
    UserOption[]
  >([]);
  const [swarmName, setSwarmName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  console.log(selectedUserOptions);
  return (
    <form onSubmit={handleSubmit} className="create-swarms-form">
      <h3>Create swarm</h3>
      <label>Name</label>
      <input
        required={true}
        value={swarmName}
        onChange={(event) => setSwarmName(event.target.value)}
        placeholder="SWARM_1..."
      />
      <label>Users</label>
      <UsersSelect
        selectedOptions={selectedUserOptions}
        setSelectedOptions={(userOptions) =>
          setSelectedUserOptions(Array.from(userOptions))
        }
      />
      <button type="submit">Create Swarm</button>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>{success}</div>}
    </form>
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setError("");
    setSuccess("");
    if (selectedUserOptions.length === 0) {
      setError("no users selected");
      return;
    }

    try {
      let response = await postSwarm(
        swarmName,
        selectedUserOptions.map((userOption) => userOption.value)
      );
      setSuccess("created");
    } catch (e: any) {
      setError(e.message);
    }
  }
}
