import { Swarm } from "@backend/entities/swarm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../..";
import useIsAdmin from "../../hooks/use-is-admin";
import SwarmSelect, { SwarmOption } from "../selects/swarm-select";
import UsersSelect from "../selects/users-select";
import { SortableTable } from "../table/sortable-table";
import CreateSwarms from "./create-swarms";
import SwarmDetail from "./swarm-display";

export default function Swarms() {
  const [selectedSwarmOption, setSelectedSwarmOption] =
    React.useState<SwarmOption | null>(null);
  const [swarm, setSwarm] = useState<Swarm | null>(null);

  let isAdmin = useIsAdmin();

  const swarms = useSelector((state: RootState) => {
    return state.swarmsState.swarms;
  });

  useEffect(() => {
    let matchedSwarm = swarms.find(
      (swarm) => swarm.id === selectedSwarmOption?.value
    );
    if (matchedSwarm) setSwarm(matchedSwarm);
  }, [swarms, selectedSwarmOption]);
  return (
    <div>
      <h1>Swarms</h1>
      {isAdmin && (
        <>
          <h2>Admin controls</h2>
          <CreateSwarms />
        </>
      )}
      {/* <h2>Swarm controls</h2> */}
      {/* <label style={{ display: "block" }}>Selected Swarm</label> */}
      {/* <SwarmSelect */}
      {/*   selectedOption={selectedSwarmOption} */}
      {/*   setSelectedOption={(swarmOption) => setSelectedSwarmOption(swarmOption)} */}
      {/* /> */}
      {/* <SortableTable<Swarm> data={swarms} /> */}
      {swarms && (
        <div>
          <h2>Swarms</h2>
          {swarms.map((swarm) => (
            <Link style={{ display: "block" }} to={"/swarms/" + swarm.id}>
              {swarm.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
