import React from "react";
import { Link } from "react-router-dom";
import { getHowLongAgoString } from "../../functions/date-functions";
import { useDispatch, useSelector } from "react-redux";
import { deleteMarketAction } from "../../actions/markets-actions";
import { LoadingEllipsisIcon } from "../icons/loading-ellipsis-icon";
import { DoubleClickButton } from "../double-click-button/double-click-button";
import LabelWithCopy from "../label-with-copy/label-with-copy";
import * as FileSaver from "file-saver";
import * as csv from "csv-stringify/browser/esm/sync";
import { Market } from "@backend/entities/market";
import { RootState } from "../..";

export default function MarketRow({ market }: { market: Market }) {
  const dispatch = useDispatch();

  const simpleFxMarket = useSelector((state: RootState) => {
    return state.simpleFxState.parsedSimpleFxMarkets.find(
      (simpleFxMarket) => simpleFxMarket.symbol === market.symbol
    );
  });

  return (
    <tr>
      <td>{market.id}</td>
      <td>{market.symbol}</td>
      <td>{getHowLongAgoString(market.lastUpdateQuotes)}</td>
      <td>{getHowLongAgoString(market.lastStartedTradeCalculation)}</td>
      {isCalculating() ? (
        <td className={"spinner"}>
          <LoadingEllipsisIcon />
        </td>
      ) : (
        <td>{getHowLongAgoString(market.lastCompletedTradeCalculation)}</td>
      )}
      <td>
        <LabelWithCopy
          string={
            !!simpleFxMarket
              ? simpleFxMarket.riskInDollarPerPercentPerStepBid.toString()
              : ""
          }
        />
      </td>
      <td>
        <LabelWithCopy
          string={
            !!simpleFxMarket
              ? simpleFxMarket.riskInEuroPerPercentPerStepBid.toString()
              : ""
          }
        />
      </td>
      <td>{market.triggerMom28 ? market.triggerMom28 : "N/A"}</td>
      <td>{market.trigger88 ? market.trigger88 : "N/A"}</td>

      <td>
        {/*<Link to={`/markets/edit/${market.id}`}>Edit</Link>*/}
        <Link to={`/markets/${market.id}`}>View</Link>
      </td>
      <td>
        <DoubleClickButton
          f={() => {
            dispatch(deleteMarketAction(market.id));
          }}
          label={"delete"}
          secondLabel={"are you sure?"}
        />
      </td>
    </tr>
  );

  function isCalculating() {
    if (market) {
      let lastStartedTradeCalculationDate = new Date(
        market.lastStartedTradeCalculation
      );
      let lastCompletedTradeCalculationDate = new Date(
        market.lastCompletedTradeCalculation
      );
      let deltaTime =
        lastCompletedTradeCalculationDate.getTime() -
        lastStartedTradeCalculationDate.getTime();

      if (deltaTime < 0) {
        return true;
      }
      return false;
    }
  }
}
