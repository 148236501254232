import React, { useState } from "react";
import CsvDropzone from "../../csv-dropzone/csv-dropzone";
import { postDividend } from "../../../requests/dividend-actions";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { DividendCreationBody } from "@backend/request-bodies/dividend-creation";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  encodig: "",
};

export default function InsertDividendWizard() {
  const [statusText, setStatusText] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const markets = useSelector((state: RootState) => state.marketsState.markets);

  async function handleDrop(files: any) {
    let counter = 0;
    for (let file of files) {
      try {
        let symbol = file.name.split(".csv")[0].toUpperCase();
        setStatusText(`${counter}/${files.length} now processing ${file.name}`);
        let csvContent = Papa.parse(file.data, papaparseOptions);
        let dividend: any = csvContent.data;

        let matchedMarket = markets.find((market) => market.symbol === symbol);
        if (!matchedMarket) continue;
        let dividendCreationBodies: DividendCreationBody[] = [];
        for (let dividendEntry of dividend) {
          let dividendCreationBody = new DividendCreationBody();
          dividendCreationBody.date = new Date(parseInt(dividendEntry.date));
          dividendCreationBody.dividend = parseFloat(dividendEntry.dividend);
          dividendCreationBody.marketId = matchedMarket.id;

          dividendCreationBodies.push(dividendCreationBody);
        }
        console.log(dividendCreationBodies);

        let response = await postDividend(dividendCreationBodies);
      } catch (e: any) {
        setErrors((old) => {
          return old.concat(`${file.name} failed with error ${e.message}`);
        });
      }
      counter++;
    }
    setStatusText(`Done!`);
  }
  return (
    <div className={"insert-quotes-wizard"}>
      <h1>Insert Dividend Wizard</h1>
      <CsvDropzone onFilesReceived={handleDrop} />

      {statusText}
      {errors.map((error) => {
        return <div>{error}</div>;
      })}
    </div>
  );
}
