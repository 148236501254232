import { AgentCommandType } from "../enums/agent-command-type";

export class AgentCommand {
  constructor(public name: AgentCommandType, public args?: string[]) {}
  toString() {
    return `${this.name} ${this.args ? this.args.join(" ") : ""}`;
  }
}

export class TakeTradeCommand extends AgentCommand {
  constructor(public tradeId: string) {
    super(AgentCommandType.TakeTrade, [tradeId]);
  }
}
export class ResumeTradeCommand extends AgentCommand {
  constructor(
    public tradeId: string,
    public stage: number,
    public tickets: number[],
    public openPrice: number
  ) {
    super(AgentCommandType.ResumeTrade, [tradeId]);
  }
  toString() {
    return `${this.name} ${this.tradeId} ${this.stage} ${this.tickets.join(
      ","
    )} ${this.openPrice}`;
  }
}
export class CloseTradeCommand extends AgentCommand {
  constructor() {
    super(AgentCommandType.CloseTrade, []);
  }
}
