import React from "react";

export default function CalculatorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M21 2v20h-18v-20h18zm2-2h-22v24h22v-24zm-3 3h-16v5h16v-5zm-13 7h-3v3h3v-3zm4 0h-3v3h3v-3zm4 0h-3v3h3v-3zm5 0h-3v3h3v-3zm-13 4h-3v3h3v-3zm4 0h-3v3h3v-3zm4 0h-3v3h3v-3zm5 0h-3v7h3v-7zm-13 4h-3v3h3v-3zm4 0h-3v3h3v-3zm4 0h-3v3h3v-3z" />
    </svg>
  );
}
