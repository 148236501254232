import React from "react";
import MenuIcon from "../icons/menu-icon.js";

export default function MenuButton({ onClick }: any) {
  return (
    <div onClick={onClick} className={"menu-button"}>
      <MenuIcon />
    </div>
  );
}
