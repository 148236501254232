import React from "react";
import axios from "axios";
import { getMrSheetTokenEndpoint } from "../../../requests/endpoints";
import { getMrSheetToken } from "../../../requests/general-requests";

export default function MrSheetTokenButton() {
  return <button onClick={getToken}>get mr sheet token</button>;

  async function getToken() {
    getMrSheetToken().then((response) => {
      navigator.clipboard.writeText(response.data.data).then(() => {});
    });
  }
}
