import { Trade } from "@backend/entities/trade";
import { Link } from "react-router-dom";
import SwapAvgProfitRatioColumn from "../components/columns/swap-avg-profit-ratio-column";
import LabelWithCopy from "../components/label-with-copy/label-with-copy";

export const possibleColumns: any = {
  id: {
    Header: "id",
    accessor: "id",
  },
  symbol: {
    Header: "symbol",
    accessor: (d: Trade) => {
      return d.market.symbol;
    },
    Cell: ({ value }: any) => {
      return <Link to={`/market-chart/${value}`}>{value}</Link>;
    },
  },
  openDay: {
    Header: "openDay",
    accessor: "openDay",
  },
  openMonth: {
    Header: "openMonth",
    accessor: "openMonth",
  },
  closeDay: {
    Header: "closeDay",
    accessor: "closeDay",
  },
  closeMonth: {
    Header: "closeMonth",
    accessor: "closeMonth",
  },
  type: {
    Header: "type",
    accessor: "type",
  },
  winners: {
    Header: "winners",
    accessor: "winners",
  },
  losers: {
    Header: "losers",
    accessor: "losers",
  },
  biggestWinStreak: {
    Header: "biggestWinStreak",
    accessor: "biggestWinStreak",
  },
  biggestLossStreak: {
    Header: "biggestLossStreak",
    accessor: "biggestLossStreak",
  },
  avgProfit: (precision: number) => {
    return {
      Header: "avgProfit",
      accessor: (d: Trade) => {
        let number = d.avgProfit;
        return number.toPrecision(precision);
      },
    };
  },
  avgProfitPercentage: (precision: number) => {
    return {
      Header: "avgProfitPercentage",
      accessor: (d: Trade) => {
        let number = d.avgProfitPercentage;
        return number.toPrecision(precision);
      },
    };
  },
  avgLoss: (precision: number) => {
    return {
      Header: "avgLoss",
      accessor: (d: Trade) => {
        let number = d.avgLoss;
        return number.toPrecision(precision);
      },
    };
  },
  avgLossPercentage: (precision: number) => {
    return {
      Header: "avgLossPercentage",
      accessor: (d: Trade) => {
        let number = d.avgLossPercentage;
        return number.toPrecision(precision);
      },
    };
  },
  plRatio: (precision: number) => {
    return {
      Header: "plRatio",
      accessor: (d: Trade) => {
        let number = d.plRatio;
        return number.toPrecision(precision);
      },
    };
  },
  winPercentage: (precision: number) => {
    return {
      Header: "winPercentage",
      accessor: (d: Trade) => {
        let number = d.winPercentage;
        return number.toPrecision(precision);
      },
    };
  },
  numberOfTrades: {
    Header: "numberOfTrades",
    accessor: "numberOfTrades",
  },
  stopLoss: (precision: number) => {
    return {
      Header: "stopLoss",
      accessor: (d: Trade) => {
        let stopLoss;
        d.type === "Long"
          ? (stopLoss = d.lowestPercentageChange)
          : (stopLoss = d.highestPercentageChange);
        let number = stopLoss;
        return number.toPrecision(precision);
      },
      Cell: ({ value }: any) => {
        return `${value}%`;
      },
    };
  },
  stopLossClose: (precision: number) => {
    return {
      Header: "stopLossClose",
      accessor: (d: Trade) => {
        let stopLoss;
        d.type === "Long"
          ? (stopLoss = d.lowestPercentageChangeClose)
          : (stopLoss = d.highestPercentageChangeClose);
        let number = stopLoss;
        return number.toPrecision(precision);
      },
      Cell: ({ value }: any) => {
        return `${value}%`;
      },
    };
  },
  biggestDailyChange: (precision: number) => {
    return {
      Header: "biggestDailyChange",
      accessor: (d: Trade) => {
        let biggestDailyPercentageChangeOfMarket;
        return d.type === "Long"
          ? (biggestDailyPercentageChangeOfMarket =
              d.market.lowestDailyPercentageChangeAfter20YearsOfData)
          : (biggestDailyPercentageChangeOfMarket =
              d.market.highestDailyPercentageChangeAfter20YearsOfData);
      },
      Cell: ({ value }: any) => {
        return `${value}%`;
      },
    };
  },
  intraDayBiggestDailyChange: (precision: any) => {
    return {
      Header: "intraDayBiggestDailyChange",
      accessor: (d: Trade) => {
        let biggestDailyPercentageChangeOfMarket;
        return d.type === "Long"
          ? (biggestDailyPercentageChangeOfMarket =
              d.market.intraDayLowestDailyPercentageChangeAfter20YearsOfData)
          : (biggestDailyPercentageChangeOfMarket =
              d.market.intraDayHighestDailyPercentageChangeAfter20YearsOfData);
      },
      Cell: ({ value }: any) => {
        return `${value}%`;
      },
    };
  },
  years: {
    Header: "years",
    accessor: "years",
  },
  // avgRRatio: (precision: number) => {
  //   return {
  //     Header: "avgRRatio",
  //     accessor: (d: Trade) => {
  //       let number = d.avgRRatio;
  //       return number.toPrecision(precision);
  //     },
  //   };
  // },
  lowestPercentageChange: (precision: number) => {
    return {
      Header: "lowestPercentageChange",
      accessor: (d: Trade) => {
        let number = d.lowestPercentageChange;
        return number.toPrecision(precision);
      },
    };
  },
  lowestPercentageChangeClose: (precision: number) => {
    return {
      Header: "lowestPercentageChangeClose",
      accessor: (d: Trade) => {
        let number = d.lowestPercentageChangeClose;
        return number.toPrecision(precision);
      },
    };
  },
  highestPercentageChangeClose: (precision: number) => {
    return {
      Header: "highestPercentageChangeClose",
      accessor: (d: Trade) => {
        let number = d.highestPercentageChangeClose;
        return number.toPrecision(precision);
      },
    };
  },
  highestPercentageChange: (precision: number) => {
    return {
      Header: "highestPercentageChange",
      accessor: (d: Trade) => {
        let number = d.highestPercentageChange;
        return number.toPrecision(precision);
      },
    };
  },
  // stopLosses:{
  //     Header:"stopLosses",
  //     accessor:"stopLosses"
  // },
  currentWinStreak: {
    Header: "currentWinStreak",
    accessor: "currentWinStreak",
  },
  currentLossStreak: {
    Header: "currentLossStreak",
    accessor: "currentLossStreak",
  },
  payoutRate: (precision: number) => {
    return {
      Header: "payoutRate",
      accessor: (d: Trade) => {
        let number = d.payoutRate;
        return number.toPrecision(precision);
      },
    };
  },
  payoutRateOfPercentages: (precision: number) => {
    return {
      Header: "payoutRateOfPercentages",
      accessor: (d: Trade) => {
        let number = d.payoutRateOfPercentages;
        return number.toPrecision(precision);
      },
    };
  },
  lowestChange: (precision: number) => {
    return {
      Header: "lowestChange",
      accessor: (d: Trade) => {
        let number = d.lowestChange;
        return number.toPrecision(precision);
      },
    };
  },
  lowestChangeClose: (precision: number) => {
    return {
      Header: "lowestChangeClose",
      accessor: (d: Trade) => {
        let number = d.lowestChangeClose;
        return number.toPrecision(precision);
      },
    };
  },
  highestChange: (precision: number) => {
    return {
      Header: "highestChange",
      accessor: (d: Trade) => {
        let number = d.highestChange;
        return number.toPrecision(precision);
      },
    };
  },
  highestChangeClose: (precision: number) => {
    return {
      Header: "highestChangeClose",
      // accessor:"highestChangeClose",
      accessor: (d: Trade) => {
        let number = d.highestChangeClose;
        return number.toPrecision(precision);
      },
    };
  },
  // cartButton:{
  //     Header: "Add",
  //     accessor: d =>{
  //         return "test"
  //     }
  //
  // },
  derivedTrade: {
    Header: "derivedTrade",
    accessor: (d: Trade) => {
      if (d.derivedTrade != null) {
        return d.derivedTrade.toString();
      }
      return "";
    },
  },
  downloadPreset: {
    Header: "DownloadPreset",
    accessor: (d: Trade) => {
      return d.id;
    },
    Cell: ({ value }: any) => {
      return (
        <Link to={`/presets/${value}`} target={"_blank"}>
          {value}
        </Link>
      );
    },
  },
  riskForStopLoss100ClosePerStepSize: {
    Header: "RiskForStopLoss100ClosePerStepSize",
    Cell: ({ value }: any) => {
      return (
        <Link to={`/trade/preset/${value}`} target={"_blank"}>
          {value}
        </Link>
      );
    },
  },
  trigger88: {
    Header: "trigger88",
    accessor: (d: Trade) => {
      if (d.market.trigger88)
        return d.type.toString() === d.market.trigger88.toString()
          ? "yes"
          : "no";
      else return "N/A";
    },
    Cell: ({ value }: any) => {
      return (
        <label className={value === "yes" ? "green" : "red"}>{value}</label>
      );
    },
  },
  triggerMom28: {
    Header: "triggerMom28",
    accessor: (d: Trade) => {
      if (!d.market.triggerMom28) {
        return "N/A";
      }
      return d.type.toString() === d.market.triggerMom28.toString()
        ? "yes"
        : "no";
    },
    Cell: ({ value }: any) => {
      return (
        <label className={value === "yes" ? "green" : "red"}>{value}</label>
      );
    },
  },
  entryDate: {
    Header: "entryDate",
    accessor: (d: Trade) => {
      return `${d.openDay}/${d.openMonth}`;
    },
  },
  exitDate: {
    Header: "exitDate",
    accessor: (d: Trade) => {
      return `${d.closeDay}/${d.closeMonth}`;
    },
  },
  idWithCopy: {
    Header: "idWithCopy",
    accessor: (d: Trade) => d.id,
    Cell: ({ value }: any) => {
      return <LabelWithCopy string={value} />;
    },
  },
  // homePageCorrelation: {
  //   Header: "homePageCorrelation",
  //   accessor: (d: Trade) => d.market.id,
  //   Cell: ({ value }: any) => {
  //     return <HomePageCorrelation marketId={value} />;
  //   },
  // },
  // sheetCorrelation: {
  //   Header: "sheetCorrelation",
  //   accessor: (d: Trade) => d.market.id,
  //   Cell: ({ value }: any) => {
  //     return <SheetCorrelation marketId={value} />;
  //   },
  // },
  // customHomePageCorrelation: {
  //   Header: "customHomePageCorrelation",
  //   accessor: (d: Trade) => d.market.id,
  //   Cell: ({ value }: any) => {
  //     return <CustomHomePageCorrelation marketId={value} />;
  //   },
  // },
  // customSheetCorrelation: {
  //   Header: "customSheetCorrelation",
  //   accessor: (d: Trade) => d.market.id,
  //   Cell: ({ value }: any) => {
  //     return <CustomSheetCorrelation marketId={value} />;
  //   },
  // },
  avgBars: {
    Header: "avgBars",
    accessor: (d: Trade) => d.avgBars,
  },
  swapToAvgProfitRatio: {
    Header: "SAPRatio",
    accessor: (d: Trade) => {
      return JSON.stringify({
        marketId: d.market.id,
        avgBars: d.avgBars,
        type: d.type,
        avgProfit: d.avgProfit,
        avgProfitPercentage: d.avgProfitPercentage,
      });
    },
    Cell: ({ value }: any) => {
      return <SwapAvgProfitRatioColumn jsonString={value} />;
    },
  },
  calendarDays: {
    Header: "calendarDays",
    accessor: (d: Trade) => {
      let startDate = new Date();
      startDate.setMonth(d.openMonth - 1);
      startDate.setDate(d.openDay);
      let endDate = new Date();
      endDate.setMonth(d.closeMonth - 1);
      endDate.setDate(d.closeDay);
      if (d.openMonth > d.closeMonth)
        endDate.setFullYear(startDate.getFullYear() + 1);
      let days =
        (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
      return days;
    },
  },

  targetYear: {
    Header: "targetYear",
    accessor: "targetYear",
  },
  // kelly:{
  //     Header:"kelly",
  //     accessor:trade=>{
  //         let W = trade.winPercentage / 100;
  //         let R = (trade.winners/trade.losers)
  //         return W - ((1- W)/R)
  //     }
  // }
};

export function getPossibleColumnOptions() {
  return Object.keys(possibleColumns).map((key) => {
    return { value: key, label: key };
  });
}

export function columnSettingsToRealColumns(columnSettings: any) {
  return columnSettings.map((columnSetting: any) => {
    let [columnString, columnPrecision] = columnSetting.split(":");
    if (columnPrecision) {
      return possibleColumns[columnString](columnPrecision);
    } else {
      return possibleColumns[columnString];
    }
  });
}

export function checkIfPrecisionIsAvailable(columnString: string) {
  return typeof possibleColumns[columnString] === "function";
}
