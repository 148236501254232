import { Trade } from "@backend/entities/trade";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import axios, { AxiosResponse } from "axios";
import { Sheet } from "../types/sheet";
import {
  sheetDTradeSelectionEndpoint,
  sheetETradeSelectionEndpoint,
  sheetSouhaibV1TradeSelectionEndpoint,
  souhaibV1TradeSelectionEndpoint,
} from "./endpoints";

export function getSouhaibV1TradeSelection(
  sheetId: number,
  date: Date
): Promise<
  AxiosResponse<
    ReadResponseBody<{ tradesForToday: Trade[]; futureTrades: Trade[] }>
  >
> {
  return axios.get(souhaibV1TradeSelectionEndpoint(sheetId, date));
}
export function getSheetETradeSelection(): Promise<
  AxiosResponse<
    ReadResponseBody<{ tradesForToday: Trade[]; futureTrades: Trade[] }>
  >
> {
  return axios.get(sheetETradeSelectionEndpoint);
}
export function getSheetDTradeSelection(): Promise<
  AxiosResponse<
    ReadResponseBody<{ tradesForToday: Trade[]; futureTrades: Trade[] }>
  >
> {
  return axios.get(sheetDTradeSelectionEndpoint);
}

export function getSheetSouhaibV1TradeSelection(
  date: Date,
  sheet: Sheet,
  blackListedTradeIds: string
): Promise<
  AxiosResponse<
    ReadResponseBody<{
      tradesForToday: Trade[];
      futureTrades: Trade[];
      activeTrades: Trade[];
    }>
  >
> {
  return axios.get(
    sheetSouhaibV1TradeSelectionEndpoint(date, sheet, blackListedTradeIds)
  );
}
