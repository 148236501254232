import React from "react";
import Table from "../table/table";

const columns = [
  {
    Header: "symbol",
    accessor: "symbol", // accessor is the "key" in the data
  },
  {
    Header: "quoteCollectionId",
    accessor: "quoteCollectionId",
  },
  {
    Header: "differenceInDays",
    accessor: "differenceInDays",
  },
  {
    Header: "dateA",
    accessor: "dateA",
  },
  {
    Header: "dateB",
    accessor: "dateB",
  },
];

export default function DifferenceInDaysReportsReactTable({
  differenceInDaysReports,
}: any) {
  return <Table columns={columns} data={differenceInDaysReports} />;
}
