import React from "react";
import { Sheet } from "../../types/sheet";
import { SheetSouhaibV1 } from "./sheet-souhaib-v1";
import { SouhaibV1 } from "./souhaib-v1";

export const sheets: Sheet[] = [
  // {
  //   name: "sheet E",
  //   id: 442829257,
  //   minAvgBars: 5,
  //   minPlRatio: 4,
  //   minWinPercentage: 70,
  //   maxBiggestLossStreak: 4,
  //   minNumberOfTrades: 27,
  // },
  // {
  //   name: "sheet D",
  //   id: 147511035,
  //   minAvgBars: 4,
  //   minPlRatio: 4,
  //   minWinPercentage: 70,
  //   maxBiggestLossStreak: 4,
  //   minNumberOfTrades: 27,
  // },
  {
    name: "sheet F",
    id: 404801762,
    minAvgBars: 15,
    minPlRatio: 3,
    minWinPercentage: 75,
    maxBiggestLossStreak: 3,
    minNumberOfTrades: 25,
  },
];

export default function TradeSelection() {
  return (
    <div>
      <h1>Trade Selection</h1>
      {sheets.map((sheet) => {
        return <SheetSouhaibV1 sheet={sheet} />;
      })}
    </div>
  );
}
