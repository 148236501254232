import {
  setDataSources,
  setDataSourcesMetadata,
} from "../reducers/data-sources-reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  getDataSources,
  getDataSourcesMetadata,
} from "../requests/data-source-requests";

export function getDataSourcesAction() {
  return (dispatch: Dispatch) => {
    getDataSources()
      .then((response) => dispatch(setDataSources(response.data.data)))
      .catch((error) => console.error(error));
  };
}

export function getDataSourcesMetadataAction() {
  return (dispatch: Dispatch) => {
    getDataSourcesMetadata()
      .then((response) => dispatch(setDataSourcesMetadata(response.data.data)))
      .catch((error) => console.log(error));
  };
}
