import React, { useEffect, useRef, useState } from "react";
import {
  CandlestickData,
  createChart,
  LineData,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import { getQuoteCollection } from "../../requests/quote-collections-requests";
import { sortByDateAsc } from "../../functions/sort-functions";
import { TALib } from "talib.ts";
import { Quote } from "@backend/entities/quote";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { Market } from "@backend/entities/market";
import { Period } from "@backend/enums/period";
import { useParams } from "react-router-dom";
import Chart from "./chart";
import { dateToBusinessDay } from "../../functions/date-functions";

export default function MarketChart() {
  const { symbol } = useParams();
  const bestQuoteCollection = useSelector((state: RootState) => {
    let market = state.marketsState.markets.find(
      (market) => market.symbol === symbol
    );
    if (market === undefined) return null;
    let marketForReal: Market = market;
    return state.quoteCollectionsState.quoteCollections.find(
      (quoteCollection) =>
        quoteCollection.marketId === marketForReal.id &&
        quoteCollection.dataSourceId === marketForReal.mainDataSourceId &&
        quoteCollection.period === Period.D
    );
  });

  useEffect(() => {
    if (bestQuoteCollection)
      getQuoteCollection(bestQuoteCollection.id, true).then((response) =>
        setOhlc(response.data.data.quotes)
      );
  }, [bestQuoteCollection]);

  const [ohlc, setOhlc] = useState<Quote[]>([]);

  const [lineSeries, setLineSeries] = useState<LineData[][]>([]);
  const [candlestickSeries, setCandlestickSeries] = useState<
    CandlestickData[][]
  >([]);

  useEffect(() => {
    if (ohlc.length > 0) {
      let sortedOhlc = ohlc.sort((a, b) => sortByDateAsc(a.date, b.date));
      let highs = sortedOhlc.map<number>((quote) => quote.high);
      let lows = sortedOhlc.map<number>((quote) => quote.low);
      let closes = sortedOhlc.map<number>((quote) => quote.close);
      let opens = sortedOhlc.map<number>((quote) => quote.open);

      let sma8Close = TALib.sma(closes, 8)
        .sma.getValue()
        .reduce<LineData[]>((list, value, index) => {
          return value
            ? [
                ...list,
                {
                  time: dateToBusinessDay(sortedOhlc[index].date),
                  value: value,
                },
              ]
            : list;
        }, []);
      let sma8Open = TALib.sma(opens, 8)
        .sma.getValue()
        .reduce<LineData[]>((list, value, index) => {
          return value
            ? [
                ...list,
                {
                  time: dateToBusinessDay(sortedOhlc[index].date),
                  value: value,
                },
              ]
            : list;
        }, []);
      let sma3High = TALib.sma(highs, 3)
        .sma.getValue()
        .reduce<LineData[]>((list, value, index) => {
          return value
            ? [
                ...list,
                {
                  time: dateToBusinessDay(sortedOhlc[index].date),
                  value: value,
                },
              ]
            : list;
        }, []);
      let sma3Low = TALib.sma(lows, 3)
        .sma.getValue()
        .reduce<LineData[]>((list, value, index) => {
          return value
            ? [
                ...list,
                {
                  time: dateToBusinessDay(sortedOhlc[index].date),
                  value: value,
                },
              ]
            : list;
        }, []);

      let candleStickData: CandlestickData[] = sortedOhlc.reduce<
        CandlestickData[]
      >((list, quote) => {
        if (
          quote.date &&
          quote.open &&
          quote.close &&
          quote.high &&
          quote.low
        ) {
          return list.concat([
            {
              time: dateToBusinessDay(quote.date),
              open: quote.open,
              close: quote.close,
              high: quote.high,
              low: quote.low,
            },
          ]);
        }
        return list;
      }, []);
      // setLineSeries([sma3Low, sma3High, sma8Open, sma8Close]);
      setCandlestickSeries([candleStickData]);
    }
  }, [ohlc]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Chart lineSeries={lineSeries} candlestickSeries={candlestickSeries} />
    </div>
  );
}
