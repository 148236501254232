import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noNullValues } from "../functions/object-functions";
import {
  getDataSourcesAction,
  getDataSourcesMetadataAction,
} from "../actions/data-source-actions";
import { RootState } from "../index";
import {
  getMarketsAction,
  getMarketsLastUpdatedAction,
} from "../actions/markets-actions";
import {
  getSheetTradesAction,
  getUsdExchangeRatesAction,
} from "../actions/general-actions";
import {
  getQuoteCollectionsAction,
  getQuoteCollectionsMetadataAction,
} from "../actions/quote-collections-actions";
import {
  getParsedSimpleFxMarketsAction,
  getSimpleFxMarketsAction,
} from "../actions/simplefx-actions";
import { getCorrelationsAction } from "../actions/correlation-actions";
import { healthy } from "../reducers/login-reducer";
import { getMeAction, getUsersAction } from "../actions/users-actions";
import useIsAdmin from "./use-is-admin";
import { getMeSwarmsAction } from "../actions/swarm-actions";
let wsUrl = "ws://localhost:3000/";

if (process.env.REACT_APP_ENV === "production") {
  wsUrl = "wss://www.boybou.nl/api/";
} else if (process.env.REACT_APP_ENV === "testing") {
  wsUrl = "wss://www.boybou.nl:5001/api/";
}

export default function useAssets() {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const assetsFetched = useSelector((state: RootState) => {
    return !!(
      state.simpleFxState.parsedSimpleFxMarkets.length > 0 &&
      state.loginState.user &&
      state.marketsState.markets &&
      state.sheetState.sheetTrades &&
      state.quoteCollectionsState.quoteCollections.length > 0 &&
      state.dataSourcesState.dataSources.length > 0
    );
  });

  useEffect(() => {
    const socket = new WebSocket(wsUrl);
    socket.addEventListener("open", (event) => {
      // console.log(event);
    });
    socket.addEventListener("message", (event) => {
      console.log(event);
      switch (event.data) {
        case "users":
          dispatch(getMeAction());
          break;
        case "markets":
          dispatch(getMarketsAction());
          dispatch(getMarketsLastUpdatedAction());
          break;
        case "sheetTrades":
          dispatch(getSheetTradesAction());
          break;
        case "quoteCollections":
          dispatch(getQuoteCollectionsAction());
          dispatch(getQuoteCollectionsMetadataAction());
          break;
        case "dataSources":
          dispatch(getDataSourcesAction());
          dispatch(getDataSourcesMetadataAction());
          break;
        case "swarms":
          dispatch(getMeSwarmsAction());
          break;
        default:
          break;
      }
    });

    dispatch(getDataSourcesMetadataAction());
    dispatch(getParsedSimpleFxMarketsAction());
    dispatch(getMarketsAction());
    dispatch(getMarketsLastUpdatedAction());
    dispatch(getCorrelationsAction());
    dispatch(getSheetTradesAction());
    dispatch(getUsdExchangeRatesAction());
    dispatch(getQuoteCollectionsAction());
    dispatch(getQuoteCollectionsMetadataAction());
    dispatch(getDataSourcesAction());
    dispatch(getMeSwarmsAction());
    if (isAdmin) {
      dispatch(getUsersAction());
    }
  }, []);

  return assetsFetched;
}
