import { User } from "@backend/entities/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LoginState = {
  user: User | null;
  healthy: boolean;
};

const initialState = {
  user: null,
  healthy: false,
} as LoginState;

const loginStateSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    setLoginData(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.healthy = true;
    },
    updateMeUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    healthy(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.healthy = true;
    },
    unHealthy(state, action) {
      return initialState;
    },
  },
});

export const loginState = loginStateSlice.reducer;
export const { setLoginData, updateMeUser, healthy, unHealthy } =
  loginStateSlice.actions;
