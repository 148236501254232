import React from "react";

export default function ButtonWithLoadingAndSuccess({
  onClick,
  requestState,
  text,
}: {
  onClick: () => any;
  requestState: string;
  text: string;
}) {
  if (requestState === "loading") {
    return <button onClick={onClick}>{text} (loading...)</button>;
  } else if (requestState === "success") {
    return <button onClick={onClick}>{text} (success!)</button>;
  }
  return <button onClick={onClick}>{text} </button>;
}
