import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ExchangesRatesState = {
  usdRates: any;
};

const initialState = {
  usdRates: null,
} as ExchangesRatesState;

const exchangeRatesStateSlice = createSlice({
  name: "exchangeRatesState",
  initialState,
  reducers: {
    setUsdExchangeRates(state, action: PayloadAction<any>) {
      state.usdRates = action.payload;
    },
  },
});

export const exchangeRatesState = exchangeRatesStateSlice.reducer;
export const { setUsdExchangeRates } = exchangeRatesStateSlice.actions;
