import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import MarketRow from "./market-row";
import "./markets-table.scss";

export default function MarketsTable() {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("");

  const markets = useSelector((state: RootState) => {
    if (filter) {
      return state.marketsState.markets.filter((market) => {
        return market.symbol.toUpperCase().includes(filter.toUpperCase());
      });
    }
    return state.marketsState.markets;
  });

  return (
    <div>
      <input
        type={"text"}
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
        placeholder={"filter..."}
      />
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>symbol</th>
            <th>lastUpdateQuotes</th>
            <th>lastStartedTradeCalculation</th>
            <th>lastCompletedTradeCalculation</th>
            <th>riskInDollarPerPercentPerStepBid</th>
            <th>riskInEuroPerPercentPerStepBid</th>
            <th>triggerMom28</th>
            <th>trigger88</th>
          </tr>
        </thead>
        <tbody>{renderMarketRows()}</tbody>
      </table>
    </div>
  );

  function renderMarketRows() {
    return markets.map((market) => {
      return <MarketRow market={market} />;
    });
  }
}
