import { AccountDataPoint } from "@backend/entities/account-data-point";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import {AccountPerformance} from "@backend/types/account-performance"
import axios, { AxiosResponse } from "axios";
import {
  getAccountDataPointsByAccountNumberEndpoint,
  getAccountPerformanceEndpoint,
  getDistinctAccountNumbersEndpoint,
  getEarliestAccountDataPointByAccountNumberAndDateEndpoint,
  getEarliestEquityBySheetIdAndDateEndpoint,
  getLatestAccountDataPointByAccountNumberAndDateEndpoint,
  getLatestEquityBySheetIdAndDateEndpoint,
} from "./endpoints";

export function getDistinctAccountNumbers(): Promise<
  AxiosResponse<ReadResponseBody<number[]>>
> {
  return axios.get(getDistinctAccountNumbersEndpoint);
}

export function getAccountDataPointsByAccountNumber(
  accountNumber: number
): Promise<AxiosResponse<ReadResponseBody<AccountDataPoint[]>>> {
  return axios.get(getAccountDataPointsByAccountNumberEndpoint(accountNumber));
}

const THIRTY_DAYS_IN_MS = 2592000000;

export function getAccountDataPointFrom30DaysAgo(
  accountNumber: number
): Promise<AxiosResponse<ReadResponseBody<AccountDataPoint>>> {
  let date = new Date(Date.now() - THIRTY_DAYS_IN_MS);
  let dateString = date.toISOString().split("T")[0];

  return axios.get(
    getEarliestAccountDataPointByAccountNumberAndDateEndpoint(
      accountNumber,
      dateString
    )
  );
}

export function getLatestAccountDataPointFromToday(
  accountNumber: number
): Promise<AxiosResponse<ReadResponseBody<AccountDataPoint>>> {
  let date = new Date();
  let dateString = date.toISOString().split("T")[0];

  return axios.get(
    getLatestAccountDataPointByAccountNumberAndDateEndpoint(
      accountNumber,
      dateString
    )
  );
}

export function getEarliestAccountDataPointFromToday(
  accountNumber: number
): Promise<AxiosResponse<ReadResponseBody<AccountDataPoint>>> {
  let date = new Date();
  let dateString = date.toISOString().split("T")[0];

  return axios.get(
    getEarliestAccountDataPointByAccountNumberAndDateEndpoint(
      accountNumber,
      dateString
    )
  );
}

export function getAccountPerformance(accountNumber:number): Promise<AxiosResponse<ReadResponseBody<AccountPerformance>>>{
    return axios.get(getAccountPerformanceEndpoint(accountNumber));
}
