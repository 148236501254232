import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { deleteToken } from "../../requests/users-requests";
import CreateNewTokenButton from "../buttons/create-new-token-button";
import { SortableTable } from "../table/sortable-table";

export default function Tokens() {
  const user = useSelector((state: RootState) => state.loginState.user);
  return (
    <div>
      <h1>Tokens</h1>
      <CreateNewTokenButton />
      {user && user.tokenNames.length > 0 && (
        <>
          <SortableTable
            data={user?.tokenNames.map((tokenName) => {
              return { tokenName };
            })}
            buttons={[
              {
                label: "revoke",
                onClick: (entry) => deleteToken(entry.tokenName),
              },
            ]}
          />
        </>
      )}
    </div>
  );
}
