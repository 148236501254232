import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SeasonalDisplayComponent } from "../seasonal-displays/seasonal-display-component";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DelayedInput from "../inputs/delayed-input";
import { RootState } from "../..";
import { getDistinctTradeCollectionAction } from "../../actions/general-actions";
import { SheetTrade } from "@backend/entities/sheet-trade";

export function Home() {
  const user = useSelector((state: RootState) => state.loginState.user);

  const [date, setDate] = useState(new Date());
  const [minimumNumberOfTrades, setMinimumNumberOfTrades] = useState<number>(
    !!user?.homePageDefaultNumberOfYears
      ? user.homePageDefaultNumberOfYears
      : 20
  );
  const [oc8Filter, setOc8Filter] = useState(false);
  const [mom28Filter, setMom28Filter] = useState(false);
  const [sheetTradesFilter, setSheetTradesFilter] = useState(false);
  const targetYear = useSelector(
    (state: RootState) => state.settingsState.targetYear
  );

  const dispatch = useDispatch();

  const seventyPercentTradeCollectionOrderedByNumberOfTradesFiltered =
    useSelector((state: RootState) => {
      let sheetTradesWithMarkets = [...state.sheetState.sheetTrades].map(
        (sheetTrade) => {
          let market = state.marketsState.markets.find(
            (market) => market.id === sheetTrade.trade.marketId
          );
          let sheetTradeCopy: SheetTrade = { ...sheetTrade };
          sheetTradeCopy.trade = Object.create(sheetTradeCopy.trade);
          if (market) sheetTradeCopy.trade.market = market;
          return sheetTradeCopy;
        }
      );

      let trades =
        state.homeState.seventyPercentTradeCollectionOrderedByNumberOfTrades;
      // trigger filter
      if (oc8Filter) {
        trades = trades.filter((trade) =>
          trade.market.trigger88
            ? trade.type.toString() === trade.market.trigger88.toString()
            : false
        );
      }
      if (mom28Filter) {
        trades = trades.filter((trade) =>
          trade.market.triggerMom28
            ? trade.type.toString() === trade.market.triggerMom28.toString()
            : false
        );
      }

      if (sheetTradesFilter) {
        trades = trades.filter((trade) => {
          if (trade.market.security.includes("Forex")) {
            let firstCurrency = trade.market.symbol.substring(0, 3);
            let secondCurrency = trade.market.symbol.substring(3);

            let noDeal = !!sheetTradesWithMarkets.find((sheetTrade) => {
              let sheetTradeFirstCurrency =
                sheetTrade.trade.market.symbol.substring(0, 3);
              let sheetTradeSecondCurrency =
                sheetTrade.trade.market.symbol.substring(3);
              let sameDirection = trade.type === sheetTrade.trade.type;
              let noDeal =
                ((firstCurrency === sheetTradeFirstCurrency ||
                  secondCurrency === sheetTradeSecondCurrency) &&
                  sameDirection) ||
                ((firstCurrency === sheetTradeSecondCurrency ||
                  secondCurrency === sheetTradeFirstCurrency) &&
                  !sameDirection);
              // console.log(trade.market.symbol + " - " + sheetTrade.market.symbol + noDeal);
              return noDeal;
            });

            return !noDeal;
          } else {
            let noDeal = !!sheetTradesWithMarkets.find((sheetTrade) => {
              let sameDirection = trade.type === sheetTrade.trade.type;
              let noDeal =
                trade.market.symbol === sheetTrade.trade.market.symbol &&
                sameDirection;
              console.log(
                trade.market.symbol +
                  " - " +
                  sheetTrade.trade.market.symbol +
                  noDeal
              );
              return noDeal;
            });

            return !noDeal;
          }
        });
      }

      return trades;
    });
  const seventyPercentTradeCollectionOrderedByNumberOfTrades = useSelector(
    (state: RootState) =>
      state.homeState.seventyPercentTradeCollectionOrderedByNumberOfTrades
  );
  const eightyPercentTradeCollectionOrderedByNumberOfTrades = useSelector(
    (state: RootState) =>
      state.homeState.eightyPercentTradeCollectionOrderedByNumberOfTrades
  );
  const seventyPercentTradeCollectionOrderedByPlRatio = useSelector(
    (state: RootState) =>
      state.homeState.seventyPercentTradeCollectionOrderedByPlRatio
  );
  const eightyPercentTradeCollectionOrderedByPlRatio = useSelector(
    (state: RootState) =>
      state.homeState.eightyPercentTradeCollectionOrderedByPlRatio
  );
  const seventyPercentTradeCollectionOrderedByWinPercentage = useSelector(
    (state: RootState) =>
      state.homeState.seventyPercentTradeCollectionOrderedByWinPercentage
  );
  const eightyPercentTradeCollectionOrderedByWinPercentage = useSelector(
    (state: RootState) =>
      state.homeState.eightyPercentTradeCollectionOrderedByWinPercentage
  );

  useEffect(() => {
    if (date && minimumNumberOfTrades > 0 && user) {
      // console.log({ targetYear });
      dispatch(
        getDistinctTradeCollectionAction(
          date,
          minimumNumberOfTrades,
          user,
          targetYear
        )
      );
    }
  }, [date, minimumNumberOfTrades, targetYear]);

  return (
    <div>
      Welkom {user?.username}
      {/*<Link to={`/seasonal-trades/markets=${markets}`}>test</Link>*/}
      <div>
        <h1>Trades for today</h1>
        <div>
          <label>Minimum Number Of Trades</label>
          <DelayedInput
            timeoutInMs={3000}
            type={"number"}
            value={minimumNumberOfTrades}
            onChange={(value) => {
              setMinimumNumberOfTrades(value);
            }}
            placeholder={"minimumNumberOfTrades"}
          />
        </div>
        <DatePicker selected={date} onChange={(date: any) => setDate(date)} />

        {seventyPercentTradeCollectionOrderedByNumberOfTrades.length > 0 && (
          <SeasonalDisplayComponent
            name={"70%+ trades with most number of trades filtered"}
            data={seventyPercentTradeCollectionOrderedByNumberOfTradesFiltered}
          />
        )}
        <div>
          <button onClick={() => setOc8Filter((old) => !old)}>
            8oc filter {oc8Filter ? "YES" : "NO"}
          </button>
          <button onClick={() => setMom28Filter((old) => !old)}>
            mom28 filter {mom28Filter ? "YES" : "NO"}
          </button>
          <button onClick={() => setSheetTradesFilter((old) => !old)}>
            sheetTradesFilter {sheetTradesFilter ? "YES" : "NO"}
          </button>
        </div>
        {seventyPercentTradeCollectionOrderedByNumberOfTrades.length > 0 && (
          <SeasonalDisplayComponent
            name={"70%+ trades with most number of trades"}
            data={seventyPercentTradeCollectionOrderedByNumberOfTrades}
          />
        )}
        {eightyPercentTradeCollectionOrderedByNumberOfTrades.length > 0 && (
          <SeasonalDisplayComponent
            name={"80%+ trades with most number of trades"}
            data={eightyPercentTradeCollectionOrderedByNumberOfTrades}
          />
        )}
        {seventyPercentTradeCollectionOrderedByPlRatio.length > 0 && (
          <SeasonalDisplayComponent
            name={"70%+ with highest pl ratio"}
            data={seventyPercentTradeCollectionOrderedByPlRatio}
          />
        )}
        {eightyPercentTradeCollectionOrderedByPlRatio.length > 0 && (
          <SeasonalDisplayComponent
            name={"80%+ with highest pl ratio"}
            data={eightyPercentTradeCollectionOrderedByPlRatio}
          />
        )}
        {seventyPercentTradeCollectionOrderedByWinPercentage.length > 0 && (
          <SeasonalDisplayComponent
            name={"70%+ with highest win percentage"}
            data={seventyPercentTradeCollectionOrderedByWinPercentage}
          />
        )}
        {eightyPercentTradeCollectionOrderedByWinPercentage.length > 0 && (
          <SeasonalDisplayComponent
            name={"80%+ with highest win percentage"}
            data={eightyPercentTradeCollectionOrderedByWinPercentage}
          />
        )}
      </div>
    </div>
  );
}
