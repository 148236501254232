import SeasonalTableOld from "../seasonal-searcher/seasonal-table-old";
import { useSelector } from "react-redux";
import { columnSettingsToRealColumns } from "../../functions/table-column-functions";
import { Trade } from "@backend/entities/trade";
import { RootState } from "../..";
import { tradesToCsvFile } from "../../functions/csv-functions";
import { useState } from "react";

export function SeasonalDisplayComponent({
  name,
  data,
  hiddenByDefault = false,
}: {
  name: string;
  data: Trade[];
  hiddenByDefault?: boolean;
}) {
  // useEffect(()=>{
  //
  // })

  const [hidden, setHidden] = useState<boolean>(hiddenByDefault);

  const columns = useSelector((state: RootState) => {
    if (state.loginState.user?.homePageColumns) {
      return columnSettingsToRealColumns(state.loginState.user.homePageColumns);
    } else {
      return [];
    }
  });

  return (
    <div>
      <div className={"seasonal-display-name"}>
        <h3>{name}</h3>
        <button onClick={(event) => setHidden((old) => !old)}>
          {hidden ? "show" : "hide"}
        </button>
        <button
          onClick={() => {
            tradesToCsvFile(data);
          }}
        >
          export to csv
        </button>
      </div>
      {!hidden && <SeasonalTableOld data={data} columns={columns} />}
    </div>
  );
}
