import { Dispatch } from "@reduxjs/toolkit";
import { TradeCriteria } from "../../../seasonal-back/src/classes/trade-criteria";
import { User } from "../../../seasonal-back/src/entities/user";
import { addRelevantHomePageMarket } from "../reducers/correlations-reducer";
import { setUsdExchangeRates } from "../reducers/exchange-rates-reducer";
import {
  setDistinctTradeCollectionStatus,
  setEightyPercentTradeCollectionOrderedByNumberOfTrades,
  setEightyPercentTradeCollectionOrderedByPlRatio,
  setEightyPercentTradeCollectionOrderedByWinPercentage,
  setSeventyPercentTradeCollectionOrderedByNumberOfTrades,
  setSeventyPercentTradeCollectionOrderedByPlRatio,
  setSeventyPercentTradeCollectionOrderedByWinPercentage,
} from "../reducers/home-reducer";
import { setSheetTrades } from "../reducers/sheet-reducer";
import { fetchingTrade, setTrade } from "../reducers/trade-reducer";
import {
  fetchingTrades,
  fetchingTradesFailed,
  setTrades,
} from "../reducers/trades-reducer";
import {
  getDistinctTrades,
  getSheetTrades,
  getSymbols,
  getTrade,
  getTrades,
  getTradeWithCalculations,
  getTradeWithRelations,
  getUsdExchangeRates,
} from "../requests/general-requests";

export function getTradesAction(tradeCriteria: TradeCriteria) {
  return (dispatch: Dispatch) => {
    dispatch(fetchingTrades());
    getTrades(tradeCriteria)
      .then((res) => dispatch(setTrades(res.data.data)))
      .catch((error) => dispatch(fetchingTradesFailed(error)));
  };
}

export function getTradeAction(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(fetchingTrade());
    getTrade(id).then((response) => dispatch(setTrade(response.data.data)));
  };
}

export function getTradeWithCalculationsAction(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(fetchingTrade());
    getTradeWithCalculations(id).then((response) =>
      dispatch(setTrade(response.data.data))
    );
  };
}

export function getTradeWithRelationsAction(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(fetchingTrade());
    getTradeWithRelations(id).then((response) =>
      dispatch(setTrade(response.data.data))
    );
  };
}

export function getDistinctTradeCollectionAction(
  date: Date,
  minimumNumberOfTrades: number,
  user: User,
  targetYear: number
) {
  return (dispatch: Dispatch) => {
    dispatch(setDistinctTradeCollectionStatus("loading"));
    let openDay = date.getDate();
    let openMonth = date.getMonth() + 1;

    let relevantMarkets = [];

    if (
      user.renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades
    ) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 70,
        minimumPlRatio: 4,
        orderColumn: "numberOfTrades",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setSeventyPercentTradeCollectionOrderedByNumberOfTrades(res.data.data)
        );
      });
    }
    if (
      user.renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades
    ) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 80,
        minimumPlRatio: 4,
        orderColumn: "numberOfTrades",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setEightyPercentTradeCollectionOrderedByNumberOfTrades(res.data.data)
        );
      });
    }
    if (user.renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 70,
        minimumPlRatio: 4,
        orderColumn: "plRatio",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setSeventyPercentTradeCollectionOrderedByPlRatio(res.data.data)
        );
      });
    }
    if (user.renderHomePageEightyPercentTradeCollectionOrderedByPlRatio) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 80,
        minimumPlRatio: 4,
        orderColumn: "plRatio",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setEightyPercentTradeCollectionOrderedByPlRatio(res.data.data)
        );
      });
    }
    if (
      user.renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage
    ) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 70,
        minimumPlRatio: 4,
        orderColumn: "winPercentage",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setSeventyPercentTradeCollectionOrderedByWinPercentage(res.data.data)
        );
      });
    }
    if (user.renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage) {
      getDistinctTrades({
        openDay,
        openMonth,
        minimumWinPercentage: 80,
        minimumPlRatio: 4,
        orderColumn: "winPercentage",
        minimumNumberOfTrades,
        targetYear,
      }).then((res) => {
        dispatch(addRelevantHomePageMarket(res.data.data));
        dispatch(
          setEightyPercentTradeCollectionOrderedByWinPercentage(res.data.data)
        );
      });
    }
  };
}

export function getSheetTradesAction() {
  return (dispatch: Dispatch) => {
    getSheetTrades().then((response) =>
      dispatch(setSheetTrades(response.data.data))
    );
  };
}

export function getUsdExchangeRatesAction() {
  return (dispatch: Dispatch) => {
    getUsdExchangeRates()
      .then((response) => dispatch(setUsdExchangeRates(response.data.data)))
      .catch((error) => console.log(error));
  };
}
