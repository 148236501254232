import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./home/home";
import { SeasonalSearcher } from "./seasonal-searcher/seasonal-searcher";
import { Settings } from "./settings/settings";
import { TradeDetail } from "./trade-detail/trade-detail";
import { Markets } from "./markets/markets";
import { MarketCreate } from "./markets/market-create";
import AdminPanel from "./admin-panel/admin-panel";
import useIsAdmin from "../hooks/use-is-admin";

import Changelog from "./changelog/changelog";
import PresetDownloading from "./preset-downloading/preset-downloading";
import CorrelationOverview from "./correlation-overview/correlation-overview";
import TradingHours from "./trading-hours/trading-hours";
import QuoteCollections from "./quote-collections/quote-collections";
import QuoteCollection from "./quote-collections/quote-collection";
import DataSources from "./data-sources/data-sources";
import DataSourceDetailView from "./data-sources/data-source-detail-view";
import MarketDetailView from "./markets/market-detail-view";
import Wizards from "./wizards/wizards";
import InsertQuoteWizard from "./wizards/insert-quotes-wizard/insert-quotes-wizard";
import Health from "./health/health";
import QuoteCollectionsHealth from "./health/quote-collections-health";
import CreateQuoteCollection from "./quote-collections/create-quote-collection";
import CreateDataSource from "./data-sources/create-data-source";
import MergeQuoteCollectionWizard from "./wizards/merge-quote-collection-wizard/merge-quote-collection-wizard";
import CompareQuoteCollection from "./quote-collections/compare-quote-collection";
import MetatraderAccounts from "./metatrader-accounts/metatrader-accounts";
import MetatraderAccount from "./metatrader-accounts/metatrader-account";
import Dashboard from "./dashboard/dashboard";
import DataSourceMarketCoverage from "./data-sources/data-source-market-coverage";
import TradeSelection from "./trade-selection/trade-selection";
import Charting from "./charts/charting";
import MarketChart from "./charts/market-chart";
import Swarms from "./swarms/swarms";
import SimplefxCalculator from "./simplefx-calculator/simplefx-calculator";
import SwarmDetail from "./swarms/swarm-display";
import InsertDividendWizard from "./wizards/insert-dividend-wizard/insert-dividend-wizard";

export default function SeasonalSwitch() {
  const isAdmin = useIsAdmin();

  return (
    <Routes>
      {/*<Route path={"/jarvis"} component={Jarvis} />*/}
      {/*<Route path={"/seasonal/trades"} component={Trades} />*/}
      {isAdmin && <Route path={"/admin-panel"} element={<AdminPanel />} />}

      <Route path={"/charting"} element={<Charting />} />
      <Route path={"/market-chart/:symbol"} element={<MarketChart />} />
      <Route path={"/swarms/:swarmId"} element={<SwarmDetail />} />
      <Route path={"/swarms"} element={<Swarms />} />
      <Route path={"/simplefx-calculator"} element={<SimplefxCalculator />} />
      <Route
        path={"/quote-collections/compare"}
        element={<CompareQuoteCollection />}
      />
      <Route
        path={"/quote-collections/create"}
        element={<CreateQuoteCollection />}
      />
      <Route
        path={"/quote-collections/:quoteCollectionId"}
        element={<QuoteCollection />}
      />
      <Route path={"/quote-collections"} element={<QuoteCollections />} />
      <Route path={"/data-sources/create"} element={<CreateDataSource />} />
      {/* <Route */}
      {/*   path={"/data-sources/:dataSourceId/market-coverage"} */}
      {/*   element={<DataSourceMarketCoverage />} */}
      {/* /> */}
      <Route
        path={"/data-sources/:dataSourceId"}
        element={<DataSourceDetailView />}
      />
      <Route path={"/data-sources"} element={<DataSources />} />
      <Route path={"/trading-hours"} element={<TradingHours />} />
      <Route path={"/markets/:marketId"} element={<MarketDetailView />} />
      <Route path={"/markets/create"} element={<MarketCreate />} />

      {/* <Route path={"/markets/:marketId"} element={<MarketListItem />} /> */}
      <Route path={"/markets"} element={<Markets />} />
      <Route path={"/seasonal-searcher"} element={<SeasonalSearcher />} />
      <Route path={"/settings"} element={<Settings />} />
      <Route path={`/trade/:tradeId`} element={<TradeDetail />} />

      {/*<Route path={"/back-test"} element={<BackTest />} />*/}
      {/*<Route path={"/assessment"} element={<Assessment />} />*/}
      {/*<Route path={"/equity-time-series/insert"} element={EquityTimeSeries} />*/}
      <Route
        path={"/accounts/:accountNumber"}
        element={<MetatraderAccount />}
      />
      <Route path={"/accounts"} element={<MetatraderAccounts />} />
      {/* <Route path={"/changelog"} element={<Changelog />} /> */}
      <Route
        path={"/wizards/insert-dividend-wizard"}
        element={<InsertDividendWizard />}
      />
      <Route
        path={"/wizards/insert-quote-wizard"}
        element={<InsertQuoteWizard />}
      />
      <Route
        path={"/wizards/merge-quote-collection-wizard"}
        element={<MergeQuoteCollectionWizard />}
      />

      <Route path={"/wizards"} element={<Wizards />} />
      {isAdmin && (
        <>
          <Route
            path={"/health/quote-collections-health"}
            element={<QuoteCollectionsHealth />}
          />
          <Route path={"/health"} element={<Health />} />
        </>
      )}
      <Route
        path={"/presets/:tradeIdsString"}
        element={<PresetDownloading />}
      />
      <Route path={"/trade-selection"} element={<TradeSelection />} />
      <Route path={"/correlation-overview"} element={<CorrelationOverview />} />
      <Route path={"/dashboard"} element={<Dashboard />} />
      <Route path={"/home"} element={<Home />} />
    </Routes>
  );
}
