import React, { useState } from "react";
import { useDispatch } from "react-redux";

export function DoubleClickButton({
  f,
  label,
  secondLabel,
}: {
  f: () => any;
  label: string;
  secondLabel: string;
}) {
  // const dispatch = useDispatch();

  const [clickedOnce, setClickedOnce] = useState(false);

  if (clickedOnce) {
    return (
      <button
        onClick={() => {
          f();
          setClickedOnce(false);
        }}
      >
        {secondLabel}
      </button>
    );
  }
  return <button onClick={() => setClickedOnce(true)}>{label}</button>;
}
