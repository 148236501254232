import React from "react";

export default function CheckboxInput({ value, setValue, label }: any) {
  return (
    <div>
      <label>{label}</label>
      <input
        type={"checkbox"}
        checked={value}
        onChange={(event) => setValue(event.target.checked)}
      />
    </div>
  );
}
