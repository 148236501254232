import { Correlation } from "@backend/entities/correlation";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { filterCorrelationsByIds } from "../../functions/correlation-functions";
import { RootState } from "../../index";
import DelayedInput from "../inputs/delayed-input";

export default function CorrelationOverview() {
  const correlations = useSelector(
    (state: RootState) => state.correlationsState.correlations
  );

  const markets = useSelector((state: RootState) => state.marketsState.markets);

  const [filter, setFilter] = useState("");

  const [filteredCorrelations, setFilteredCorrelations] = useState<
    Correlation[]
  >([]);

  const [marketIdHashMap, setMarketIdHashMap] = useState<Map<number, string>>(
    new Map()
  );

  useEffect(() => {
    let map = new Map<number, string>();
    for (let market of markets) {
      map.set(market.id, market.symbol);
    }
    setMarketIdHashMap(map);
  }, [markets]);

  useEffect(() => {
    if (correlations && correlations.length > 0) {
      if (filter === "") {
        setFilteredCorrelations(correlations);
      } else {
        // let matchedCorrelations = correlations.map(correlation=>{
        //     return Object.assign({},correlation,{firstMarketSymbol:markets.find(market=>market.id===correlation.firstMarketId).symbol,secondMarketSymbol:markets.find(market=>market.id===correlation.secondMarketId).symbol})
        // })
        let [firstSymbol, secondSymbol] = filter.split(",");
        let ids = [];
        if (firstSymbol) {
          let firstMarket = markets.find(
            (market) =>
              market.symbol.toLowerCase() === firstSymbol.toLowerCase()
          );
          if (firstMarket) ids.push(firstMarket.id);
        }
        if (secondSymbol) {
          let secondMarket = markets.find(
            (market) =>
              market.symbol.toLowerCase() === secondSymbol.toLowerCase()
          );
          if (secondMarket) ids.push(secondMarket.id);
        }

        setFilteredCorrelations(filterCorrelationsByIds(correlations, ids));
      }
    }
  }, [filter, correlations, markets]);

  return (
    <div>
      <h1>Correlation Overview</h1>
      <label>Filter</label>
      <DelayedInput
        timeoutInMs={1000}
        value={filter}
        onChange={(value) => setFilter(value)}
        placeholder={"example: usdjpy,gbpjpy"}
        type={"string"}
      />
      <table>
        <thead>
          <tr>
            <th>First Market</th>
            <th>Second Market</th>
            <th>Correlation Value</th>
            <th>Correlation period</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );

  function renderRows() {
    return filteredCorrelations.map((correlation) => {
      return (
        <tr>
          <td>{marketIdHashMap.get(correlation.firstMarketId)}</td>
          <td>{marketIdHashMap.get(correlation.secondMarketId)}</td>
          <td
            className={
              correlation.value >= 0.8 || correlation.value <= -0.8 ? "red" : ""
            }
          >
            {correlation.value}
          </td>
          <td>{correlation.period}</td>
        </tr>
      );
    });
  }
}
