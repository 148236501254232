import { Trade } from "@backend/entities/trade";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { TradeType } from "@backend/enums/trade-type";
import { SortableTable } from "../table/sortable-table";

export function BlackSwanDisplay({
  trades,
  riskPercentage,
}: {
  trades: Trade[];
  riskPercentage: number;
}) {
  const marketsMap = useSelector(
    (state: RootState) =>
      new Map(state.marketsState.markets.map((market) => [market.id, market]))
  );

  const [processedTrades, setProcessedTrades] = useState<
    {
      tradeId: string;
      tradeType: TradeType;
      marketSymbol: string;
      worstCaseLossPercentage: number;
    }[]
  >([]);
  useEffect(() => {
    let things: typeof processedTrades = [];
    for (let trade of trades) {
      let matchedMarket = marketsMap.get(trade.marketId);
      if (!matchedMarket) continue;
      let hl =
        trade.type === TradeType.Long
          ? Math.abs(trade.lowestPercentageChange)
          : Math.abs(trade.highestPercentageChange);

      let hlc =
        trade.type === TradeType.Long
          ? Math.abs(trade.lowestPercentageChangeClose)
          : Math.abs(trade.highestPercentageChangeClose);
      let worstCaseLossPercentage =
        trade.type === TradeType.Long
          ? (matchedMarket.lowestDailyPercentageChange / hlc) * riskPercentage
          : (matchedMarket.highestDailyPercentageChange / hlc) * riskPercentage;
      things.push({
        tradeId: trade.id,
        tradeType: trade.type,
        marketSymbol: matchedMarket.symbol,
        worstCaseLossPercentage: Math.abs(worstCaseLossPercentage),
      });
    }
    let sum = 0;
    for (let thing of things) {
      sum += thing.worstCaseLossPercentage;
    }
    things.push({
      tradeId: "TOTAL",
      marketSymbol: "TOTAL",
      tradeType: TradeType.Long,
      worstCaseLossPercentage: sum,
    });

    setProcessedTrades(things);
  }, [trades, riskPercentage, marketsMap]);

  return (
    <div>
      {processedTrades.length > 0 && <SortableTable data={processedTrades} />}
    </div>
  );
}
