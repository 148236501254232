import axios, { AxiosResponse } from "axios";
import {
  compareQuoteCollectionsEndpoint,
  getQuoteCollectionEndpoint,
  getQuoteCollectionsEndpoint,
  getQuoteCollectionsMetadataEndpoint,
  postMergeQuoteCollectionsEndpoint,
  postQuoteCollectionEndpoint,
  postQuoteCollectionsMatchesCountEndpoint,
  postQuoteCollectionsSimilarityEndpoint,
  toggleQuoteCollectionEndpoint,
} from "./endpoints";
import { QuoteCollectionCreationBody } from "@backend/request-bodies/quote-collection-creation-body";
import { QuoteCollectionMerge } from "@backend/request-bodies/quote-collection-merge";
import { QuoteCollectionMatchesCountBody } from "@backend/request-bodies/quote-collection-matches-count-body";
import { QuoteCollectionSimilarityRequestBody } from "@backend/request-bodies/quote-collection-similarity-request-body";
import { QuoteCollectionsCompareRequestBody } from "@backend/request-bodies/quote-collections-compare-request-body";
import { QuoteCollection } from "@backend/entities/quote-collection";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { QuoteCollectionMetadata } from "@backend/entities/quote-collection-metadata";
import { UpdateResponseBody } from "@backend/response-bodies/update-response-body";

export function toggleQuoteCollection(
  quoteCollectionId: number
): Promise<AxiosResponse<UpdateResponseBody<QuoteCollection>>> {
  return axios.put(toggleQuoteCollectionEndpoint(quoteCollectionId));
}

export function getQuoteCollections(): Promise<
  AxiosResponse<ReadResponseBody<QuoteCollection[]>>
> {
  return axios.get(getQuoteCollectionsEndpoint);
}

export function getQuoteCollectionsMetadata(): Promise<
  AxiosResponse<ReadResponseBody<QuoteCollectionMetadata[]>>
> {
  return axios.get(getQuoteCollectionsMetadataEndpoint);
}

export function getQuoteCollection(
  quoteCollectionId: number,
  quotes = false
): Promise<AxiosResponse<ReadResponseBody<QuoteCollection>>> {
  return axios.get(getQuoteCollectionEndpoint(quoteCollectionId, quotes));
}

export function postQuoteCollection(
  quoteCollectionCreationBody: QuoteCollectionCreationBody
): Promise<AxiosResponse<CreateResponseBody<QuoteCollection>>> {
  return axios.post(postQuoteCollectionEndpoint, quoteCollectionCreationBody);
}

export function postMergeQuoteCollection(
  quoteCollectionMerge: QuoteCollectionMerge
): Promise<AxiosResponse<ReadResponseBody<any>>> {
  return axios.post(postMergeQuoteCollectionsEndpoint, quoteCollectionMerge);
}

export function postQuoteCollectionMatchesCount(
  quoteCollectionMatchesCountBody: QuoteCollectionMatchesCountBody
): Promise<AxiosResponse<ReadResponseBody<number>>> {
  return axios.post(
    postQuoteCollectionsMatchesCountEndpoint,
    quoteCollectionMatchesCountBody
  );
}

export function postQuoteCollectionSimilarity(
  quoteCollectionSimilarityRequestBody: QuoteCollectionSimilarityRequestBody
): Promise<AxiosResponse<ReadResponseBody<number>>> {
  return axios.post(
    postQuoteCollectionsSimilarityEndpoint,
    quoteCollectionSimilarityRequestBody
  );
}

export function compareQuoteCollections(
  quoteCollectionsCompareRequestBody: QuoteCollectionsCompareRequestBody
): Promise<AxiosResponse<ReadResponseBody<any>>> {
  return axios.post(
    compareQuoteCollectionsEndpoint,
    quoteCollectionsCompareRequestBody
  );
}
