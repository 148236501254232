import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SettingsState = {
  tradeTableColumns: any;
  tradeTableColumnsOptions: any;
  targetYear: number;
};

const initialState = {
  tradeTableColumns: [],
  tradeTableColumnsOptions: [],
  targetYear: new Date().getFullYear(),
} as SettingsState;

export const settingsStateSlice = createSlice({
  name: "settingsState",
  initialState,
  reducers: {
    setTradeTableColumns(state, action: PayloadAction<any>) {
      let tradeTableColumnOptions = action.payload.map((columnString: any) => {
        return { value: columnString, label: columnString };
      });
      tradeTableColumnOptions.push({
        value: "cartButton",
        label: "cartButton",
      });
      state.tradeTableColumns = action.payload.columns;
      state.tradeTableColumnsOptions = tradeTableColumnOptions;
    },
    setTargetYear(state, action: PayloadAction<number>) {
      state.targetYear = action.payload;
    },
  },
});

export const settingsState = settingsStateSlice.reducer;
export const { setTradeTableColumns, setTargetYear } =
  settingsStateSlice.actions;
