import { Trade } from "@backend/entities/trade";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type HomeState = {
  seventyPercentTradeCollectionOrderedByPlRatio: Trade[];
  eightyPercentTradeCollectionOrderedByPlRatio: Trade[];
  eightyPercentTradeCollectionOrderedByNumberOfTrades: Trade[];
  seventyPercentTradeCollectionOrderedByNumberOfTrades: Trade[];
  eightyPercentTradeCollectionOrderedByWinPercentage: Trade[];
  seventyPercentTradeCollectionOrderedByWinPercentage: Trade[];
  status: string;
};

const initialState = {
  seventyPercentTradeCollectionOrderedByPlRatio: [],
  eightyPercentTradeCollectionOrderedByPlRatio: [],
  eightyPercentTradeCollectionOrderedByNumberOfTrades: [],
  seventyPercentTradeCollectionOrderedByNumberOfTrades: [],
  eightyPercentTradeCollectionOrderedByWinPercentage: [],
  seventyPercentTradeCollectionOrderedByWinPercentage: [],
  status: "idle",
} as HomeState;

const homeStateSlice = createSlice({
  name: "homeState",
  initialState,
  reducers: {
    setDistinctTradeCollectionStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setSeventyPercentTradeCollectionOrderedByPlRatio(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.seventyPercentTradeCollectionOrderedByPlRatio = action.payload;
    },
    setEightyPercentTradeCollectionOrderedByPlRatio(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.eightyPercentTradeCollectionOrderedByPlRatio = action.payload;
    },
    setEightyPercentTradeCollectionOrderedByNumberOfTrades(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.eightyPercentTradeCollectionOrderedByNumberOfTrades =
        action.payload;
    },
    setSeventyPercentTradeCollectionOrderedByNumberOfTrades(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.seventyPercentTradeCollectionOrderedByNumberOfTrades =
        action.payload;
    },
    setEightyPercentTradeCollectionOrderedByWinPercentage(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.eightyPercentTradeCollectionOrderedByWinPercentage = action.payload;
    },
    setSeventyPercentTradeCollectionOrderedByWinPercentage(
      state,
      action: PayloadAction<Trade[]>
    ) {
      state.seventyPercentTradeCollectionOrderedByWinPercentage =
        action.payload;
    },
  },
});

export const homeState = homeStateSlice.reducer;
export const {
  setDistinctTradeCollectionStatus,
  setSeventyPercentTradeCollectionOrderedByPlRatio,
  setEightyPercentTradeCollectionOrderedByPlRatio,
  setEightyPercentTradeCollectionOrderedByNumberOfTrades,
  setSeventyPercentTradeCollectionOrderedByNumberOfTrades,
  setEightyPercentTradeCollectionOrderedByWinPercentage,
  setSeventyPercentTradeCollectionOrderedByWinPercentage,
} = homeStateSlice.actions;
