import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../..";

export default function SimpleFxSymbolSelect({ value, setValue }: any) {
  const simpleFxSymbolOptions = useSelector((state: RootState) => {
    return state.simpleFxState.parsedSimpleFxMarkets.map((market) => {
      return { value: market.symbol, label: market.symbol };
    });
  });

  return (
    <div>
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        value={{ value: value, label: value }}
        onChange={handleChange}
        options={simpleFxSymbolOptions}
      />
    </div>
  );

  function handleChange(option: any) {
    setValue(option.value);
  }
}
