import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpinnerIcon } from "../icons/spinner-icon";
import ReactJson from "react-json-view";
import QuotesReactTable from "../tables/quotes-react-table";
import { getQuoteCollection } from "../../requests/quote-collections-requests";
import { QuoteCollection as QuoteCollectionEntity } from "@backend/entities/quote-collection";

export default function QuoteCollection() {
  const { quoteCollectionId } = useParams();

  const [quoteCollection, setQuoteCollection] =
    useState<QuoteCollectionEntity | null>(null);

  useEffect(() => {
    if (quoteCollectionId)
      getQuoteCollection(parseInt(quoteCollectionId), true)
        .then((response) => setQuoteCollection(response.data.data))
        .catch((error) => console.error(error));
  }, []);

  return <>{renderQuoteCollection()}</>;

  function renderQuoteCollection() {
    if (!quoteCollection)
      return (
        <div>
          <SpinnerIcon />
        </div>
      );
    return (
      <div style={{ overflow: "visible", height: "100%", width: "100%" }}>
        <h1>Quote Collection:</h1>
        {renderJsonView()}
        {/*<div>Id: {quoteCollection.id}</div>*/}
        {/*<div>MarketId: {quoteCollection.marketId}</div>*/}
        {/*<div>Period: {quoteCollection.period}</div>*/}
        {/*<div>DataSourceId: {quoteCollection.dataSourceId}</div>*/}
        {renderQuoteTable()}
      </div>
    );
  }

  function renderQuoteTable() {
    if (quoteCollection?.quotes)
      return <QuotesReactTable quotes={quoteCollection.quotes} />;
    return <></>;
  }

  function renderJsonView() {
    return (
      <ReactJson
        src={getQuoteCollectionWithoutQuotes()}
        collapsed={1}
        displayDataTypes={false}
      />
    );
  }

  function getQuoteCollectionWithoutQuotes() {
    let quoteCollectionWithoutQuotes = { ...quoteCollection };
    delete quoteCollectionWithoutQuotes["quotes"];
    return quoteCollectionWithoutQuotes;
  }
}
