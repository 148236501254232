import React from "react";
import LogoutIcon from "../icons/logout-icon.js";
import "./logout-button.scss";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export default function LogoutButton() {
  const [cookies, setCookie, removeCookie] = useCookies();

  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        removeCookie("token");
        removeCookie("refreshToken");
        dispatch({ type: "LOGOUT_SUCCESS" });
      }}
      title={"logout"}
      className={"logout-button"}
    >
      <LogoutIcon />
    </div>
  );
}
