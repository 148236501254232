import React, { useState } from "react";
import DataSourceSelect from "../../selects/data-source-select";
import "./merge-quote-collection-wizard.scss";
import MultipleMarketsSelect from "../../selects/multiple-markets-select";
import MultipleQuoteCollectionsSelect from "../../selects/multiple-quote-collections-select";
import { useSelector } from "react-redux";
import MatchesCount from "./matches-count";
import QuoteCollectionSimilarity from "./quote-collection-similarity";
import { RootState } from "../../..";
import { QuoteCollection } from "@backend/entities/quote-collection";
import { DataSource } from "@backend/entities/data-source";
import { postMergeQuoteCollection } from "../../../requests/quote-collections-requests";

export default function MergeQuoteCollectionWizard() {
  const [sourceDataSourceOption, setSourceDataSourceOption] =
    useState<any>(null);
  const [targetDataSourceOption, setTargetDataSourceOption] =
    useState<any>(null);
  const [deleteAfterMerge, setDeleteAfterMerge] = useState(false);
  const [onConflictUpdate, setOnConflictUpdate] = useState(false);
  const [selectedQuoteCollectionOptions, setSelectedQuoteCollectionOptions] =
    useState<any>(null);
  const [matches, setMatches] = useState([]);
  const [mergedQuoteCollections, setMergedQuoteCollections] = useState<any>([]);
  const [failedMergedQuoteCollections, setFailedMergedQuoteCollections] =
    useState<any>([]);

  const { quoteCollections, dataSources, markets } = useSelector(
    (state: RootState) => {
      return {
        quoteCollections: state.quoteCollectionsState.quoteCollections,
        dataSources: state.dataSourcesState.dataSources,
        markets: state.marketsState.markets,
      };
    }
  );

  function quoteCollectionToString(quoteCollection: QuoteCollection) {
    let market = markets.find(
      (market) => market.id === quoteCollection.marketId
    );
    let dataSource = dataSources.find(
      (dataSource) => dataSource.id === quoteCollection.dataSourceId
    );
    if (!market || !dataSource) return `NANI???`;
    return `${quoteCollection.id},${market.symbol},${quoteCollection.period},${dataSource.name}`;
  }

  return (
    <div className={"merge-quote-collection-wizard"}>
      <h1>Merge Quote Collection Wizard</h1>
      <h2>Select Data Sources</h2>
      <div className={"selects"}>
        <div>Merge</div>
        <div style={{ width: "200px" }}>
          <DataSourceSelect
            selectedOption={sourceDataSourceOption}
            setSelectedOption={setSourceDataSourceOption}
            dataSourceFilter={
              !!targetDataSourceOption
                ? (dataSource: DataSource) => {
                    return dataSource.id !== targetDataSourceOption.value;
                  }
                : null
            }
          />
        </div>

        <div>With</div>
        <div style={{ width: "200px" }}>
          <DataSourceSelect
            selectedOption={targetDataSourceOption}
            setSelectedOption={setTargetDataSourceOption}
            dataSourceFilter={
              sourceDataSourceOption
                ? (dataSource: DataSource) => {
                    return dataSource.id !== sourceDataSourceOption.value;
                  }
                : null
            }
          />
        </div>
      </div>
      {sourceDataSourceOption && targetDataSourceOption && (
        <>
          <h2>Options</h2>
          <div>
            <label>delete after merge:</label>
            <input
              type={"checkbox"}
              checked={deleteAfterMerge}
              onChange={(event) => setDeleteAfterMerge(event.target.checked)}
            />
          </div>

          <div>
            <label>on conflict update(unchecked = DO NOTHING):</label>
            <input
              type={"checkbox"}
              checked={onConflictUpdate}
              onChange={(event) => setOnConflictUpdate(event.target.checked)}
            />
          </div>

          <h2>Select Markets</h2>
          <MultipleQuoteCollectionsSelect
            selectedOptions={selectedQuoteCollectionOptions}
            setSelectedOptions={setSelectedQuoteCollectionOptions}
            filterFunction={(quoteCollection: QuoteCollection) => {
              let counterPartQuoteCollection = !!quoteCollections.find(
                (innerQuoteCollection) => {
                  return (
                    innerQuoteCollection.dataSourceId ===
                      targetDataSourceOption.value &&
                    innerQuoteCollection.period === quoteCollection.period &&
                    innerQuoteCollection.marketId === quoteCollection.marketId
                  );
                }
              );
              return (
                quoteCollection.dataSourceId === sourceDataSourceOption.value &&
                counterPartQuoteCollection
              );
            }}
          />
          <div>
            {selectedQuoteCollectionOptions && (
              <>
                <h3>Merging:</h3>
                <button onClick={merge}>MERGE!!!!!!</button>
                {selectedQuoteCollectionOptions &&
                  selectedQuoteCollectionOptions.map(
                    (quoteCollectionOption: any) => {
                      let quoteCollection = quoteCollections.find(
                        (quoteCollection) =>
                          quoteCollectionOption.value === quoteCollection.id
                      );
                      let counterPartQuoteCollection = quoteCollections.find(
                        (innerQuoteCollection) => {
                          return (
                            innerQuoteCollection.dataSourceId ===
                              targetDataSourceOption.value &&
                            innerQuoteCollection.period ===
                              quoteCollection?.period &&
                            innerQuoteCollection.marketId ===
                              quoteCollection?.marketId
                          );
                        }
                      );

                      let failedMerge = failedMergedQuoteCollections.find(
                        (merge: any) =>
                          merge.sourceQuoteCollectionId === quoteCollection?.id
                      );
                      let merged = mergedQuoteCollections.find(
                        (merge: any) =>
                          merge.sourceQuoteCollectionId === quoteCollection?.id
                      );

                      return (
                        <div>
                          {quoteCollection &&
                            quoteCollectionToString(quoteCollection)}{" "}
                          WITH{" "}
                          {counterPartQuoteCollection &&
                            quoteCollectionToString(
                              counterPartQuoteCollection
                            )}{" "}
                          {quoteCollection && counterPartQuoteCollection && (
                            <QuoteCollectionSimilarity
                              sourceQuoteCollectionId={quoteCollection.id}
                              targetQuoteCollectionId={
                                counterPartQuoteCollection.id
                              }
                            />
                          )}{" "}
                          <button
                            onClick={() => {
                              deleteSelectedQuoteCollectionOption(
                                quoteCollectionOption
                              );
                            }}
                          >
                            X
                          </button>
                          {failedMerge && (
                            <span style={{ color: "red" }}>
                              {failedMerge.error.message}
                            </span>
                          )}
                          {merged && (
                            <span style={{ color: "green" }}>
                              SUCCESSFULLY MERGED!!!!!!!(row count:
                              {merged.returnedRowCount})
                            </span>
                          )}
                        </div>
                      );
                    }
                  )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );

  function deleteSelectedQuoteCollectionOption(selectedOption: any) {
    setSelectedQuoteCollectionOptions((old: any) => {
      return old.filter(
        (quoteCollectionOption: any) =>
          quoteCollectionOption.value !== selectedOption.value
      );
    });
  }

  async function merge() {
    let quoteCollectionMerges = selectedQuoteCollectionOptions.map(
      (quoteCollectionOption: any) => {
        let quoteCollection = quoteCollections.find(
          (quoteCollection) =>
            quoteCollectionOption.value === quoteCollection.id
        );
        let counterPartQuoteCollection = quoteCollections.find(
          (innerQuoteCollection) => {
            return (
              innerQuoteCollection.dataSourceId ===
                targetDataSourceOption.value &&
              innerQuoteCollection.period === quoteCollection?.period &&
              innerQuoteCollection.marketId === quoteCollection?.marketId
            );
          }
        );

        return {
          sourceQuoteCollectionId: quoteCollection?.id,
          targetQuoteCollectionId: counterPartQuoteCollection?.id,
          deleteAfterMerge: deleteAfterMerge,
          onConflictUpdate: onConflictUpdate,
        };
      }
    );
    for (let merge of quoteCollectionMerges) {
      try {
        let response = await postMergeQuoteCollection(merge);
        setMergedQuoteCollections((old: any) =>
          old.concat([
            Object.assign({}, merge, {
              returnedRowCount: response.data.data.length,
            }),
          ])
        );
      } catch (e) {
        setFailedMergedQuoteCollections((old: any) =>
          old.concat([Object.assign({}, merge, { error: e })])
        );
      }
    }
  }
}
