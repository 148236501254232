import { DeleteResponseBody } from "@backend/response-bodies/delete-response-body";
import axios, { AxiosResponse } from "axios";
import { deleteSheetTradeEndpoint } from "./endpoints";

export function deleteSheetTrade(
  sheetId: number,
  tradeId: string
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteSheetTradeEndpoint(sheetId, tradeId));
}
