import React from "react";
import "./loading-ellipsis-icon.css";

export function LoadingEllipsisIcon() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
