import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QuoteCollectionRow from "./quote-collection-row";
import { Link } from "react-router-dom";
import "./quote-collections.scss";
import { RootState } from "../../index";
import { QuoteCollection } from "@backend/entities/quote-collection";
import DelayedInput from "../inputs/delayed-input";
import { flattenObject } from "../../functions/flatten-object";

export default function QuoteCollections() {
  const quoteCollections = useSelector(
    (state: RootState) => state.quoteCollectionsState.quoteCollections
  );

  const marketsMap = useSelector(
    (state: RootState) =>
      new Map(state.marketsState.markets.map((market) => [market.id, market]))
  );

  const [filteredQuoteCollections, setFilteredQuoteCollections] = useState<
    QuoteCollection[]
  >([]);
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    if (filter === "") setFilteredQuoteCollections(quoteCollections);
    else if (quoteCollections) {
      setFilteredQuoteCollections(
        quoteCollections.filter((entry) => {
          return fuzzyObjectMatch(
            filter,
            flattenObject({
              ...entry,
              market: marketsMap.get(entry.marketId),
            })
          );
        })
      );
    }
  }, [filter, quoteCollections]);

  return (
    <div className={"quote-collections"}>
      <h1>Quote Collections</h1>
      <Link to={"/quote-collections/create"}>Create Quote Collection</Link>
      <Link to={"/quote-collections/compare"}>Compare Quote Collections</Link>
      <DelayedInput
        placeholder="filter"
        value={filter}
        onChange={(value) => setFilter(value)}
        type={"string"}
        timeoutInMs={1000}
      />
      <div>{renderQuoteCollectionsTable()}</div>
    </div>
  );

  function renderQuoteCollectionsTable() {
    return (
      <table className={"quote-collections-table"}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Market</th>
            <th>Period</th>
            <th>Data Source</th>
            <th>Earliest Quote</th>
            <th>Latest Quote</th>
            <th>Years</th>
          </tr>
        </thead>
        <tbody>{renderQuoteCollectionRows()}</tbody>
      </table>
    );
  }

  function renderQuoteCollectionRows() {
    return [...filteredQuoteCollections]
      .sort((a, b) => a.id - b.id)
      .map((quoteCollection) => {
        return <QuoteCollectionRow quoteCollection={quoteCollection} />;
      });
  }
}
interface Options {
  keys?: string[];
  threshold?: number;
}

export function fuzzyObjectMatch(
  str: string,
  obj: Record<string, any>,
  options?: Options
): boolean {
  const { keys = Object.keys(obj), threshold = 0.6 } = options || {};

  for (const key of keys) {
    const value = obj[key];
    if (value && typeof value === "string") {
      const score = calculateSimilarityScore(value, str);
      if (score >= threshold) {
        return true;
      }
    }
  }

  return false;
}

function calculateSimilarityScore(str1: string, str2: string): number {
  const len1 = str1.length;
  const len2 = str2.length;
  const matrix: number[][] = [];

  for (let i = 0; i <= len1; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // deletion
        matrix[i][j - 1] + 1, // insertion
        matrix[i - 1][j - 1] + cost // substitution
      );
    }
  }

  const distance = matrix[len1][len2];
  return 1 - distance / Math.max(len1, len2);
}
