import { Market } from "@backend/entities/market";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type MarketState = {
  market: Market | null;
};

const initialState = {
  market: null,
} as MarketState;

export const marketStateSlice = createSlice({
  name: "marketState",
  initialState,
  reducers: {
    setMarket(state, action: PayloadAction<Market>) {
      state.market = action.payload;
    },
  },
});

export const marketState = marketStateSlice.reducer;
export const { setMarket } = marketStateSlice.actions;
