import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../..";

export default function DataSourceSelect({
  selectedOption,
  setSelectedOption,
  dataSourceFilter,
}: any) {
  const dataSourceOptions = useSelector((state: RootState) => {
    if (dataSourceFilter)
      return state.dataSourcesState.dataSources
        .filter(dataSourceFilter)
        .map((dataSource) => {
          return { value: dataSource.id, label: dataSource.name };
        });
    return state.dataSourcesState.dataSources.map((dataSource) => {
      return { value: dataSource.id, label: dataSource.name };
    });
  });

  return (
    <Select
      options={dataSourceOptions}
      value={selectedOption}
      onChange={(selectedOption) => setSelectedOption(selectedOption)}
    />
  );
}
