import React from "react";
import { Link } from "react-router-dom";
import "./wizards.css";

export default function Wizards() {
  return (
    <div className={"wizards"}>
      <h1>Wizards:</h1>
      <Link to={"/wizards/insert-dividend-wizard"}>Insert Dividend Wizard</Link>
      <Link to={"/wizards/insert-quote-wizard"}>Insert Quote Wizard</Link>
      <Link to={"/wizards/merge-quote-collection-wizard"}>
        Merge Quote Collection Wizard
      </Link>
    </div>
  );
}
