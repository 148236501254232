import { GoogleLogin } from "react-google-login";
import React, { FormEvent, useEffect, useState } from "react";
import "./login.scss";
import md5 from "md5";
import { useDispatch } from "react-redux";
import axios, { AxiosResponse } from "axios";
import {
  getHealthEndpoint,
  googleLoginEndpoint,
  loginEndpoint,
  postRefreshTokenEndpoint,
} from "../../requests/endpoints";
import { setLoginData } from "../../reducers/login-reducer";
import { useCookies } from "react-cookie";
import { LoginResponseBody } from "@backend/response-bodies/login-response-body";
import { digestMessageSHA256 } from "../../functions/hash";

export function Login() {
  const [cookies, setCookie, removeCookie] = useCookies();

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (cookies.token) {
      tokenLoginFlow(cookies.token)
        .then()
        .catch((error) => {
          removeCookie("token");
          console.error(error);
        });
    } else if (cookies.refreshToken) {
      refreshTokenLoginFlow(cookies.refreshToken)
        .then()
        .catch((error) => {
          removeCookie("refreshToken");
          console.error(error);
        });
    }
  }, []);

  return (
    <div className={"login-wrapper"}>
      <form onSubmit={handleSubmit} className={"login-form"}>
        <div>
          <div className={"label-input-pair"}>
            <label>username</label>
            <input
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              value={username}
            />
          </div>
          <div className={"label-input-pair"}>
            <label>password</label>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={"password"}
              value={password}
            />
          </div>
          <input type={"submit"} />
          <div>OR</div>
          <GoogleLogin
            onSuccess={loginSuccess}
            onFailure={loginFail}
            clientId={
              "908390087602-8q1f6gpn99350i4igdf2i3bfqjoah231.apps.googleusercontent.com"
            }
          />
        </div>
      </form>
    </div>
  );

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    login(username, await digestMessageSHA256(password))
      .then()
      .catch((error) => console.error(error));
  }

  function loginSuccess(googleLoginResponse: any) {
    googleLogin(googleLoginResponse.tokenId)
      .then()
      .catch((error) => console.error(error));
  }

  function loginFail(error: Error) {}

  async function googleLogin(googleToken: string) {
    let loginResponse = await axios.post(googleLoginEndpoint, {
      token: googleToken,
    });
    await generalLoginFlow(loginResponse);
  }

  async function login(username: string, password: string) {
    let loginResponse = await axios.post(loginEndpoint, { username, password });
    await generalLoginFlow(loginResponse);
  }

  async function generalLoginFlow(response: AxiosResponse<LoginResponseBody>) {
    let token = response.data.token;
    let refreshToken = response.data.refreshToken;

    let healthResponse = await axios.get(getHealthEndpoint, {
      headers: { Authorization: token },
    });

    let user = healthResponse.data.data;

    setTokenCookies(token, refreshToken);
    setAxiosHeader(token);

    dispatch(setLoginData(user));
  }

  async function tokenLoginFlow(token: string) {
    let healthResponse = await axios.get(getHealthEndpoint, {
      headers: { Authorization: token },
    });

    let user = healthResponse.data.data;

    setAxiosHeader(token);

    dispatch(setLoginData(user));
  }

  async function refreshTokenLoginFlow(refreshToken: string) {
    let refreshTokenResponse = await axios.post(postRefreshTokenEndpoint, {
      refreshToken,
    });
    let token = refreshTokenResponse.data.token;

    let healthResponse = await axios.get(getHealthEndpoint, {
      headers: { Authorization: token },
    });

    let user = healthResponse.data.data;

    setTokenCookies(token, refreshToken);
    setAxiosHeader(token);

    dispatch(setLoginData(user));
  }

  function setTokenCookies(token: string, refreshToken: string) {
    setCookie("token", token, {
      path: "/",
      expires: new Date(Date.now() + 3600000 * 10),
    });
    setCookie("refreshToken", refreshToken, {
      path: "/",
      expires: new Date(Date.now() + 3600000 * 24 * 7),
    });
  }

  function setAxiosHeader(token: string) {
    axios.defaults.headers.common["Authorization"] = token;
  }
}
