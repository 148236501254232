import { Correlation } from "@backend/entities/correlation";
import { Market } from "@backend/entities/market";
import { Trade } from "@backend/entities/trade";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CorrelationState = {
  correlations: Correlation[];
  relevantHomePageMarkets: Market[];
  calculateCorrelationsStatus: string;
  customCorrelations: Correlation[];
};

const initialState = {
  correlations: [],
  relevantHomePageMarkets: [],
  calculateCorrelationsStatus: "idle",
  customCorrelations: [],
} as CorrelationState;

const correlationStateSlice = createSlice({
  name: "correlationState",
  initialState,
  reducers: {
    setCorrelations(state, action: PayloadAction<Correlation[]>) {
      state.correlations = action.payload;
    },
    addRelevantHomePageMarket(state, action: PayloadAction<Trade[]>) {
      let newRelevantMarkets = [...state.relevantHomePageMarkets];
      newRelevantMarkets = newRelevantMarkets.concat(
        action.payload.map((trade) => trade.market)
      );
      let uniqueRelevantMarkets: Market[] = [];
      newRelevantMarkets.forEach((market) => {
        if (!uniqueRelevantMarkets.find((market2) => market2.id === market.id))
          uniqueRelevantMarkets.push(market);
      });
      state.relevantHomePageMarkets = uniqueRelevantMarkets;
    },
    setCustomCorrelations(state, action) {
      state.customCorrelations = action.payload.correlations;
    },
    setCalculateCorrelationsState(state, action) {
      state.calculateCorrelationsStatus = action.payload;
    },
  },
});

export const correlationsState = correlationStateSlice.reducer;

export const {
  setCorrelations,
  addRelevantHomePageMarket,
  setCustomCorrelations,
  setCalculateCorrelationsState,
} = correlationStateSlice.actions;
