import { Swarm } from "@backend/entities/swarm";
import { SwarmLog } from "@backend/entities/swarm-log";
import { AgentCommandBody } from "@backend/request-bodies/agent-command-body";
import { SwarmUpdateBody } from "@backend/request-bodies/swarm-update-body";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { Agent } from "@backend/types/agent";
import { SerializableSwarmInstance } from "@backend/types/serializable-swarm-instance";
import axios, { AxiosResponse } from "axios";
import {
  getSwarmInstanceEndpoint,
  getMeSwarmsEndpoint,
  getSwarmLogsEndpoint,
  getSwarmWithLogsEndpoint,
  postAgentCommandEndpoint,
  postSwarmEndpoint,
  putSwarmEndpoint,
  postAgentCommandEndpointString,
  postSwarmRestartEndpoint,
  getSwarmEndpoint,
} from "./endpoints";

export async function postSwarm(
  swarmName: string,
  userIds: number[]
): Promise<AxiosResponse<CreateResponseBody<Swarm>>> {
  return axios.post(postSwarmEndpoint, { swarmName, userIds });
}

export async function getMeSwarms(): Promise<
  AxiosResponse<ReadResponseBody<Swarm[]>>
> {
  return axios.get(getMeSwarmsEndpoint);
}

export async function putSwarm(
  swarmId: number,
  swarmUpdateBody: SwarmUpdateBody
) {
  return axios.put(putSwarmEndpoint(swarmId), swarmUpdateBody);
}

export async function getSwarmInstance(
  swarmId: number
): Promise<AxiosResponse<ReadResponseBody<SerializableSwarmInstance>>> {
  return axios.get(getSwarmInstanceEndpoint(swarmId));
}
export async function postAgentCommand(
  agentCommandBody: AgentCommandBody
): Promise<AxiosResponse<ReadResponseBody<Agent[]>>> {
  return axios.post(postAgentCommandEndpoint, agentCommandBody);
}

export async function getSwarmLogs(
  swarmId: number,
  limit: number = 0
): Promise<AxiosResponse<ReadResponseBody<SwarmLog[]>>> {
  return axios.get(getSwarmLogsEndpoint(swarmId, limit));
}
export async function getSwarmWithLogs(
  swarmId: number
): Promise<AxiosResponse<ReadResponseBody<Swarm>>> {
  return axios.get(getSwarmWithLogsEndpoint(swarmId));
}
export async function getSwarm(
  swarmId: number
): Promise<AxiosResponse<ReadResponseBody<Swarm>>> {
  return axios.get(getSwarmEndpoint(swarmId));
}

export async function restartSwarm(swarmId: number) {
  return axios.post(postSwarmRestartEndpoint(swarmId));
}
