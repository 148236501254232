import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./side-bar.scss";
import { StockIcon } from "../icons/stock-icon";
import { TradesIcon } from "../icons/trades-icon";
import { PriceCalcIcon } from "../icons/price-calc-icon";
import { SettingsIcon } from "../icons/settings-icon";
import { CartIcon } from "../icons/cart-icon";
import { BacktestIcon } from "../icons/backtest-icon";
import { DataManagementIcon } from "../icons/data-management-icon";
import { SearchBarComponent } from "../search-bar/search-bar-component";
import { HomeIcon } from "../icons/home-icon";
import { MarketIcon } from "../icons/market-icon";
import MarketsLastUpdatedLabel from "../informational-labels/markets-last-updated-label";
import { useSelector } from "react-redux";
import AdminPanelIcon from "../icons/admin-panel-icon";
import useIsAdmin from "../../hooks/use-is-admin";
import AssessmentIcon from "../icons/assesment-icon";
import AccountStats from "../sheets-stats/account-stats";
import ReceiptIcon from "../icons/receipt-icon";
import CorrelationIcon from "../icons/correlation-icons";
import LogoutButton from "../buttons/logout-button";
import TimeIcon from "../icons/time-icon";
import CollectionIcon from "../icons/collection-icon";
import SourcesIcon from "../icons/sources-icon";
import MagicIcon from "../icons/magic-icon";
import HealthIcon from "../icons/health-icon";
import BankAccountIcon from "../icons/bank-account-icon";
import DashboardIcon from "../icons/dashboard-icon";
import MoveDownIcon from "../icons/move-down-icon";
import Control from "react-select/dist/declarations/src/components/Control";
import ControllerIcon from "../icons/controller-icon";
import DashboardAlt from "../icons/dashboard-alt";
import NetworkingIcon from "../icons/networking-icon";
import CalculatorIcon from "../icons/calculator-icon";

export default function SideBar({ menuOpen }: { menuOpen: boolean }) {
  //
  // let history = useHistory();
  //
  // const [firstPathPart,setFirstPathPart] = useState();

  // useEffect(()=>{
  //     if(history.location.pathname){
  //         setFirstPathPart(history.location.pathname.split("/")[1]);
  //
  //     }
  //
  // })

  const isAdmin = useIsAdmin();

  return (
    <div className={"side-bar " + (menuOpen ? "" : "closed")}>
      <NavLink title={"Home"} to={"/home"}>
        <HomeIcon />
        {/*<label>Home</label>*/}
      </NavLink>
      <NavLink title={"Swarms"} to={"/swarms"}>
        <NetworkingIcon />
      </NavLink>
      <NavLink title={"Trade Selection"} to={"/trade-selection"}>
        <MoveDownIcon />
      </NavLink>
      <NavLink title={"Dashboard"} to={"/dashboard"}>
        <DashboardIcon />
        {/*<label>Home</label>*/}
      </NavLink>
      <NavLink title={"Trade Searcher"} to={"/seasonal-searcher"}>
        <StockIcon />
        {/*<label>Trade Searcher</label>*/}
      </NavLink>
      <NavLink title={"Simplefx Calculator"} to={"/simplefx-calculator"}>
        <CalculatorIcon />
      </NavLink>
      {/*<NavLink to={"/seasonal/trades"}>*/}
      {/*    <TradesIcon/>*/}
      {/*    <label>N/A</label>*/}
      {/*</NavLink>*/}
      {/*<NavLink title={"Price Calculator"} to={"/price-calculator"}>*/}
      {/*  <PriceCalcIcon />*/}
      {/*  {/1*<label>Price Calculator</label>*1/}*/}
      {/*</NavLink>*/}
      <NavLink title={"Markets"} to={"/markets"}>
        <MarketIcon />
        {/*<label>Markets</label>*/}
      </NavLink>
      <NavLink title={"Quote Collections"} to={"/quote-collections"}>
        <CollectionIcon />
        {/*<label>Markets</label>*/}
      </NavLink>
      <NavLink title={"Data Sources"} to={"/data-sources"}>
        <SourcesIcon />
        {/*<label>Markets</label>*/}
      </NavLink>
      {/*<NavLink title={"Equity"} to={"/equity"}>*/}
      {/*  <StockIcon />*/}
      {/*  {/1*<label>EquityOld</label>*1/}*/}
      {/*</NavLink>*/}
      <NavLink title={"Accounts"} to={"/accounts"}>
        <BankAccountIcon />
      </NavLink>
      {/*<NavLink title={"Back Test"} to={"/back-test"}>*/}
      {/*  <BacktestIcon />*/}
      {/*  /!*<label>Back Test</label>*!/*/}
      {/*</NavLink>*/}
      {/*<NavLink to={"/cart"}>*/}
      {/*    <CartIcon/>*/}
      {/*    <label>Trade Cart</label>*/}
      {/*</NavLink>*/}
      {/*<NavLink title={"Assessment"} to={"/assessment"}>*/}
      {/*  <AssessmentIcon />*/}
      {/*  /!*<label>Assessment</label>*!/*/}
      {/*</NavLink>*/}
      <NavLink title={"Correlation Overview"} to={"/correlation-overview"}>
        <CorrelationIcon />
      </NavLink>
      <NavLink title={"Trading Hours"} to={"/trading-hours"}>
        <TimeIcon />
        {/*<label>Settings</label>*/}
      </NavLink>
      <NavLink title={"Wizards"} to={"/wizards"}>
        <MagicIcon />
      </NavLink>
      <NavLink title={"Settings"} to={"/settings"}>
        <SettingsIcon />
        {/*<label>Settings</label>*/}
      </NavLink>
      {/* <NavLink title={"Changelog"} to={"/changelog"}> */}
      {/*   <ReceiptIcon /> */}
      {/* </NavLink> */}
      {isAdmin && (
        <>
          <NavLink title={"Health"} to={"/health"}>
            <HealthIcon />
          </NavLink>
          <NavLink title={"Admin Panel"} to={"/admin-panel"}>
            <AdminPanelIcon />

            {/*<label>Admin Panel</label>*/}
          </NavLink>
        </>
      )}
      <LogoutButton />
    </div>
  );
}
