import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

export function SearchBarComponent() {
  const [id, setId] = useState<string>("");
  let navigate = useNavigate();

  return (
    <>
      <form onSubmit={onSubmit} className={"search-bar"}>
        <input
          onChange={(e) => {
            setId(e.target.value);
          }}
          placeholder={"enter trade id"}
        />
        <input type={"submit"} />
      </form>
    </>
  );

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    navigate(`/trade/${id}`);
  }
}
