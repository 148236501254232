import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../..";

export default function QuoteCollectionSelect({
  selectedOption,
  setSelectedOption,
  filterFunction,
}: any) {
  const quoteCollectionOptions = useSelector((state: RootState) => {
    let options = filterFunction
      ? state.quoteCollectionsState.quoteCollections
          .filter(filterFunction)
          .map((quoteCollection) => {
            let market = state.marketsState.markets.find(
              (market) => market.id === quoteCollection.marketId
            );
            let dataSource = state.dataSourcesState.dataSources.find(
              (dataSource) => dataSource.id === quoteCollection.dataSourceId
            );

            return {
              value: quoteCollection.id,
              label: `${quoteCollection.id},${market?.symbol},${quoteCollection.period},${dataSource?.name}`,
            };
          })
      : state.quoteCollectionsState.quoteCollections.map((quoteCollection) => {
          let market = state.marketsState.markets.find(
            (market) => market.id === quoteCollection.marketId
          );
          let dataSource = state.dataSourcesState.dataSources.find(
            (dataSource) => dataSource.id === quoteCollection.dataSourceId
          );

          return {
            value: quoteCollection.id,
            label: `${quoteCollection.id},${market?.symbol},${dataSource?.name}`,
          };
        });

    return options;
  });
  return (
    <Select
      value={selectedOption}
      onChange={(newSelectedOption) => {
        setSelectedOption(newSelectedOption);
      }}
      options={quoteCollectionOptions}
    />
  );
}
