import React, { useEffect, useState } from "react";
import "./object-inputs.scss";

type Props<T> = {
  object: T;
  onUpdate: (updatedObject: T) => void;
  blacklist?: Array<keyof T>;
};

function ObjectInputs<T>({
  object,
  onUpdate,
  blacklist = [],
}: Props<T>): JSX.Element {
  const [updatedObject, setUpdatedObject] = useState<T | any>(object);
  const [updateClicked, setUpdateClicked] = useState<boolean>(false);

  const handleInputChange = (key: keyof T, value: string | boolean) => {
    setUpdatedObject({
      ...updatedObject,
      [key]: typeof value === "boolean" ? value : value.toString(),
    });
  };

  const handleSubmit = () => {
    setUpdateClicked(true);
    onUpdate(updatedObject);
  };

  useEffect(() => {
    if (updateClicked) {
      setUpdatedObject(object);
      setUpdateClicked(false);
    }
  }, [object]);

  const filteredKeys = Object.keys(updatedObject).filter(
    (key) => !blacklist.includes(key as keyof T)
  );

  return (
    <div className="object-inputs">
      {filteredKeys.map((key: any) => {
        let entry: any = updatedObject[key];
        return (
          <div className="object-inputs__input-container" key={key}>
            <label className="object-inputs__label">{key}</label>
            <input
              className="object-inputs__input"
              type={typeof entry === "boolean" ? "checkbox" : "text"}
              checked={typeof entry === "boolean" ? entry : false}
              value={typeof entry === "boolean" ? "" : updatedObject[key]}
              onChange={(e) =>
                handleInputChange(
                  key as keyof T,
                  typeof entry === "boolean" ? e.target.checked : e.target.value
                )
              }
            />
          </div>
        );
      })}
      <button className="object-inputs__button" onClick={handleSubmit}>
        Update
      </button>
    </div>
  );
}

export default ObjectInputs;
