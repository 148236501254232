import React, { useState } from "react";
import { SeasonalSearcherForm } from "./seasonal-searcher-form";
import "./seasonal-searcher.scss";
import { useSelector } from "react-redux";
import SeasonalTableOld from "./seasonal-table-old";
import { SpinnerIcon } from "../icons/spinner-icon";
import { columnSettingsToRealColumns } from "../../functions/table-column-functions";
import { RootState } from "../..";

export function SeasonalSearcher() {
  const [formExtended, setFormExtended] = useState(true);
  const tradesState = useSelector((state: RootState) => {
    return state.tradesState;
  });

  const columns = useSelector((state: RootState) => {
    if (state.loginState.user?.tradeSearcherColumns) {
      return columnSettingsToRealColumns(
        state.loginState.user.tradeSearcherColumns
      );
    } else {
      return [];
    }
  });

  function toggleFormExtended() {
    setFormExtended(!formExtended);
  }

  return (
    <div className={"seasonal-searcher"}>
      {formExtended ? (
        <SeasonalSearcherForm toggleFormExtended={toggleFormExtended} />
      ) : (
        <button onClick={() => setFormExtended((old) => !old)}>
          Show Searcher
        </button>
      )}
      {tradesState.status === "loading" && <SpinnerIcon />}
      {tradesState.trades.length > 0 && columns && (
        <SeasonalTableOld columns={columns} data={tradesState.trades} />
      )}
      {tradesState.error !== null && <div>{tradesState.error.toString()}</div>}
    </div>
  );
}
