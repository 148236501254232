import { DataSource } from "@backend/entities/data-source";
import { DataSourceMetadata } from "@backend/entities/data-source-metadata";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type DataSourcesState = {
  dataSources: DataSource[];
  dataSourcesMetadata: DataSourceMetadata[];
};
const initialState = {
  dataSources: [],
  dataSourcesMetadata: [],
} as DataSourcesState;

export const dataSourcesStateSlice = createSlice({
  name: "dataSourcesState",
  initialState,
  reducers: {
    setDataSources(state, action: PayloadAction<DataSource[]>) {
      state.dataSources = action.payload;
    },
    setDataSourcesMetadata(state, action: PayloadAction<DataSourceMetadata[]>) {
      state.dataSourcesMetadata = action.payload;
    },
  },
});

export const dataSourcesState = dataSourcesStateSlice.reducer;
export const { setDataSources, setDataSourcesMetadata } =
  dataSourcesStateSlice.actions;
