import { DataSource } from "@backend/entities/data-source";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../..";
import { deleteDataSource } from "../../requests/data-source-requests";
import { DoubleClickButton } from "../double-click-button/double-click-button";

export default function DataSourceRow({
  dataSource,
}: {
  dataSource: DataSource;
}) {
  const dispatch = useDispatch();
  const dataSourceMetadata = useSelector((state: RootState) =>
    state.dataSourcesState.dataSourcesMetadata.find(
      (dataSourceMetadata) => dataSourceMetadata.id === dataSource.id
    )
  );
  const marketCount = useSelector(
    (state: RootState) => state.marketsState.markets.length
  );
  return (
    <tr>
      <td>
        <Link to={`/data-sources/${dataSource.id}`}>{dataSource.id}</Link>
      </td>
      <td>{dataSource.name}</td>
      <td>{dataSource.link}</td>
      <td>
        {dataSourceMetadata && dataSourceMetadata.marketsCovered}/{marketCount}
      </td>
      <td>
        <DoubleClickButton
          f={() => {
            deleteDataSource(dataSource.id);
          }}
          label={"Delete"}
          secondLabel={"You sure?"}
        />
      </td>
    </tr>
  );
}
