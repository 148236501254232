import React from "react";
import { TabWindow } from "../tab-window/tab-window";
import Users from "./users/users";

const tabs = [
  {
    span: "Users",
    component: <Users />,
  },
];

export default function AdminPanel() {
  return (
    <div>
      <TabWindow tabs={tabs} />
    </div>
  );
}
