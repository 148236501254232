import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTargetYear } from "../../reducers/setting-reducer";

export default function TargetYearInput() {
  const dispatch = useDispatch();
  const targetYear = useSelector(
    (state: any) => state.settingsState.targetYear
  );
  return (
    <div>
      <label>Target Year:</label>
      <input
        type={"number"}
        value={targetYear}
        onChange={(event) =>
          dispatch(setTargetYear(parseInt(event.target.value)))
        }
      />
    </div>
  );
}
