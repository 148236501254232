import {
  calculateCorrelationsEndpoint,
  getCorrelationsEndpoint,
  getCustomCorrelationsEndpoint,
} from "./endpoints";
import axios, { AxiosResponse } from "axios";
import { Correlation } from "@backend/entities/correlation";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";

export function getCorrelations(): Promise<
  AxiosResponse<ReadResponseBody<Correlation[]>>
> {
  return axios.get(getCorrelationsEndpoint);
}

export function calculateCorrelations(): Promise<
  AxiosResponse<CreateResponseBody<boolean>>
> {
  return axios.post(calculateCorrelationsEndpoint);
}

export function getCustomCorrelations() {
  return axios.get(getCustomCorrelationsEndpoint);
}
