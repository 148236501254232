import { Swarm } from "@backend/entities/swarm";
import { SwarmUpdateBody } from "@backend/request-bodies/swarm-update-body";
import { SerializableSwarmInstance } from "@backend/types/serializable-swarm-instance";
import React, { useEffect, useState } from "react";
import {
  getSwarm,
  getSwarmInstance,
  getSwarmLogs,
  getSwarmWithLogs,
  postAgentCommand,
  putSwarm,
  restartSwarm,
} from "../../requests/swarm-requests";
import ObjectInputs from "../inputs/object-inputs";
import { SortableTable } from "../table/sortable-table";
import { Agent } from "@backend/types/agent";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import ReactJson from "react-json-view";
import { SwarmLog } from "@backend/entities/swarm-log";
import { flattenObject } from "../../functions/flatten-object";
import { SeasonalDisplayComponent } from "../seasonal-displays/seasonal-display-component";
import useWebsocket from "../../hooks/use-websocket";
import { ExpertState } from "@backend/types/expert-state";
import {
  AgentCommand,
  ResumeTradeCommand,
} from "@backend/objects/agent-command";
import { BlackSwanDisplay } from "../black-swan-display/black-swan-display";

let interval: any;
export default function SwarmDetail() {
  const { swarmId } = useParams();
  const [swarmInstance, setSwarmInstance] =
    useState<SerializableSwarmInstance | null>(null);
  const [logs, setLogs] = useState<SwarmLog[]>([]);
  const [limit, setLimit] = useState<number>(100);
  const [showSettings, setShowSettings] = useState(false);
  const [swarm, setSwarm] = useState<Swarm | null>(null);
  const [messages, setMessages] = useWebsocket();
  const [command, setCommand] = useState<string>("");
  const [agentName, setAgentName] = useState<string>("");
  const [commandHistory, setCommandHistory] = useState<string[]>([]);

  // const swarm = useSelector((state: RootState) => {
  //   return state.swarmsState.swarms.find(
  //     (swarm) => swarmId && swarm.id === parseInt(swarmId)
  //   );
  // });
  useEffect(() => {
    if (swarmId) {
      getSwarm(parseInt(swarmId))
        .then((response) => setSwarm(response.data.data))
        .catch((error) => console.log(error));
    }
  }, [swarmId]);
  useEffect(() => {
    if (swarmId)
      getSwarmLogs(parseInt(swarmId), limit)
        .then((response) => setLogs(response.data.data))
        .catch((error) => console.log(error));
  }, [limit, swarmId]);
  useEffect(() => {
    if (swarm) {
      getSwarmInstance(swarm.id)
        .then((response) => setSwarmInstance(response.data.data))
        .catch((error) => console.log(error));
    }
  }, [swarm]);

  useEffect(() => {
    if (messages.includes(`swarm:${swarmId}`)) {
      if (swarm)
        getSwarmInstance(swarm.id)
          .then((response) => setSwarmInstance(response.data.data))
          .catch((error) => console.log(error));

      if (swarmId) {
        getSwarm(parseInt(swarmId))
          .then((response) => setSwarm(response.data.data))
          .catch((error) => console.log(error));
        getSwarmLogs(parseInt(swarmId), limit)
          .then((response) => setLogs(response.data.data))
          .catch((error) => console.log(error));
      }
      setMessages([]);
    }
  }, [messages]);

  function onUpdate(swarm: Swarm) {
    putSwarm(swarm.id, swarm as SwarmUpdateBody)
      .then((response) => {})
      .catch((error) => console.error(error));
  }
  if (!swarm) return <div></div>;

  return (
    <div>
      <h2>Swarm: {swarm.name}</h2>
      <h3>Swarm settings</h3>
      <button onClick={() => setShowSettings((old) => !old)}>
        {showSettings ? "hide settings" : "show settings"}
      </button>
      <button
        onClick={() => {
          restartSwarm(swarm.id)
            .then(() => console.log("swamr restarted"))
            .catch((error) => console.error(error));
        }}
      >
        restart swarm
      </button>
      {showSettings && (
        <ObjectInputs<Swarm>
          object={swarm}
          blacklist={["users", "id", "swarmLogs"]}
          onUpdate={onUpdate}
        />
      )}
      {swarmInstance && (
        <div>
          {swarmInstance.tradeIdsStartingToday.length > 0 && (
            <>
              <h3>trade ids starting/started today</h3>
              <SortableTable
                data={swarmInstance.tradeIdsStartingToday.map((tradeId) => {
                  return { tradeId };
                })}
              />
            </>
          )}
          {swarmInstance.tradeIdsFailedDueToTrigger.length > 0 && (
            <>
              <h3>trade ids failed due to trigger</h3>
              <SortableTable
                data={swarmInstance.tradeIdsFailedDueToTrigger.map(
                  (tradeId) => {
                    return { tradeId };
                  }
                )}
              />
            </>
          )}
          {swarmInstance.tradeIdsFailedDueToRisk.length > 0 && (
            <>
              <h3>trade ids failed due to risk</h3>
              <SortableTable
                data={swarmInstance.tradeIdsFailedDueToRisk.map((tradeId) => {
                  return { tradeId };
                })}
              />
            </>
          )}
          <SeasonalDisplayComponent
            name={"active trades"}
            data={swarmInstance.activeTrades}
          />
          <SeasonalDisplayComponent
            name={"trades for today"}
            data={swarmInstance.tradesForToday}
          />
          <SeasonalDisplayComponent
            name={"future trades"}
            data={swarmInstance.futureTrades}
            hiddenByDefault={true}
          />
          {swarmInstance.activeTrades.length > 0 && (
            <BlackSwanDisplay
              trades={swarmInstance.activeTrades}
              riskPercentage={5}
            />
          )}

          <h3>Agents</h3>

          {swarmInstance.agents.length > 0 && (
            <div>
              <SortableTable
                data={swarmInstance.agents.map((agent) => flattenObject(agent))}
              />
              <ReactJson src={swarmInstance.agents} collapsed={true} />
            </div>
          )}
          <h3>Commands</h3>
          <label>AgentName:</label>
          <input
            value={agentName}
            onChange={(event) => setAgentName(event.target.value)}
          />
          <label>Command:</label>
          <input
            value={command}
            onChange={(event) => setCommand(event.target.value)}
          />
          <button
            onClick={() => {
              if (swarm) {
                postAgentCommand({ swarmId: swarm.id, agentName, command })
                  .then((response) => {
                    setCommandHistory((old) => old.concat(command));
                    setCommand("");
                    console.log(response);
                  })
                  .catch((error) => console.error(error));
              }
            }}
          >
            send command
          </button>
          <h4>History</h4>
          <div>
            <div>
              {commandHistory.map((command) => {
                return <div>{command}</div>;
              })}
            </div>
          </div>
        </div>
      )}
      <h3>Logs</h3>
      <label>Limit</label>
      <input
        value={limit}
        onChange={(event) => setLimit(parseInt(event.target.value))}
      />
      {logs && logs.length > 0 && (
        <>
          <SortableTable data={logs} />
          <SortableTable
            data={swarmLogsToCommands(logs).map((string) => {
              return { command: string };
            })}
          />
        </>
      )}
    </div>
  );
}

function swarmLogsToCommands(swarmLogs: SwarmLog[]) {
  let possibleCommands: string[] = [];
  for (let log of swarmLogs) {
    let jsonPart = log.message.split("expertState: ")[1];
    if (!jsonPart) continue;
    let expertState = JSON.parse(jsonPart) as ExpertState;

    if (
      expertState.tradeId &&
      expertState.stage &&
      expertState.tickets &&
      expertState.orderOpenPrice
    ) {
      let resumeTradeCommand = new ResumeTradeCommand(
        expertState.tradeId,
        expertState.stage,
        expertState.tickets,
        expertState.orderOpenPrice
      );
      possibleCommands.push(resumeTradeCommand.toString());
    }
  }
  return possibleCommands;
}
