import { DataSource } from "@backend/entities/data-source";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../..";

export default function DataSourceMarketCoverage({
  dataSource,
}: {
  dataSource: DataSource;
}) {
  const quoteCollections = useSelector((state: RootState) =>
    state.quoteCollectionsState.quoteCollections.filter((quoteCollection) => {
      return quoteCollection.dataSourceId === dataSource.id;
    })
  );
  const markets = useSelector((state: RootState) => state.marketsState.markets);

  return (
    <div>
      <h2>Market Coverage:</h2>

      {renderCoverageTable()}
    </div>
  );

  function renderCoverageTable() {
    return (
      <table>
        <thead>
          <tr>
            <th>symbol</th>
            <th>collections</th>
          </tr>
        </thead>

        <tbody>
          {[...markets]
            .sort((a, b) => {
              let aCount = [...quoteCollections].filter(
                (quoteCollection) => quoteCollection.marketId === a.id
              ).length;
              let bCount = [...quoteCollections].filter(
                (quoteCollection) => quoteCollection.marketId === b.id
              ).length;
              return aCount - bCount;
            })
            .map((market) => {
              return (
                <tr>
                  <td>{market.symbol}</td>
                  <td>
                    {[...quoteCollections]
                      .filter(
                        (quoteCollection) =>
                          quoteCollection.marketId === market.id
                      )
                      .map((quoteCollection) => `${quoteCollection.period}`)
                      .join(",")}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
}
