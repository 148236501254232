import { User } from "@backend/entities/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putUserMeAction } from "../../../../actions/users-actions";
import { RootState } from "../../../../index";
import CheckboxInput from "./checkbox-input";

export default function HomePageSettings() {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.loginState.user);

  const [defaultNumberOfTrades, setDefaultNumberOfTrades] = useState(
    user?.homePageDefaultNumberOfYears
  );

  const [
    renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades,
    setrenderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades,
  ] = useState(
    user?.renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades
  );

  const [
    renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades,
    setrenderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades,
  ] = useState(
    user?.renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades
  );

  const [
    renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio,
    setrenderHomePageSeventyPercentTradeCollectionOrderedByPlRatio,
  ] = useState(
    user?.renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio
  );

  const [
    renderHomePageEightyPercentTradeCollectionOrderedByPlRatio,
    setrenderHomePageEightyPercentTradeCollectionOrderedByPlRatio,
  ] = useState(
    user?.renderHomePageEightyPercentTradeCollectionOrderedByPlRatio
  );

  const [
    renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage,
    setrenderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage,
  ] = useState(
    user?.renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage
  );

  const [
    renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage,
    setrenderHomePageEightyPercentTradeCollectionOrderedByWinPercentage,
  ] = useState(
    user?.renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage
  );

  useEffect(() => {
    setDefaultNumberOfTrades(user?.homePageDefaultNumberOfYears);
  }, [user]);

  return (
    <div>
      <h1>Home Page Settings</h1>

      <button onClick={saveChanges}>Save changes</button>

      <div>
        <label>Default number of trades</label>
        <input
          onChange={(event) =>
            setDefaultNumberOfTrades(parseInt(event.target.value))
          }
          value={defaultNumberOfTrades}
        />
      </div>
      <CheckboxInput
        value={
          renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades
        }
        setValue={
          setrenderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades
        }
        label={
          "renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades"
        }
      />
      <CheckboxInput
        value={
          renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades
        }
        setValue={
          setrenderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades
        }
        label={
          "renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades"
        }
      />
      <CheckboxInput
        value={renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio}
        setValue={
          setrenderHomePageSeventyPercentTradeCollectionOrderedByPlRatio
        }
        label={"renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio"}
      />
      <CheckboxInput
        value={renderHomePageEightyPercentTradeCollectionOrderedByPlRatio}
        setValue={setrenderHomePageEightyPercentTradeCollectionOrderedByPlRatio}
        label={"renderHomePageEightyPercentTradeCollectionOrderedByPlRatio"}
      />
      <CheckboxInput
        value={
          renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage
        }
        setValue={
          setrenderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage
        }
        label={
          "renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage"
        }
      />
      <CheckboxInput
        value={renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage}
        setValue={
          setrenderHomePageEightyPercentTradeCollectionOrderedByWinPercentage
        }
        label={
          "renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage"
        }
      />
    </div>
  );

  function saveChanges() {
    if (!user) return;
    let updatedUser = { ...user } as User;
    updatedUser = Object.assign({}, updatedUser, {
      homePageDefaultNumberOfYears: defaultNumberOfTrades,
      renderHomePageSeventyPercentTradeCollectionOrderedByNumberOfTrades,
      renderHomePageEightyPercentTradeCollectionOrderedByNumberOfTrades,
      renderHomePageSeventyPercentTradeCollectionOrderedByPlRatio,
      renderHomePageEightyPercentTradeCollectionOrderedByPlRatio,
      renderHomePageSeventyPercentTradeCollectionOrderedByWinPercentage,
      renderHomePageEightyPercentTradeCollectionOrderedByWinPercentage,
    });

    dispatch(putUserMeAction(updatedUser));
  }
}
