import axios, { AxiosResponse } from "axios";
import {
  getDistinctTradesEndpoint,
  getMrSheetTokenEndpoint,
  getSheetTradesEndpoint,
  getSymbolsEndpoint,
  getTradeEndpoint,
  getTradesEndpoint,
  getTradeWithCalculationsEndpoint,
  getTradeWithRelationsEndpoint,
  getUsdExchangeRatesEndpoint,
} from "./endpoints";
import { TradeCriteria } from "@backend/objects/trade-criteria";
import { DistinctTradeCriteria } from "@backend/objects/distinct-trade-criteria";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { Trade } from "@backend/entities/trade";
import { SheetTrade } from "@backend/entities/sheet-trade";

export function getMrSheetToken(): Promise<
  AxiosResponse<ReadResponseBody<string>>
> {
  return axios.get(getMrSheetTokenEndpoint);
}

export function getSymbols() {
  return axios.get(getSymbolsEndpoint);
}

export function getTrades(
  tradeCriteria: TradeCriteria
): Promise<AxiosResponse<ReadResponseBody<Trade[]>>> {
  return axios.post(getTradesEndpoint, tradeCriteria);
}

export function getTrade(
  id: string
): Promise<AxiosResponse<ReadResponseBody<Trade>>> {
  return axios.get(getTradeEndpoint(id));
}

export function getTradeWithCalculations(
  id: string
): Promise<AxiosResponse<ReadResponseBody<Trade>>> {
  return axios.get(getTradeWithCalculationsEndpoint(id));
}

export function getTradeWithRelations(
  id: string
): Promise<AxiosResponse<ReadResponseBody<Trade>>> {
  return axios.get(getTradeWithRelationsEndpoint(id));
}

export function getDistinctTrades(
  distinctTradeCriteria: DistinctTradeCriteria
): Promise<AxiosResponse<ReadResponseBody<Trade[]>>> {
  return axios.post(getDistinctTradesEndpoint, distinctTradeCriteria);
}
export function getSheetTrades(): Promise<
  AxiosResponse<ReadResponseBody<SheetTrade[]>>
> {
  return axios.get(getSheetTradesEndpoint);
}

export function getUsdExchangeRates(): Promise<
  AxiosResponse<ReadResponseBody<any>>
> {
  return axios.get(getUsdExchangeRatesEndpoint);
}
