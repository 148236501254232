import React, { useState } from "react";
import "./home.scss";
import SeasonalSwitch from "./seasonal-switch";
import SideBar from "./bars/side-bar";

import TopBar from "./bars/top-bar";
import useAssets from "../hooks/use-assets";
import CookieConsentBar from "./cookie-consent/cookie-consent-bar";

export default function Home() {
  const assetsFetched = useAssets();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={"home"}>
      <TopBar setMenuOpen={setMenuOpen} />

      <SideBar menuOpen={menuOpen} />

      <div className={"switch-wrapper " + (menuOpen ? "" : "menu-closed")}>
        {assetsFetched ? <SeasonalSwitch /> : <div>Fetching assets</div>}
      </div>

      {/* <CookieConsentBar /> */}
    </div>
  );
}
