import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import axios, { AxiosResponse } from "axios";
import { postDividendEndpoint } from "./endpoints";
import { DividendCreationBody } from "@backend/request-bodies/dividend-creation";
import { Dividend } from "@backend/entities/dividend";

export function postDividend(
  dividend: DividendCreationBody[]
): Promise<AxiosResponse<CreateResponseBody<Dividend[]>>> {
  return axios.post(postDividendEndpoint, dividend);
}
