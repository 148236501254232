import React, { useEffect, useState } from "react";
import "./account-stats.scss";
import AccountStat from "./account-stat";
import { getDistinctAccountNumbers } from "../../requests/account-data-point-requests";
import AccountPerformanceDisplay from "./account-performance-display";

export default function AccountStats() {
  const [distinctAccountNumber, setDistinctAccountNumbers] = useState<number[]>(
    []
  );

  useEffect(() => {
    getDistinctAccountNumbers()
      .then((response) => setDistinctAccountNumbers(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className={"account-stats"}>
      {/*<SheetStat sheetId={2011048894} />*/}
      <AccountPerformanceDisplay accountNumber={488972} />
      <AccountPerformanceDisplay accountNumber={798366} />
    </div>
  );
}
// {distinctAccountNumber.map((accountNumber) => {
//   return <AccountStat accountNumber={accountNumber} />;
// })}
