import {
  CandlestickData,
  createChart,
  LineData,
  OhlcData,
} from "lightweight-charts";
import React, { useEffect, useRef } from "react";

export type Serie = {
  legend: boolean;
  data: CandlestickData;
};

export default function Chart({
  candlestickSeries,
  lineSeries,
}: {
  candlestickSeries: CandlestickData[][];
  lineSeries: LineData[][];
}) {
  const divRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (divRef.current) {
      const chart = createChart(divRef.current, {
        width: divRef.current.clientWidth,
        height: divRef.current.clientHeight,
      });

      for (let lineSerie of lineSeries) {
        let serie = chart.addLineSeries();
        serie.setData(lineSerie);
      }
      for (let candlestickSerie of candlestickSeries) {
        let serie = chart.addCandlestickSeries();
        serie.setData(candlestickSerie);
      }

      return () => chart.remove();
    }
  }, [divRef, lineSeries, candlestickSeries]);
  return <div style={{ width: "100%", height: "100%" }} ref={divRef}></div>;
}
