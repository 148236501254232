import React, { useEffect, useState } from "react";
import { getAccountPerformance } from "../../requests/account-data-point-requests";
import { AccountPerformance } from "@backend/types/account-performance";
import { Link } from "react-router-dom";

export default function AccountPerformanceDisplay({
  accountNumber,
}: {
  accountNumber: number;
}) {
  const [accountPerformance, setAccountPerformance] =
    useState<AccountPerformance | null>(null);
  useEffect(() => {
    getAccountPerformance(accountNumber)
      .then((response) => setAccountPerformance(response.data.data))
      .catch((error) => console.error(error));
  }, [accountNumber]);

  return (
    <div>
      <Link to={`/accounts/${accountNumber}`}>
        <div className={"account-number"}>{accountNumber}</div>
      </Link>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        {accountPerformance && (
          <>
            {Object.entries(accountPerformance).map(([key, value]) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: value
                      ? value > 0
                        ? "green"
                        : "red"
                      : "gray",
                    padding: "2px",
                    fontSize: "0.9em",
                  }}
                >
                  <div>{value && value.toFixed(2) + " %"}</div>
                  <div>{key}</div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
