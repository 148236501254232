import { User } from "@backend/entities/user";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Select, { MultiValue, Options } from "react-select";
import { RootState } from "../..";

export type UserOption = { label: string; value: number };
export default function UsersSelect({
  selectedOptions,
  setSelectedOptions,
  filter,
}: {
  selectedOptions: MultiValue<UserOption>;
  setSelectedOptions: (ids: MultiValue<UserOption>) => any;
  filter?: (user: User) => boolean;
}) {
  const marketOptions = useSelector((state: RootState) => {
    let users = state.usersState.users;
    if (filter) users = users.filter(filter);
    let options: any = users.map((user) => {
      return { value: user.id, label: user.username };
    });
    return options;
  });
  return (
    <Select
      isMulti={true}
      value={selectedOptions}
      onChange={(selectedOptions) => {
        setSelectedOptions(selectedOptions);
      }}
      options={marketOptions}
    />
  );
}
