import { Market } from "@backend/entities/market";
import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { diffInDays } from "../../functions/date-functions";
import UseCurrencyConversion from "../../hooks/use-currency-conversion";
import MarketsSelect from "../selects/markets-select";
import SimpleFxSymbolSelect from "../selects/simple-fx-symbol-select";

export default function SimplefxCalculator() {
  const [selectedMarket, setSelectedMarket] =
    useState<ParsedSimpleFxMarket | null>(null);
  const [selectedSymbol, setSelectedSymbol] = useState<string>("");
  const simplefxMarkets = useSelector((state: RootState) => {
    return state.simpleFxState.parsedSimpleFxMarkets;
  });
  const [orderType, setOrderType] = useState<"long" | "short">("long");
  const [profit, setProfit] = useState<number>(0);
  const [orderSize, setOrderSize] = useState<number>(0);
  const [openPrice, setOpenPrice] = useState<number>(0);
  const [closePrice, setClosePrice] = useState<number>(0);
  const [tradeValue, setTradeValue] = useState<number>(0);
  const [openDate, setOpenDate] = useState<Date>(new Date());
  const [closeDate, setCloseDate] = useState<Date>(new Date());
  const [swap, setSwap] = useState<number>(0);

  const [convertCurrencyToDollar, convertDollarToCurrency] =
    UseCurrencyConversion();

  useEffect(() => {
    let matchedMarket = simplefxMarkets.find(
      (simplefxMarket) => simplefxMarket.symbol === selectedSymbol
    );
    if (matchedMarket) setSelectedMarket(matchedMarket);
  }, [selectedSymbol, simplefxMarkets]);
  useEffect(() => {
    setSelectedSymbol(simplefxMarkets[0].symbol);
  }, []);

  useEffect(() => {
    if (selectedMarket) {
      setOrderSize((old) =>
        old === 0 || true ? selectedMarket.stepSize : old
      );
      setOpenPrice((old) => (old === 0 || true ? selectedMarket.bid : old));
      setClosePrice((old) => (old === 0 || true ? selectedMarket.ask : old));
    }
  }, [selectedMarket]);

  useEffect(() => {
    if (selectedMarket) {
      let differenceInDays = diffInDays(openDate, closeDate);

      let tradeValue: number =
        (orderSize / selectedMarket.stepSize) *
        selectedMarket.riskInDollarPerPercentPerStepBid *
        100;
      let swapFeeForDaysInQuoteCurrency =
        orderType === "long"
          ? differenceInDays *
            selectedMarket.swapFeeLongPerDayPerLotAndPriceInQuoteCurrency *
            orderSize
          : differenceInDays *
            selectedMarket.swapFeeShortPerDayPerLotAndPriceInQuoteCurrency *
            orderSize;
      setSwap(
        convertCurrencyToDollar(
          swapFeeForDaysInQuoteCurrency,
          selectedMarket.simpleFxMarket.priceCurrency
        )
      );
      let percentageChange = ((closePrice - openPrice) / openPrice) * 100;
      percentageChange =
        orderType === "long" ? percentageChange : -percentageChange;
      setTradeValue(tradeValue);
      setProfit(
        (orderSize / selectedMarket.stepSize) *
          selectedMarket.riskInDollarPerPercentPerStepBid *
          percentageChange
      );
    }
  }, [
    selectedMarket,
    orderSize,
    orderType,
    openPrice,
    closePrice,
    openDate,
    closeDate,
  ]);
  return (
    <div>
      <h1>Simplefx calculator</h1>
      <SimpleFxSymbolSelect
        value={selectedSymbol}
        setValue={setSelectedSymbol}
      />
      {selectedMarket && (
        <div>
          <h2>{selectedMarket.symbol}</h2>
          <h3>
            {selectedMarket.simpleFxMarket.priceCurrency}{" "}
            {convertCurrencyToDollar(
              1,
              selectedMarket.simpleFxMarket.priceCurrency
            )}
          </h3>
          <div
            onClick={() => setOrderType("long")}
            style={{
              display: "inline-block",
              padding: "12px",
              color: "green",
              border: "1px solid black",
              backgroundColor: orderType === "long" ? "gray" : "transparent",
              cursor: "pointer",
            }}
          >
            Long
          </div>
          <div
            onClick={() => setOrderType("short")}
            style={{
              display: "inline-block",
              padding: "12px",
              color: "red",
              border: "1px solid black",
              backgroundColor: orderType === "short" ? "gray" : "transparent",
              cursor: "pointer",
            }}
          >
            Short
          </div>
          <label style={{ display: "block" }}>Order size (lots)</label>
          <input
            type="number"
            value={orderSize}
            onChange={(event) => setOrderSize(parseFloat(event.target.value))}
          />
          <label style={{ display: "block" }}>Open price</label>
          <input
            type="number"
            value={openPrice}
            onChange={(event) => setOpenPrice(parseFloat(event.target.value))}
          />
          <label style={{ display: "block" }}>Open Date</label>
          <ReactDatePicker
            selected={openDate}
            onChange={(event) => event && setOpenDate(event)}
          />
          <label style={{ display: "block" }}>Close price</label>
          <input
            type="number"
            value={closePrice}
            onChange={(event) => setClosePrice(parseFloat(event.target.value))}
          />
          <label style={{ display: "block" }}>Close Date</label>
          <ReactDatePicker
            selected={closeDate}
            onChange={(event) => event && setCloseDate(event)}
          />
          <div />
          {profit !== 0 && (
            <>
              <div>
                <label>
                  Profit:
                  <span style={{ color: profit > 0 ? "green" : "red" }}>
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "USD",
                    }).format(profit)}
                  </span>{" "}
                </label>
              </div>
              <div>
                <label>
                  TradeValue:
                  <span style={{ color: profit > 0 ? "green" : "red" }}>
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "USD",
                    }).format(tradeValue)}
                  </span>{" "}
                </label>
              </div>
              <div>
                <label>
                  Swap:
                  <span style={{ color: profit > 0 ? "green" : "red" }}>
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "USD",
                    }).format(swap)}
                  </span>{" "}
                </label>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
