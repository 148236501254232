import { User } from "@backend/entities/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { putUserMeAction } from "../../../../actions/users-actions";
import { ColumnSettingsSelectComponent } from "./column-setting-select";
import "./column-settings.scss";

export function ColumnSettings() {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => {
    return state.loginState.user;
  });

  const [homePageColumns, setHomePageColumns] = useState<string[]>([]);

  const [tradeSearcherColumns, setTradeSearcherColumns] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (user) {
      setHomePageColumns([...user.homePageColumns]);
      setTradeSearcherColumns([...user.tradeSearcherColumns]);
    }
  }, [user]);

  return (
    <div className={"column-settings-component"}>
      <button onClick={saveChanges}>Save changes</button>
      <h2>Home page columns</h2>
      {renderHomePageColumnSettings()}
      <h2>Trade searcher columns</h2>
      {renderTradeSearcherColumnSettings()}
    </div>
  );

  function saveChanges() {
    if (!user) return;
    let updatedUser = { ...user } as User;
    updatedUser.homePageColumns = [...homePageColumns];
    updatedUser.tradeSearcherColumns = [...tradeSearcherColumns];

    dispatch(putUserMeAction(updatedUser));
  }

  function renderHomePageColumnSettings() {
    return (
      <table className={"column-setting-table"}>
        <thead>
          <tr>
            <th>Column name</th>
            <th>Precision</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {homePageColumns.map((columnString, index) => {
            return (
              <ColumnSettingsSelectComponent
                index={index}
                key={index}
                columnString={columnString}
                onChange={onChangeFromHomePageColumn}
                deleteFunction={deleteFromHomePageColumns}
                moveItemUp={moveItemUpInHomePageColumns}
                moveItemDown={moveItemDownInHomePageColumns}
              />
            );
          })}
          <tr>
            <td
              onClick={() => {
                newHomePageColumn();
              }}
              className={"add-button"}
            >
              +
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderTradeSearcherColumnSettings() {
    return (
      <table className={"column-setting-table"}>
        <thead>
          <tr>
            <th>Column name</th>
            <th>Precision</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tradeSearcherColumns.map((columnString, index) => {
            return (
              <ColumnSettingsSelectComponent
                index={index}
                key={index}
                columnString={columnString}
                onChange={onChangeFromTradeSearcherColumn}
                deleteFunction={deleteFromTradeSearcherColumns}
                moveItemUp={moveItemUpInTradeSearcherColumns}
                moveItemDown={moveItemDownInTradeSearcherColumns}
              />
            );
          })}
          <tr>
            <td
              onClick={() => {
                newTradeSearcherColumn();
              }}
              className={"add-button"}
            >
              +
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function onChangeFromTradeSearcherColumn(index: number, value: any) {
    let newTradeSearcherColumns = [...tradeSearcherColumns];
    newTradeSearcherColumns[index] = value;
    setTradeSearcherColumns(newTradeSearcherColumns);
  }

  function onChangeFromHomePageColumn(index: number, value: any) {
    let newHomePageColumns = [...homePageColumns];
    newHomePageColumns[index] = value;
    setHomePageColumns(newHomePageColumns);
  }

  function deleteFromTradeSearcherColumns(index: number) {
    let newTradeSearcherColumns = [...tradeSearcherColumns];
    newTradeSearcherColumns.splice(index, 1);
    setTradeSearcherColumns(newTradeSearcherColumns);
  }

  function deleteFromHomePageColumns(index: number) {
    let newHomePageColumns = [...homePageColumns];
    newHomePageColumns.splice(index, 1);
    setHomePageColumns(newHomePageColumns);
  }

  function newHomePageColumn() {
    let newHomePageColumns = [...homePageColumns];
    newHomePageColumns.push("id");
    setHomePageColumns(newHomePageColumns);
  }

  function newTradeSearcherColumn() {
    let newTradeSearcherColumns = [...tradeSearcherColumns];
    newTradeSearcherColumns.push("id");
    setTradeSearcherColumns(newTradeSearcherColumns);
  }

  function moveItemDownInHomePageColumns(index: number) {
    if (index < homePageColumns.length - 1) {
      let newHomePageColumns = [...homePageColumns];
      newHomePageColumns[index + 1] = homePageColumns[index];
      newHomePageColumns[index] = homePageColumns[index + 1];
      setHomePageColumns(newHomePageColumns);
    }
  }
  function moveItemUpInHomePageColumns(index: number) {
    if (index !== 0) {
      let newHomePageColumns = [...homePageColumns];
      newHomePageColumns[index - 1] = homePageColumns[index];
      newHomePageColumns[index] = homePageColumns[index - 1];
      setHomePageColumns(newHomePageColumns);
    }
  }
  function moveItemDownInTradeSearcherColumns(index: number) {
    if (index < tradeSearcherColumns.length - 1) {
      let newTradeSearcherColumns = [...tradeSearcherColumns];
      newTradeSearcherColumns[index + 1] = tradeSearcherColumns[index];
      newTradeSearcherColumns[index] = tradeSearcherColumns[index + 1];
      setTradeSearcherColumns(newTradeSearcherColumns);
    }
  }
  function moveItemUpInTradeSearcherColumns(index: number) {
    if (index !== 0) {
      let newTradeSearcherColumns = [...tradeSearcherColumns];
      newTradeSearcherColumns[index - 1] = tradeSearcherColumns[index];
      newTradeSearcherColumns[index] = tradeSearcherColumns[index - 1];
      setTradeSearcherColumns(newTradeSearcherColumns);
    }
  }
}
