import { Action, combineReducers } from "redux";
import { loginState } from "./login-reducer";
import { tradesState } from "./trades-reducer";
import { tradeState } from "./trade-reducer";
import { homeState } from "./home-reducer";
import { settingsState } from "./setting-reducer";
import { marketsState } from "./markets-reducer";
import { simpleFxState } from "./simple-fx-reducer";
import { marketState } from "./market-reducer";
import { informationalState } from "./informational-reducer";
import { correlationsState } from "./correlations-reducer";
import { sheetState } from "./sheet-reducer";
import { usersState } from "./users-reducer";
import { exchangeRatesState } from "./exchange-rates-reducer";
import { quoteCollectionsState } from "./quote-collections-reducer";
import { dataSourcesState } from "./data-sources-reducer";
import { swarmsState } from "./swarms-reducer";

const appReducer = combineReducers({
  loginState,
  tradesState,
  tradeState,
  homeState,
  settingsState,
  marketsState,
  simpleFxState,
  marketState,
  informationalState,
  correlationsState,
  sheetState,
  usersState,
  exchangeRatesState,
  quoteCollectionsState,
  dataSourcesState,
  swarmsState,
});

export default function rootReducer(state: any, action: Action) {
  if (action.type === "LOGOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
