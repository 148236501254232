import { SheetTrade } from "@backend/entities/sheet-trade";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { getSheetTradesAction } from "../../actions/general-actions";
import { getSheetTrades } from "../../requests/general-requests";
import { deleteSheetTrade } from "../../requests/sheet-trade-requests";
import { DoubleClickButton } from "../double-click-button/double-click-button";

export default function SheetTradeManager() {
  let sheetTrades = useSelector((state: RootState) => {
    return state.sheetState.sheetTrades;
  });

  let [filteredSheetTrades, setFilteredSheetTrades] = useState<SheetTrade[]>(
    []
  );
  const dispatch = useDispatch();

  const [tradeStageFilter, setTradeStageFilter] = useState<string>("");
  const [tradeIdFilter, setTradeIdFilter] = useState<string>("");
  const [sheetIdFilter, setSheetIdFilter] = useState<string>("");

  useEffect(() => {
    setFilteredSheetTrades(
      sheetTrades.filter((sheetTrade) => {
        return (
          (sheetIdFilter
            ? sheetTrade.sheetId.toString().includes(sheetIdFilter)
            : true) &&
          (tradeIdFilter ? sheetTrade.tradeId.includes(tradeIdFilter) : true) &&
          (tradeStageFilter
            ? sheetTrade.tradeStage.toString().includes(tradeStageFilter)
            : true)
        );
      })
    );
  }, [sheetTrades, tradeStageFilter, tradeIdFilter, sheetIdFilter]);

  return (
    <div>
      <h1>Sheet Trade Manager</h1>
      <h2>filters</h2>
      <input
        value={tradeStageFilter}
        onChange={(event) => setTradeStageFilter(event.target.value)}
        placeholder={"trade stage filter"}
      />
      <input
        value={sheetIdFilter}
        onChange={(event) => setSheetIdFilter(event.target.value)}
        placeholder={"sheet id filter"}
      />
      <input
        value={tradeIdFilter}
        onChange={(event) => setTradeIdFilter(event.target.value)}
        placeholder={"trade id filter"}
      />
      <div style={{ border: "1px solid black", fontSize: "1em" }}>
        <div>tradeStage-sheetId-tradeId</div>
        {filteredSheetTrades.map((sheetTrade) => (
          <div>
            {sheetTrade.tradeStage}-{sheetTrade.sheetId}-{sheetTrade.tradeId}{" "}
            <DoubleClickButton
              f={async () => {
                try {
                  await deleteSheetTrade(
                    sheetTrade.sheetId,
                    sheetTrade.tradeId
                  );
                  dispatch(getSheetTradesAction());
                } catch (e) {}
              }}
              label={"delete"}
              secondLabel={"you sure?"}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
