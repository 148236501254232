import { RiskProfile as RiskProfileEntity } from "@backend/entities/risk-profile";
import { PersonalRiskProfileCreationBody } from "@backend/request-bodies/personal-risk-profile-creation-body";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { getMeAction } from "../../../../actions/users-actions";
import { postRiskProfile } from "../../../../requests/users-requests";
import RiskProfile from "./risk-profile";

export default function RiskSettings() {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.loginState.user);
  const [riskProfiles, setRiskProfiles] = useState<RiskProfileEntity[]>([]);

  useEffect(() => {
    if (user)
      setRiskProfiles([...user.riskProfiles].sort((a, b) => b.id - a.id));
  }, [user]);

  return (
    <div>
      <h1>Risk Profiles</h1>
      {riskProfiles.length === 0 && <div>no risk profiles...</div>}
      {riskProfiles.map((riskProfile) => (
        <div key={riskProfile.id}>
          <RiskProfile riskProfile={riskProfile} />
        </div>
      ))}
      <button onClick={addRiskProfile}>ADD</button>
    </div>
  );

  async function addRiskProfile() {
    let riskProfileCreationBody = {
      preferredRiskPercentagePerTrade: 5,
      accountSize: 10000,
      accountCurrency: "USD",
    } as PersonalRiskProfileCreationBody;
    await postRiskProfile(riskProfileCreationBody);
  }
}
