import { Quote } from "@backend/entities/quote";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import axios, { AxiosResponse } from "axios";
import { postQuotesEndpoint } from "./endpoints";

export function postQuotes(
  quotes: Quote[]
): Promise<AxiosResponse<CreateResponseBody<Quote[]>>> {
  return axios.post(postQuotesEndpoint, quotes);
}
