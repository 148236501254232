import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./markets.scss";
import { Link } from "react-router-dom";
import MarketsTable from "./markets-table";
import ButtonWithLoadingAndSuccess from "../buttons/button-with-loading-and-success";
import { RootState } from "../..";
import { calculateCorrelationsAction } from "../../actions/correlation-actions";

export function Markets() {
  const dispatch = useDispatch();

  const correlationRequestState = useSelector(
    (state: RootState) => state.correlationsState.calculateCorrelationsStatus
  );

  return (
    <div className={"markets-component"}>
      <h1>Markets</h1>
      <Link to={"/markets/create"}>Create market</Link>
      {/*<Link to={"/markets/create-bulk"}>Add market bulk</Link>*/}
      {/*<Link to={"/markets/bulk-update"}>Bulk update</Link>*/}
      {/*<button onClick={dispatch(calculateMarkets)}>*/}
      {/*  calculate trades for all markets*/}
      {/*</button>*/}
      <ButtonWithLoadingAndSuccess
        onClick={() => dispatch(calculateCorrelationsAction())}
        text={"calculate-correlations"}
        requestState={correlationRequestState}
      />
      {/*<DownloadAllMarketsButton />*/}
      {/*<MarketsList />*/}
      <MarketsTable />
    </div>
  );
}
