import axios, { AxiosResponse } from "axios";
import {
  getDataSourceEndpoint,
  getDataSourcesEndpoint,
  postDataSourceEndpoint,
  putDataSourceEndpoint,
  postRefreshDataSourceMetadataEndpoint,
  deleteDataSourceEndpoint,
  getDataSourcesMetadataEndpoint,
} from "./endpoints";
import {
  setDataSources,
  setDataSourcesMetadata,
} from "../reducers/data-sources-reducer";
import { DataSourceCreationBody } from "@backend/request-bodies/data-source-creation-body";
import { DataSourceUpdateBody } from "@backend/request-bodies/data-source-update-body";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { DataSource } from "@backend/entities/data-source";
import { DataSourceMetadata } from "@backend/entities/data-source-metadata";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { UpdateResponseBody } from "@backend/response-bodies/update-response-body";
import { DeleteResponseBody } from "@backend/response-bodies/delete-response-body";

export function getDataSources(): Promise<
  AxiosResponse<ReadResponseBody<DataSource[]>>
> {
  return axios.get(getDataSourcesEndpoint);
}

export function getDataSourcesMetadata(): Promise<
  AxiosResponse<ReadResponseBody<DataSourceMetadata[]>>
> {
  return axios.get(getDataSourcesMetadataEndpoint);
}

export function getDataSource(
  id: number
): Promise<AxiosResponse<ReadResponseBody<DataSource>>> {
  return axios.get(getDataSourceEndpoint(id));
}

export function postDataSource(
  dataSourceCreationBody: DataSourceCreationBody
): Promise<AxiosResponse<CreateResponseBody<DataSource>>> {
  return axios.post(postDataSourceEndpoint, dataSourceCreationBody);
}

export function putDataSource(
  dataSourceId: number,
  dataSourceUpdateBody: DataSourceUpdateBody
): Promise<AxiosResponse<UpdateResponseBody<DataSource>>> {
  return axios.put(putDataSourceEndpoint(dataSourceId), dataSourceUpdateBody);
}

export function deleteDataSource(
  dataSourceId: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteDataSourceEndpoint(dataSourceId));
}
