import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../index";
import { postQuoteCollection } from "../../requests/quote-collections-requests";

const periodOptions = [
  { value: "D", label: "D" },
  { value: "M1", label: "M1" },
];

export default function CreateQuoteCollection() {
  const marketOptions = useSelector((state: RootState) =>
    state.marketsState.markets.map((market) => {
      return {
        value: market.id,
        label: market.symbol,
      };
    })
  );

  const dataSourceOptions = useSelector((state: RootState) =>
    state.dataSourcesState.dataSources.map((dataSource) => {
      return {
        value: dataSource.id,
        label: dataSource.name,
      };
    })
  );

  const [statusText, setStatusText] = useState("");

  const [selectedMarketOption, setSelectedMarketOption] = useState<any>(null);
  const [selectedDataSourceOption, setSelectedDataSourceOption] =
    useState<any>(null);
  const [selectedPeriodOptions, setSelectedPeriodOptions] = useState<any>(null);

  return (
    <div>
      <h1>Create Quote Collection</h1>
      <form onSubmit={createQuoteCollection}>
        <label>Market</label>
        <Select
          value={selectedMarketOption}
          options={marketOptions}
          onChange={(selectedOption) => setSelectedMarketOption(selectedOption)}
        />
        <label>Data Source</label>
        <Select
          value={selectedDataSourceOption}
          options={dataSourceOptions}
          onChange={(selectedOption) =>
            setSelectedDataSourceOption(selectedOption)
          }
        />
        <label>Period</label>
        <Select
          value={selectedPeriodOptions}
          options={periodOptions}
          onChange={(selectedOption) =>
            setSelectedPeriodOptions(selectedOption)
          }
        />
        <input
          disabled={
            !selectedDataSourceOption ||
            !selectedMarketOption ||
            !selectedPeriodOptions
          }
          type={"submit"}
          value={"create"}
        />
        <div>{statusText}</div>
      </form>
    </div>
  );

  function createQuoteCollection(event: FormEvent) {
    event.preventDefault();
    let quoteCollectionCreationBody = {
      marketId: selectedMarketOption.value,
      period: selectedPeriodOptions.value,
      dataSourceId: selectedDataSourceOption.value,
    };
    postQuoteCollection(quoteCollectionCreationBody)
      .then((response) => {
        setStatusText("quote collection created");
      })
      .catch((error) => setStatusText(error.message));
  }
}
