import React, { useState } from "react";
import { useSelector } from "react-redux";
import CsvDropzone from "../../../csv-dropzone/csv-dropzone";
import Papa from "papaparse";
import Select from "react-select";
import { RootState } from "../../../../index";
import { Quote } from "@backend/entities/quote";
import { postQuoteCollection } from "../../../../requests/quote-collections-requests";
import { postQuotes } from "../../../../requests/quote-actions";

const periodOptions = [
  { value: "D", label: "D" },
  { value: "M1", label: "M1" },
];

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  encodig: "",
};

export default function InsertQuotesSubWizard2() {
  const [statusText, setStatusText] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedDataSourceOption, setSelectedDataSourceOption] =
    useState<any>(null);

  const { dataSources, dataSourceOptions } = useSelector((state: RootState) => {
    return {
      dataSources: state.dataSourcesState.dataSources,
      dataSourceOptions: state.dataSourcesState.dataSources.map(
        (dataSource) => {
          return { value: dataSource.id, label: dataSource.name };
        }
      ),
    };
  });

  const quoteCollections = useSelector(
    (state: RootState) => state.quoteCollectionsState.quoteCollections
  );

  const markets = useSelector((state: RootState) => state.marketsState.markets);

  const [selectedPeriodOption, setSelectedPeriodOptions] = useState<any>(null);

  return (
    <div>
      <h2>Select Data Source</h2>
      <Select
        value={selectedDataSourceOption}
        onChange={(selectedOption) => {
          setSelectedDataSourceOption(selectedOption);
          setSelectedPeriodOptions(null);
        }}
        options={dataSourceOptions}
      />
      {selectedDataSourceOption && (
        <>
          <h2>
            Select Period And Drop Quotes FILE NAME MATCHING SYMBOL
            [date,open,high,low,close]
          </h2>
          <label>Period</label>
          <Select
            value={selectedPeriodOption}
            options={periodOptions}
            onChange={(selectedOption) =>
              setSelectedPeriodOptions(selectedOption)
            }
          />

          {selectedPeriodOption && (
            <>
              <CsvDropzone onFilesReceived={quoteDrop} />
            </>
          )}
        </>
      )}

      {statusText}
      {errors.map((error) => {
        return <div>{error}</div>;
      })}
    </div>
  );

  async function quoteDrop(files: any) {
    let counter = 0;
    for (let file of files) {
      try {
        let symbol = file.name.split(".csv")[0].toUpperCase();
        setStatusText(`${counter}/${files.length} now processing ${file.name}`);
        let csvContent = Papa.parse(file.data, papaparseOptions);
        let quotes = csvContent.data;

        let matchedMarket = markets.find((market) => market.symbol === symbol);
        if (!matchedMarket) continue;
        let quoteCollection = quoteCollections.find(
          (quoteCollection) =>
            quoteCollection.dataSourceId === selectedDataSourceOption.value &&
            quoteCollection.marketId === matchedMarket?.id &&
            quoteCollection.period === selectedPeriodOption.value
        );
        if (!quoteCollection) {
          let response = await postQuoteCollection({
            marketId: matchedMarket.id,
            period: selectedPeriodOption.value,
            dataSourceId: selectedDataSourceOption.value,
          });
          if (response.data.data) quoteCollection = response.data.data;
        }
        if (!!!quoteCollection) continue;

        quotes = quotes.map((quote: any) => {
          let date = new Date(quote.date);
          if (!!!date.valueOf()) date = new Date(parseInt(quote.date));
          return Object.assign({}, quote, {
            quoteCollectionId: quoteCollection?.id,
            date,
          });
        });
        quotes = quotes as Quote[];
        let realQuotes = quotes.map((quote: any) => {
          return quote as Quote;
        });
        let response = await postQuotes(realQuotes);
      } catch (e: any) {
        setErrors((old) => {
          return old.concat(`${file.name} failed with error ${e.message}`);
        });
      }
      counter++;
    }
    setStatusText(`Done!`);
  }
}
