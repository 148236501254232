import React, { useEffect, useState } from "react";
import {
  dateToNonWeekendDayDate,
  secondsToHour,
  secondsToRestMinutes,
} from "../../functions/date-functions";
import { presetStringToBlob } from "../../functions/preset-function";
import {} from "../../actions/markets-actions";
import { LoadingEllipsisIcon } from "../icons/loading-ellipsis-icon";
import { RiskProfile } from "@backend/entities/risk-profile";
import { Trade } from "@backend/entities/trade";
import { getTrade } from "../../requests/general-requests";
import {
  getAverageAbsolutePercentageChange,
  getAveragePercentageChange,
} from "../../requests/markets-requests";
import useMarket from "../../hooks/use-market";
import useRiskFunctions from "../../hooks/use-risk-functions";

const lenghts = [100, 50, 30, 10, 5];

export default function Preset({
  shouldPromptDownloadOnce,
  tradeId,
  riskProfile,
}: {
  shouldPromptDownloadOnce: boolean;
  tradeId: string;
  riskProfile: RiskProfile;
}) {
  const [trade, setTrade] = useState<Trade | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [stoploss, setStoploss] = useState<number>(0);
  const [risk, setRisk] = useState<number>(0);

  const { market, simpleFxMarket } = useMarket(trade?.marketId);
  const [lotSize, setLotSize] = useState(0);

  const [riskOptions, setRiskOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [stoplossOptions, setStoplossOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [useAvgProfitPercentage, setUseAvgProfitPercentage] =
    useState<boolean>(false);
  const [warnings, setWarnings] = useState<string[]>([]);

  const { calculateCustomLotSizeWithRiskProfile, calculateRiskForRiskProfile } =
    useRiskFunctions();

  //should prompt download
  useEffect(() => {
    if (shouldPromptDownloadOnce) {
      downloadPreset();
    }
  }, [shouldPromptDownloadOnce]);

  useEffect(() => {
    async function doThingsAsync() {
      let trade = (await getTrade(tradeId)).data.data;
      setTrade(trade);
    }
    doThingsAsync()
      .then(() => {})
      .catch((error) => console.log(error));
  }, [tradeId]);

  useEffect(() => {
    async function riskThing() {
      if (!trade || !market) return;
      let hl =
        trade.type === "Long"
          ? Math.abs(trade.lowestPercentageChange)
          : Math.abs(trade.highestPercentageChange);

      let hlc =
        trade.type === "Long"
          ? Math.abs(trade.lowestPercentageChangeClose)
          : Math.abs(trade.highestPercentageChangeClose);

      let warnings = [];
      for (let length of lenghts) {
        let averageAbsolutePercentageChange = (
          await getAverageAbsolutePercentageChange(market.id, length)
        ).data.data;
        let averagePercentageChange = (
          await getAveragePercentageChange(market.id, length)
        ).data.data;
        console.log({
          averagePercentageChange,
          averageAbsolutePercentageChange,
          hl,
          hlc,
        });

        if (hlc <= averageAbsolutePercentageChange)
          warnings.push(
            `hlc(${hlc.toPrecision(
              4
            )}%) smaller than the averageAbsolutePercentageChange(${averageAbsolutePercentageChange.toPrecision(
              4
            )}%) of ${length} days.`
          );
      }
      setWarnings(warnings);

      let riskOptions = [
        {
          value: 20,
          label: "20%",
        },
        {
          value: hl,
          label: `h/l (${hl}%)`,
        },
        {
          value: hlc,
          label: `h/l (${hlc}%)`,
        },
      ];

      let stoplossOptions = [
        {
          value: 20,
          label: "20%",
        },
        {
          value: hl,
          label: `h/l (${hl}%)`,
        },
        {
          value: hlc,
          label: `h/l (${hlc}%)`,
        },
      ];

      setRiskOptions(riskOptions);
      setStoplossOptions(stoplossOptions);

      setStoploss(stoplossOptions[1].value);

      if (hlc > 20) setRisk(riskOptions[0].value);
      else setRisk(riskOptions[2].value);

      if (market.security.includes("Equities")) {
        setUseAvgProfitPercentage(true);
      }
    }
    if (trade && market) {
      riskThing()
        .then(() => {
          setLoading(false);
        })
        .catch();
    }
  }, [trade, market]);

  useEffect(() => {
    if (risk && simpleFxMarket && trade) {
      let lotSize = calculateCustomLotSizeWithRiskProfile(
        simpleFxMarket,
        risk,
        trade,
        riskProfile
      );
      console.log({ market, risk, lotSize });
      setLotSize(lotSize);
    }
  }, [risk]);
  if (loading)
    return (
      <tr>
        <LoadingEllipsisIcon />
      </tr>
    );
  if (trade && market)
    return (
      <tr
        className={`${lotSize === 0 ? "red" : ""}  ${
          warnings.length > 0 ? "orange" : ""
        }`}
      >
        <td>{tradeId}</td>
        <td>{trade.type}</td>
        <td>{market.symbol}</td>
        <td>
          {renderRiskSelect()} -{" "}
          {simpleFxMarket &&
            trade &&
            risk &&
            calculateRiskForRiskProfile(
              lotSize,
              simpleFxMarket,
              risk,
              trade,
              riskProfile
            )}
          {riskProfile.accountCurrency}
        </td>
        <td>
          {renderStoplossSelect()} -{" "}
          {simpleFxMarket &&
            trade &&
            risk &&
            calculateRiskForRiskProfile(
              lotSize,
              simpleFxMarket,
              stoploss,
              trade,
              riskProfile
            )}
          {riskProfile.accountCurrency}
        </td>
        <td>{lotSize}</td>
        <td>
          <input
            type={"checkbox"}
            checked={useAvgProfitPercentage}
            onChange={(event) =>
              setUseAvgProfitPercentage(event.target.checked)
            }
          />
        </td>
        <td>{renderWarnings()}</td>
        <td>
          <button onClick={downloadPreset}>Download Preset</button>
        </td>
      </tr>
    );
  return <tr></tr>;
  function renderWarnings() {
    return warnings.map((warning) => {
      return <div>{warning}</div>;
    });
  }

  function renderRiskSelect() {
    return (
      <select
        value={risk}
        onChange={(event) => setRisk(parseFloat(event.target.value))}
      >
        {riskOptions.map((option) => {
          return <option value={option.value}>{option.label}</option>;
        })}
      </select>
    );
  }

  function renderStoplossSelect() {
    return (
      <select
        value={stoploss}
        onChange={(event) => setStoploss(parseFloat(event.target.value))}
      >
        {stoplossOptions.map((option) => {
          return <option value={option.value}>{option.label}</option>;
        })}
      </select>
    );
  }

  function downloadPreset() {
    if (!trade || !simpleFxMarket) return;
    let currentDate = new Date();

    let openDate = new Date(
      currentDate.getFullYear(),
      trade.openMonth - 1,
      trade.openDay
    );

    let closeDate = new Date(
      currentDate.getFullYear(),
      trade.closeMonth - 1,
      trade.closeDay
    );

    let correctOpenDate = dateToNonWeekendDayDate(openDate);

    let correctOpenDay = correctOpenDate.getDate();
    let correctOpenMonth = correctOpenDate.getMonth() + 1;

    let correctCloseDate = dateToNonWeekendDayDate(closeDate);

    let correctCloseDay = correctCloseDate.getDate();
    let correctCloseMonth = correctCloseDate.getMonth() + 1;

    let firstTakeProfitLotsize = (lotSize / 3).toFixed(2);
    let secondTakeProfitLotsize = (lotSize / 3).toFixed(2);
    // console.log(firstTakeProfitLotsize % market.simpleFxMarket.stepSize)
    // firstTakeProfitLotsize -= firstTakeProfitLotsize % market.simpleFxMarket.stepSize;
    // secondTakeProfitLotsize -= secondTakeProfitLotsize % market.simpleFxMarket.stepSize;

    let normalCloseHour = 0;
    let normalCloseMinute = 0;
    let fridayCloseHour = 0;
    let fridayCloseMinute = 0;

    if (simpleFxMarket.tradingHours[1]) {
      normalCloseMinute = secondsToRestMinutes(
        simpleFxMarket.tradingHours[1][0].to - 60
      );
      normalCloseHour = secondsToHour(
        simpleFxMarket.tradingHours[1][0].to - 60
      );
    }
    if (simpleFxMarket.tradingHours[5]) {
      fridayCloseMinute = secondsToRestMinutes(
        simpleFxMarket.tradingHours[5][0].to - 60
      );
      fridayCloseHour = secondsToHour(
        simpleFxMarket.tradingHours[5][0].to - 60
      );
    }


    let trailingStop = 0;

    let tradeType = trade.type === "Long" ? 0 : 1;

    let stoplossPercentage = stoploss;

    let avgProfit = trade.avgProfit;
    if (useAvgProfitPercentage) {
      avgProfit = trade.avgProfitPercentage;
    }

    let presetString = `useAvgProfitPercentage=${useAvgProfitPercentage ? 1 : 0}
avgProfit=${avgProfit}
stoplossPercentage=${stoplossPercentage}
inputTradeType=${tradeType}
entryDay=${correctOpenDay}
entryMonth=${correctOpenMonth}
exitDay=${correctCloseDay}
exitMonth=${correctCloseMonth}
lotSize=${lotSize}
firstTakeProfitLotsize=${firstTakeProfitLotsize}
secondTakeProfitLotsize=${secondTakeProfitLotsize}
normalCloseHour=${normalCloseHour}
normalCloseMinute=${normalCloseMinute}
fridayCloseHour=${fridayCloseHour}
fridayCloseMinute=${fridayCloseMinute}
trailingStop=0
                `;

    presetStringToBlob(presetString, trade.id);
  }
}
