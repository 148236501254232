import React from "react";
import { useSelector } from "react-redux";
import DataSourceRow from "./data-source-row";
import { Link } from "react-router-dom";
import { RootState } from "../../index";

export default function DataSources() {
  const dataSources = useSelector(
    (state: RootState) => state.dataSourcesState.dataSources
  );

  return (
    <div>
      <h1>Data Sources</h1>
      <Link to={"/data-sources/create"} style={{ display: "block" }}>
        Create Data Source
      </Link>
      <div>{renderDataSourcesTable()}</div>
    </div>
  );

  function renderDataSourcesTable() {
    return (
      <table className={"data-sources-table"}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Link</th>
            <th>Market coverage</th>
          </tr>
        </thead>
        <tbody>{renderDataSourceRows()}</tbody>
      </table>
    );
  }

  function renderDataSourceRows() {
    return dataSources.map((dataSource) => {
      return <DataSourceRow dataSource={dataSource} />;
    });
  }
}
