import React from "react";
import { Link } from "react-router-dom";

export default function Health() {
  return (
    <div>
      <h1>Health!</h1>
      <Link to={"/health/quote-collections-health"}>
        Quote Collections Health
      </Link>
    </div>
  );
}
