import { Trade } from "@backend/entities/trade";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TradesState = {
  trades: Trade[];
  status: string;
  error: Error | null;
};

const initialState = {
  trades: [],
  status: "idle",
  error: null,
} as TradesState;

export const tradesStateSlice = createSlice({
  name: "tradesState",
  initialState,
  reducers: {
    fetchingTrades(state) {
      state.trades = [];
      state.error = null;
      state.status = "loading";
    },
    setTrades(state, action: PayloadAction<Trade[]>) {
      state.trades = action.payload;
      state.status = "succeeded";
    },
    fetchingTradesFailed(state, action) {
      state.trades = [];
      state.error = action.payload.response.data.message;
      state.status = "failed";
    },
  },
});

export const tradesState = tradesStateSlice.reducer;
export const { fetchingTrades, setTrades, fetchingTradesFailed } =
  tradesStateSlice.actions;
