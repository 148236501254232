import { Role } from "../../enums/role";

export default function RoleSelect({
  value,
  onChange,
}: {
  value: Role;
  onChange: (role: Role) => any;
}) {
  return (
    <select
      value={value}
      onChange={(event) => onChange(event.target.value as Role)}
    >
      {renderOptions()}
    </select>
  );

  function renderOptions() {
    return Object.values(Role).map((role) => {
      return <option value={role}>{role}</option>;
    });
  }
}
