import { RiskProfile } from "@backend/entities/risk-profile";
import { Trade } from "@backend/entities/trade";
import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";
import UseCurrencyConversion from "./use-currency-conversion";

export default function useRiskFunctions() {
  const [convertCurrencyToDollar, convertDollarToCurrency] =
    UseCurrencyConversion();

  function calculateCustomLotSizeWithRiskProfile(
    simpleFxMarket: ParsedSimpleFxMarket,
    customStopLoss: number,
    trade: Trade,
    riskProfile: RiskProfile
  ) {
    let riskAmountInDollars =
      convertCurrencyToDollar(
        riskProfile.accountSize,
        riskProfile.accountCurrency
      ) *
      (riskProfile.preferredRiskPercentagePerTrade / 100);

    let counter = 1;
    let currentRisk = 0;
    let lotSize = 0;
    for (
      lotSize = simpleFxMarket.stepSize;
      currentRisk < riskAmountInDollars;
      lotSize += simpleFxMarket.stepSize
    ) {
      currentRisk =
        (trade.type === "Long"
          ? simpleFxMarket.riskInDollarPerPercentPerStepAsk
          : simpleFxMarket.riskInDollarPerPercentPerStepBid) *
        customStopLoss *
        (lotSize / simpleFxMarket.stepSize);
      counter++;
    }
    lotSize -= simpleFxMarket.stepSize;
    counter--;
    // if(lotSize ===0) return 0;
    console.log({ counter, lotSize, rest: counter % 3 });
    while (counter > 0 && counter % 3 !== 0) {
      console.log({ counter, lotSize });
      counter--;
      lotSize -= simpleFxMarket.stepSize;
    }

    if (counter === 0) return 0;

    return parseFloat(lotSize.toFixed(2));
  }

  function calculateRiskForRiskProfile(
    lotSize: number,
    simpleFxMarket: ParsedSimpleFxMarket,
    percentage: number,
    trade: Trade,
    riskProfile: RiskProfile
  ) {
    return parseFloat(
      convertDollarToCurrency(
        (trade.type === "Long"
          ? simpleFxMarket.riskInDollarPerPercentPerStepAsk
          : simpleFxMarket.riskInDollarPerPercentPerStepBid) *
          percentage *
          (lotSize / simpleFxMarket.stepSize),
        riskProfile.accountCurrency
      ).toPrecision(5)
    );
  }

  return { calculateCustomLotSizeWithRiskProfile, calculateRiskForRiskProfile };
}
