import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpinnerIcon } from "../icons/spinner-icon.js";
import ReactJson from "react-json-view";
import { getMarket } from "../../requests/markets-requests";
import { Market } from "@backend/entities/market";

export default function MarketDetailView() {
  const { marketId } = useParams();

  const [market, setMarket] = useState<Market | null>(null);

  useEffect(() => {
    if (marketId)
      getMarket(parseInt(marketId))
        .then((response) => setMarket(response.data.data))
        .catch((error) => console.error(error));
  }, [marketId]);

  if (!market)
    return (
      <div>
        <SpinnerIcon />
      </div>
    );
  return (
    <div>
      <h1>Market:</h1>
      {renderJsonMarketView()}
    </div>
  );

  function renderJsonMarketView() {
    return !!market ? (
      <ReactJson src={market} collapsed={1} displayDataTypes={false} />
    ) : (
      <></>
    );
  }
}
