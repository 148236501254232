import * as FileSaver from "file-saver";
import * as csv from "csv-stringify/browser/esm/sync";
import { Trade } from "@backend/entities/trade";

export function tradesToCsvFile(trades: Trade[]) {
  let tradesCopy: Trade[] = [...trades];
  let sortedTrades = tradesCopy.sort((tradeA, tradeB) => {
    if (tradeA.openMonth === tradeB.openMonth) {
      if (tradeA.openDay === tradeB.openDay) {
        return 0;
      } else if (tradeA.openDay < tradeB.openDay) {
        return -1;
      } else if (tradeA.openDay > tradeB.openDay) {
        return 1;
      }
    } else if (tradeA.openMonth < tradeB.openMonth) {
      return -1;
    } else if (tradeA.openMonth > tradeB.openMonth) {
      return 1;
    }
    return 0;
  });

  let csvArrays = sortedTrades.map((trade) => {
    let tradeType = "";
    if (trade.type === "Long") {
      tradeType = "L";
    } else {
      tradeType = "S";
    }
    let lossLastYear = "";

    return [
      trade.marketId,
      tradeType,
      `${trade.openDay}/${trade.openMonth}`,
      `${trade.closeDay}/${trade.closeMonth}`,
      trade.plRatio.toPrecision(4),
      trade.winPercentage.toPrecision(4),
      trade.avgProfit.toPrecision(5),
      trade.biggestWinStreak,
      trade.biggestLossStreak,
      trade.numberOfTrades,
      lossLastYear,
    ];
  });
  let csvString = csv.stringify(csvArrays);
  let blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
  FileSaver.saveAs(blob, "trades.csv");
}
