import { SheetTrade } from "@backend/entities/sheet-trade";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SheetState = {
  sheetTrades: SheetTrade[];
};

const initialState = {
  sheetTrades: [],
} as SheetState;

export const sheetStateSlice = createSlice({
  name: "sheetState",
  initialState,
  reducers: {
    setSheetTrades(state, action: PayloadAction<SheetTrade[]>) {
      state.sheetTrades = action.payload;
    },
  },
});

export const sheetState = sheetStateSlice.reducer;
export const { setSheetTrades } = sheetStateSlice.actions;
