import React, { useState } from "react";
import { useSelector } from "react-redux";
import CsvDropzone from "../../../csv-dropzone/csv-dropzone.js";
import Select from "react-select";
import { RootState } from "../../../../index.js";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  encodig: "",
};

export default function InsertQuotesSubWizard3() {
  const [selectedDataSourceOption, setSelectedDataSourceOption] =
    useState<any>(null);

  const { dataSources, dataSourceOptions } = useSelector((state: RootState) => {
    return {
      dataSources: state.dataSourcesState.dataSources,
      dataSourceOptions: state.dataSourcesState.dataSources.map(
        (dataSource) => {
          return { value: dataSource.id, label: dataSource.name };
        }
      ),
    };
  });

  return (
    <div>
      <h2>Select Data Source</h2>
      <Select
        value={selectedDataSourceOption}
        onChange={(selectedOption) => {
          setSelectedDataSourceOption(selectedOption);
        }}
        options={dataSourceOptions}
      />
      {selectedDataSourceOption && (
        <>
          <h2>
            Drop Quotes FILE NAME MATCHING SYMBOL
            [date,open,high,low,close,period]
          </h2>
          {/* <CsvDropzone onFilesReceived={quoteDrop} /> */}
        </>
      )}
    </div>
  );
}
