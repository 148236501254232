import React, { useEffect, useRef } from "react";
import { createChart, ISeriesApi, LineData } from "lightweight-charts";

export default function LineChart({
  data,
  width,
  height,
}: {
  data: LineData[];
  width: string;
  height: string;
}) {
  const chartElement = useRef(null);
  const lineSeriesRef = useRef<ISeriesApi<"Line"> | null>(null);

  // let lineSeriesRef: ISeriesApi<"Line">;
  useEffect(() => {
    console.log({ data });
    if (data.length > 0) {
      if (lineSeriesRef.current) {
        lineSeriesRef.current.setData(data);
      } else if (chartElement.current) {
        const chart = createChart(chartElement.current);
        lineSeriesRef.current = chart.addLineSeries();
        lineSeriesRef.current.setData(data);
      }
    }
  }, [chartElement, data]);

  return (
    <div
      style={{ width: width, height: height }}
      ref={chartElement}
      className={"line-chart"}
    />
  );
}
