import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { SpinnerIcon } from "../icons/spinner-icon.js";
import { useSelector } from "react-redux";

import * as FileSaver from "file-saver";
import * as csv from "csv-stringify/browser/esm/sync";
import DifferenceInDaysReportsReactTable from "../tables/difference-in-days-reports-react-table";
import DifferenceInCloseReportsReactTable from "../tables/difference-in-close-reports-react-table";
import { RootState } from "../../index";
import { getQuoteCollectionsHealth } from "../../requests/health-requests";

export default function QuoteCollectionsHealth() {
  const [quoteCollectionsHealth, setQuoteCollectionsHealth] =
    useState<any>(null);

  const [differenceInDaysReports, setDifferenceInDaysReports] =
    useState<any>(null);
  const [differenceInDaysReportsFiltered, setDifferenceInDaysReportsFiltered] =
    useState<any>(null);

  const [differenceInCloseReports, setDifferenceInCloseReports] =
    useState(null);

  const [filterEquals, setFilterEquals] = useState<boolean>(false);

  const [
    faultyQuoteCollectionCountPerDataSource,
    setFaultyQuoteCollectionCountPerDataSource,
  ] = useState<any>(null);
  const [fetching, setFetching] = useState(false);

  const markets = useSelector((state: RootState) => state.marketsState.markets);
  const dataSources = useSelector(
    (state: RootState) => state.dataSourcesState.dataSources
  );

  useEffect(() => {
    if (filterEquals) {
      setDifferenceInDaysReportsFiltered(
        differenceInDaysReports.filter((report: any, index: number) => {
          return (
            differenceInDaysReports.filter(
              (innerReport: any, innerIndex: number) => {
                if (index === innerIndex) return false;
                return (
                  report.dateA === innerReport.dateA &&
                  report.dateB === innerReport.dateB &&
                  report.differenceInDays === innerReport.differenceInDays
                );
                // return (
                //   report.quoteA.date === innerReport.quoteA.date &&
                //   report.quoteB.date === innerReport.quoteB.date &&
                //   report.differenceInDays === innerReport.differenceInDays
                // );
              }
            ).length < 2
          );
        })
      );
    } else if (!filterEquals) {
      setDifferenceInDaysReportsFiltered(differenceInDaysReports);
    }
  }, [filterEquals, differenceInDaysReports]);
  return (
    <div style={{ overflow: "visible", height: "100%", width: "100%" }}>
      <h1>Quote Collection Health</h1>
      <button onClick={getQuoteHealth}>
        get quote health statistics(DATA WARNING!)
      </button>

      {fetching && <SpinnerIcon />}
      {faultyQuoteCollectionCountPerDataSource && (
        <table>
          <thead>
            <tr>
              <th>dataSource</th>
              <th>count</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(faultyQuoteCollectionCountPerDataSource).map((key) => {
              return (
                <tr>
                  <td>
                    {
                      dataSources.find(
                        (dataSource) => dataSource.id === parseInt(key)
                      )?.name
                    }
                  </td>
                  <td>{faultyQuoteCollectionCountPerDataSource[key]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {quoteCollectionsHealth && differenceInDaysReports && (
        <div>
          <button onClick={downloadCsv}>To csv</button>
          <div>
            <label>filterEquals</label>{" "}
            <input
              type={"checkbox"}
              checked={filterEquals}
              onChange={(event) => setFilterEquals(event.target.checked)}
            />
          </div>

          <DifferenceInDaysReportsReactTable
            differenceInDaysReports={differenceInDaysReportsFiltered}
          />
          {/*<ReactJson src={quoteCollectionsHealth} collapsed={3} />*/}
        </div>
      )}
    </div>
  );
  async function getQuoteHealth() {
    if (markets) {
      setFetching(true);
      let response = await getQuoteCollectionsHealth();
      let quoteCollectionsHealth = response.data.data;

      quoteCollectionsHealth = quoteCollectionsHealth.map(
        (quoteCollectionHealth: any) => {
          let copy = { ...quoteCollectionHealth };
          copy.quoteCollection.market = markets.find(
            (market) => market.id === copy.quoteCollection.marketId
          );
          return copy;
        }
      );
      let differenceInDaysReports: any = [];
      let differenceInCloseReports: any = [];
      let faultyQuoteCollectionCountPerDataSource =
        quoteCollectionsHealth.reduce((a: any, quoteCollectionHealth: any) => {
          if (quoteCollectionHealth.differenceInDaysReports.length === 0)
            return a;
          let count =
            a[quoteCollectionHealth.quoteCollection.dataSourceId] || 0;
          count++;
          a[quoteCollectionHealth.quoteCollection.dataSourceId] = count;
          return a;
        }, {});
      for (let quoteCollectionHealth of quoteCollectionsHealth) {
        quoteCollectionHealth.differenceInDaysReports.forEach((report: any) => {
          differenceInDaysReports.push({
            symbol: quoteCollectionHealth.quoteCollection.market.symbol,
            quoteCollectionId: quoteCollectionHealth.quoteCollection.id,
            differenceInDays: report.differenceInDays,
            dateA: report.quoteA.date,
            dateB: report.quoteB.date,
          });
        });
        quoteCollectionHealth.differenceInCloseReports.forEach(
          (report: any) => {
            differenceInCloseReports.push({
              symbol: quoteCollectionHealth.quoteCollection.market.symbol,
              quoteCollectionId: quoteCollectionHealth.quoteCollection.id,
              differenceInCloseReports: report.differenceInClose,
              dateA: report.quoteA.date,
              dateB: report.quoteB.date,
            });
          }
        );
      }
      setDifferenceInDaysReports(differenceInDaysReports);
      setDifferenceInCloseReports(differenceInCloseReports);
      setQuoteCollectionsHealth(quoteCollectionsHealth);
      setFaultyQuoteCollectionCountPerDataSource(
        faultyQuoteCollectionCountPerDataSource
      );
      setFetching(false);
    }
  }
  function downloadCsv() {
    if (quoteCollectionsHealth) {
      let csvArray = [
        ["symbol", "quoteCollectionId", "differenceInDays", "dateA", "dateB"],
      ];
      for (let quoteCollectionHealth of quoteCollectionsHealth) {
        quoteCollectionHealth.differenceInDaysReports.forEach((report: any) => {
          csvArray.push([
            quoteCollectionHealth.quoteCollection.market.symbol,
            quoteCollectionHealth.quoteCollection.id,
            report.differenceInDays,
            report.quoteA.date,
            report.quoteB.date,
          ]);
        });
      }

      let content = csv.stringify(csvArray);

      let blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(
        blob,
        `${new Date().toISOString()}-quote-collections-days-health-report.csv`
      );
    }
    let csvArray = [
      ["symbol", "quoteCollectionId", "differenceInClose", "dateA", "dateB"],
    ];
    for (let quoteCollectionHealth of quoteCollectionsHealth) {
      quoteCollectionHealth.differenceInCloseReports.forEach((report: any) => {
        csvArray.push([
          quoteCollectionHealth.quoteCollection.market.symbol,
          quoteCollectionHealth.quoteCollection.id,
          report.differenceInClose,
          report.quoteA.date,
          report.quoteB.date,
        ]);
      });
    }

    let content = csv.stringify(csvArray);

    let blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(
      blob,
      `${new Date().toISOString()}-quote-collections-close-health-report.csv`
    );
  }
}
