import QuoteCollectionSelect from "../selects/quote-collection-select";
import { useSelector } from "react-redux";
import ComparedQuotesReactTable from "../tables/compared-quotes-react-table";
import QuoteCollectionSimilarity from "../wizards/merge-quote-collection-wizard/quote-collection-similarity";
import { RootState } from "../../index";
import { compareQuoteCollections } from "../../requests/quote-collections-requests";
import { useEffect, useState } from "react";

export default function CompareQuoteCollection() {
  const [
    firstSelectedQuoteCollectionOption,
    setFirstSelectedQuoteCollectionOption,
  ] = useState<any>(null);

  const [
    secondSelectedQuoteCollectionOption,
    setSecondSelectedQuoteCollectionOption,
  ] = useState<any>(null);

  const quoteCollections = useSelector(
    (state: RootState) => state.quoteCollectionsState.quoteCollections
  );

  const [comparedQuotes, setComparedQuotes] = useState<any[]>([]);

  const [filteredComparedQuotes, setFilteredComparedQuotes] = useState<any[]>(
    []
  );

  const [filterEqualQuotes, setFilterEqualQuotes] = useState<boolean>(false);

  useEffect(() => {
    if (comparedQuotes) {
      setFilteredComparedQuotes(
        filterEqualQuotes
          ? comparedQuotes.filter((comparedQuote) => {
              return (
                comparedQuote.d_open !== 0 &&
                comparedQuote.d_low !== 0 &&
                comparedQuote.d_high !== 0 &&
                comparedQuote.d_close !== 0
              );
            })
          : comparedQuotes
      );
    }
  }, [comparedQuotes, filterEqualQuotes]);

  useEffect(() => {
    if (
      firstSelectedQuoteCollectionOption &&
      secondSelectedQuoteCollectionOption
    ) {
      setComparedQuotes([]);
      compareQuoteCollections({
        sourceQuoteCollectionId: firstSelectedQuoteCollectionOption.value,
        targetQuoteCollectionId: secondSelectedQuoteCollectionOption.value,
      }).then((response) => setComparedQuotes(response.data.data));
    }
  }, [firstSelectedQuoteCollectionOption, secondSelectedQuoteCollectionOption]);

  return (
    <div style={{ overflow: "visible", height: "100%", width: "100%" }}>
      <h1>Compare Quote Collections</h1>
      <h2>Quote collection 1</h2>
      <QuoteCollectionSelect
        selectedOption={firstSelectedQuoteCollectionOption}
        setSelectedOption={setFirstSelectedQuoteCollectionOption}
        filterFunction={(quoteCollection: any) => {
          if (secondSelectedQuoteCollectionOption) {
            let secondSelectedQuoteCollection = quoteCollections.find(
              (innerQuoteCollection) =>
                innerQuoteCollection.id ===
                secondSelectedQuoteCollectionOption.value
            );
            if (!secondSelectedQuoteCollection) return false;
            return (
              quoteCollection.marketId ===
                secondSelectedQuoteCollection.marketId &&
              quoteCollection.period === secondSelectedQuoteCollection.period &&
              quoteCollection.id !== secondSelectedQuoteCollection.id
            );
          }
          return true;
        }}
      />
      <h2>Quote collection 2</h2>
      <QuoteCollectionSelect
        selectedOption={secondSelectedQuoteCollectionOption}
        setSelectedOption={setSecondSelectedQuoteCollectionOption}
        filterFunction={(quoteCollection: any) => {
          if (firstSelectedQuoteCollectionOption) {
            let firstSelectedQuoteCollection = quoteCollections.find(
              (innerQuoteCollection) =>
                innerQuoteCollection.id ===
                firstSelectedQuoteCollectionOption.value
            );
            if (!firstSelectedQuoteCollection) return false;
            return (
              quoteCollection.marketId ===
                firstSelectedQuoteCollection.marketId &&
              quoteCollection.period === firstSelectedQuoteCollection.period &&
              quoteCollection.id !== firstSelectedQuoteCollection.id
            );
          }
          return true;
        }}
      />
      {filteredComparedQuotes && (
        <>
          <h2>results</h2>

          <div>
            <label>filter out equal quotes</label>
            <input
              type={"checkbox"}
              checked={filterEqualQuotes}
              onChange={(event) => setFilterEqualQuotes(event.target.checked)}
            />
          </div>

          <div>
            <label>Similarity: </label>
            <QuoteCollectionSimilarity
              targetQuoteCollectionId={
                secondSelectedQuoteCollectionOption.value
              }
              sourceQuoteCollectionId={firstSelectedQuoteCollectionOption.value}
            />
          </div>

          <ComparedQuotesReactTable comparedQuotes={filteredComparedQuotes} />
        </>
      )}
    </div>
  );
}
