import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDistinctAccountNumbers } from "../../requests/account-data-point-requests";

export default function MetatraderAccounts() {
  const [metatraderAccountNumbers, setMetatraderAccountNumbers] = useState<
    number[]
  >([]);

  useEffect(() => {
    getDistinctAccountNumbers().then((response) =>
      setMetatraderAccountNumbers(response.data.data)
    );
  }, []);

  return (
    <div>
      <h1>Metatrader Accounts:</h1>
      {metatraderAccountNumbers.map((accountNumber) => {
        return (
          <div>
            <Link to={`/accounts/${accountNumber}`}>{accountNumber}</Link>
          </div>
        );
      })}
    </div>
  );
}
