import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { putUserMeAction } from "../../../../actions/users-actions";

export function CorrelationSettings() {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.loginState.user);

  const [customCorrelation, setCustomCorrelation] = useState<number>(0);

  useEffect(() => {
    if (user) setCustomCorrelation(user.customCorrelation);
  }, [user]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type={"submit"} value={"save changes"} />
        <label>Custom Correlation(length)</label>
        <input
          value={customCorrelation}
          onChange={(event) =>
            setCustomCorrelation(parseInt(event.target.value))
          }
        />
      </form>
    </div>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    let newUser = Object.assign({}, user, { customCorrelation });
    dispatch(putUserMeAction(newUser));
  }
}
