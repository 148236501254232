import { setMarketsLastUpdated } from "../reducers/informational-reducer";
import { setMarket } from "../reducers/market-reducer";
import {
  setMarkets,
  deleteMarket as deleteMarketFromReducer,
} from "../reducers/markets-reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  deleteMarket,
  getMarket,
  getMarkets,
  getMarketsLastUpdated,
  getMarketsWithQuotes,
} from "../requests/markets-requests";

export function getMarketsAction() {
  return (dispatch: Dispatch) => {
    getMarkets()
      .then((response) => dispatch(setMarkets(response.data.data)))
      .catch((error) => console.error(error));
  };
}

export function getMarketAction(marketId: number) {
  return (dispatch: Dispatch) => {
    getMarket(marketId).then((response) =>
      dispatch(setMarket(response.data.data))
    );
  };
}

export function getMarketsLastUpdatedAction() {
  return (dispatch: Dispatch) => {
    getMarketsLastUpdated()
      .then((response) => dispatch(setMarketsLastUpdated(response.data.data)))
      .catch((error) => console.log(error));
  };
}

export function deleteMarketAction(id: number) {
  return (dispatch: Dispatch) => {
    deleteMarket(id)
      .then((response) => {
        if (response.data.id !== null)
          dispatch(
            deleteMarketFromReducer(
              response.data.id instanceof Array
                ? response.data.id[0]
                : response.data.id
            )
          );
      })
      .catch((error) => console.log(error));
  };
}
