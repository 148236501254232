import { Dispatch } from "@reduxjs/toolkit";
import { setSwarms } from "../reducers/swarms-reducer";
import { getMeSwarms } from "../requests/swarm-requests";

export function getMeSwarmsAction() {
  return (dispatch: Dispatch) => {
    getMeSwarms()
      .then((response) => dispatch(setSwarms(response.data.data)))
      .catch((error) => console.log(error));
  };
}
