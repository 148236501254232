import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SimpleFxState = {
  parsedSimpleFxMarkets: ParsedSimpleFxMarket[];
};

const initialState = {
  parsedSimpleFxMarkets: [],
} as SimpleFxState;

export const simpleFxStateSlice = createSlice({
  name: "simpleFxState",
  initialState,
  reducers: {
    setSimpleFxMarkets(state, action: PayloadAction<ParsedSimpleFxMarket[]>) {
      state.parsedSimpleFxMarkets = action.payload;
    },
  },
});

export const simpleFxState = simpleFxStateSlice.reducer;
export const { setSimpleFxMarkets } = simpleFxStateSlice.actions;
