export function flattenObject(obj: any, prefix = "") {
  const flattenedObject: any = {};
  for (const key in obj) {
    const value = obj[key];

    // If the value is an object, recursively flatten it
    if (typeof value === "object" && value !== null) {
      const nestedPrefix = prefix ? `${prefix}.${key}` : key;
      const nestedObject = flattenObject(value, nestedPrefix);
      Object.assign(flattenedObject, nestedObject);
    } else {
      // If the value is not an object, add it to the flattened object
      const flattenedKey = prefix ? `${prefix}.${key}` : key;
      const keys = flattenedKey.split(".");
      const lastKey: any = keys.pop();

      const formattedKey = keys.reduce((acc, cur) => {
        if (acc === "") {
          return cur.replace(/(\w)\w*/g, "$1.");
        } else {
          return acc + cur.replace(/(\w)\w*/g, "$1.") + ".";
        }
      }, "");

      const lastKeyInitials = lastKey.replace(/(\w)\w*/g, "$1");
      const formattedLastKey =
        lastKeyInitials + lastKey.slice(lastKeyInitials.length);

      flattenedObject[formattedKey + formattedLastKey] = value;
    }
  }

  return flattenedObject;
}
