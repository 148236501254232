import { AccountTrade } from "@backend/entities/account-trade";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import axios, { AxiosResponse } from "axios";
import {
  getAccountTradesByAccountNumberEndpoint,
  getDistinctAccountTradeAccountNumbersEndpoint,
} from "./endpoints";

export async function getAccountTradesByAccountNumber(
  accountNumber: number
): Promise<AxiosResponse<ReadResponseBody<AccountTrade[]>>> {
  return axios.get(getAccountTradesByAccountNumberEndpoint(accountNumber));
}
export function getDistinctAccountNumbers(): Promise<
  AxiosResponse<ReadResponseBody<number[]>>
> {
  return axios.get(getDistinctAccountTradeAccountNumbersEndpoint);
}
