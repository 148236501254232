import { QuoteCollection } from "@backend/entities/quote-collection";
import { QuoteCollectionMetadata } from "@backend/entities/quote-collection-metadata";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type QuoteCollectionsState = {
  quoteCollections: QuoteCollection[];
  quoteCollectionsMetadata: QuoteCollectionMetadata[];
};

const initialState = {
  quoteCollections: [],
  quoteCollectionsMetadata: [],
} as QuoteCollectionsState;

export const quoteCollectionsStateSlice = createSlice({
  name: "quoteCollectionsState",
  initialState,
  reducers: {
    setQuoteCollections(state, action: PayloadAction<QuoteCollection[]>) {
      state.quoteCollections = action.payload;
    },
    setQuoteCollectionsMetadata(
      state,
      action: PayloadAction<QuoteCollectionMetadata[]>
    ) {
      state.quoteCollectionsMetadata = action.payload;
    },
  },
});

export const quoteCollectionsState = quoteCollectionsStateSlice.reducer;
export const { setQuoteCollections, setQuoteCollectionsMetadata } =
  quoteCollectionsStateSlice.actions;
