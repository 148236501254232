import React, { useEffect, useState } from "react";
import {
  addLeadingZero,
  secondsToHourMinuteTimeString,
} from "../../functions/date-functions";
import { useSelector } from "react-redux";
import "./trading-hours.scss";
import { RootState } from "../../index";
import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";

export default function TradingHours() {
  const simpleFxMarkets = useSelector(
    (state: RootState) => state.simpleFxState.parsedSimpleFxMarkets
  );
  const [filteredMarkets, setFilteredMarkets] =
    useState<ParsedSimpleFxMarket[]>(simpleFxMarkets);
  const [filter, setFilter] = useState<string>("");
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (simpleFxMarkets && filter)
      setFilteredMarkets(
        simpleFxMarkets.filter((market) => {
          return market.symbol.toLowerCase().includes(filter.toLowerCase());
        })
      );
    if (filter === "") setFilteredMarkets(simpleFxMarkets);
  }, [filter]);

  return (
    <div>
      <h1>Trading Hours</h1>
      <div>Current Local Time: {new Date().toLocaleTimeString()}</div>
      <div>Current UTC Time: {getCurrentUTCTimeString()}</div>
      <input
        autoFocus={true}
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      />
      {renderTradingHoursTable()}
    </div>
  );

  function getCurrentUTCTimeString() {
    let currentDate = new Date();
    let hours = addLeadingZero(2, currentDate.getUTCHours().toString());
    let minutes = addLeadingZero(2, currentDate.getUTCMinutes().toString());
    let seconds = addLeadingZero(2, currentDate.getUTCSeconds().toString());
    return `${hours}:${minutes}:${seconds}`;
  }

  function renderTradingHoursTable() {
    return (
      <div>
        {renderOffsetControls()}
        <table className={"trading-hour-table"}>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Sunday</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
            </tr>
          </thead>
          <tbody>
            {filteredMarkets.map((market) => {
              return (
                <tr>
                  <td>{market.symbol}</td>
                  <td>{getTradingHourString(market.tradingHours[0])}</td>
                  <td>{getTradingHourString(market.tradingHours[1])}</td>
                  <td>{getTradingHourString(market.tradingHours[2])}</td>
                  <td>{getTradingHourString(market.tradingHours[3])}</td>
                  <td>{getTradingHourString(market.tradingHours[4])}</td>
                  <td>{getTradingHourString(market.tradingHours[5])}</td>
                  <td>{getTradingHourString(market.tradingHours[6])}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderOffsetControls() {
    let localOffset = new Date().getTimezoneOffset();
    let options = [];
    for (let i = 12; i >= -12; i--) {
      options.push({
        value: i * 60,
        label: `${i}:00${-localOffset === i * 60 ? " (local)" : ""}`,
      });
    }

    return (
      <div>
        <label>Offset: </label>
        <select
          value={offset}
          onChange={(event) => setOffset(parseInt(event.target.value))}
        >
          {options.map((option) => {
            return <option value={option.value * 60}>{option.label}</option>;
          })}
        </select>
      </div>
    );
  }

  function getTradingHourString(tradingHoursEntry: any) {
    if (!tradingHoursEntry) return <div>closed</div>;
    return tradingHoursEntry.map((entry: any) => {
      return (
        <div>
          {secondsToHourMinuteTimeString(entry.from + offset)} -{" "}
          {secondsToHourMinuteTimeString(entry.to + offset)}
        </div>
      );
    });
  }
}
