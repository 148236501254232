import React, { useState } from "react";
import InsertQuotesSubWizard1 from "./insert-quotes-sub-wizards/insert-quotes-sub-wizard-1";
import InsertQuotesSubWizard2 from "./insert-quotes-sub-wizards/insert-quotes-sub-wizard-2";
import InsertQuotesSubWizard3 from "./insert-quotes-sub-wizards/insert-quotes-sub-wizard-3";

export default function InsertQuoteWizard() {
  const [selectedWizi, setSelectedWizi] = useState(0);

  return (
    <div className={"insert-quotes-wizard"}>
      <h1>Insert Quote Wizard</h1>
      {!selectedWizi && renderPathSelector()}
      {selectedWizi && renderWizi()}
    </div>
  );

  function renderWizi() {
    if (selectedWizi === 1) return <InsertQuotesSubWizard1 />;
    else if (selectedWizi === 2) return <InsertQuotesSubWizard2 />;
    else if (selectedWizi === 3) return <InsertQuotesSubWizard3 />;
  }

  function renderPathSelector() {
    return (
      <div>
        <button disabled={true} onClick={() => setSelectedWizi(1)}>
          dataSource&rarr;quoteCollection&rarr;quoteDrop
        </button>
        <button onClick={() => setSelectedWizi(2)}>
          dataSource&rarr;period&rarr;quoteDrop
        </button>
        <button disabled={true} onClick={() => setSelectedWizi(3)}>
          dataSource&rarr;quoteDrop
        </button>
      </div>
    );
  }
}
