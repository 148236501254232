import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as Papa from "papaparse";
import "./csv-dropzone.scss";

export default function CsvDropzone({ onFilesReceived }: any) {
  const onDrop = useCallback((acceptedFiles: any) => {
    let openedFiles: any = [];
    acceptedFiles.forEach((file: any) => {
      let reader = new FileReader();
      reader.onload = () => {
        let rslt = reader.result;
        openedFiles.push({ name: file.name, data: rslt });
        if (openedFiles.length === acceptedFiles.length) {
          onFilesReceived(openedFiles);
        }
      };
      reader.readAsBinaryString(file);
    });

    // onFilesReceived(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={"dropzone"} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>yes put them in here :D</p>
      ) : (
        <p>drag csv file(s) in to here</p>
      )}
    </div>
  );
}
