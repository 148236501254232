import { User } from "@backend/entities/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UsersState = {
  users: User[];
};

const initialState = {
  users: [],
} as UsersState;

export const usersStateSlice = createSlice({
  name: "usersState",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
  },
});

export const usersState = usersStateSlice.reducer;
export const { setUsers } = usersStateSlice.actions;
