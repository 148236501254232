import { Correlation } from "@backend/entities/correlation";

export function filterCorrelationsByIds(
  correlations: Correlation[],
  ids: number[]
) {
  return correlations.filter((correlation) => {
    if (ids.length === 1)
      return (
        ids.includes(correlation.firstMarketId) ||
        ids.includes(correlation.secondMarketId)
      );
    return (
      ids.includes(correlation.firstMarketId) &&
      ids.includes(correlation.secondMarketId)
    );
  });
}
