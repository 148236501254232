import axios, { AxiosResponse } from "axios";
import {
  deleteRiskProfileEndpoint,
  deleteTokenEndpoint,
  getUserMeEndpoint,
  getUsersEndpoint,
  postRiskProfileEndpoint,
  postTokenEndpoint,
  postUserEndpoint,
  putRiskProfileEndpoint,
  putUserEndpoint,
  putUserMeEndpoint,
} from "./endpoints";
import { PersonalRiskProfileCreationBody } from "@backend/request-bodies/personal-risk-profile-creation-body";
import { RiskProfileUpdateBody } from "@backend/request-bodies/risk-profile-update-body";
import { User } from "@backend/entities/user";
import { RiskProfile } from "@backend/entities/risk-profile";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { UpdateResponseBody } from "@backend/response-bodies/update-response-body";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { DeleteResponseBody } from "@backend/response-bodies/delete-response-body";

export function putUserMe(
  user: User
): Promise<AxiosResponse<UpdateResponseBody<User>>> {
  return axios.put(putUserMeEndpoint, user);
}

export function putUser(
  id: number,
  user: User
): Promise<AxiosResponse<UpdateResponseBody<User>>> {
  return axios.put(putUserEndpoint(id), user);
}

export function getUsers(): Promise<AxiosResponse<ReadResponseBody<User[]>>> {
  return axios.get(getUsersEndpoint);
}

export function getMe(): Promise<AxiosResponse<ReadResponseBody<User>>> {
  return axios.get(getUserMeEndpoint);
}

export function postRiskProfile(
  riskProfileCreationBody: PersonalRiskProfileCreationBody
): Promise<AxiosResponse<CreateResponseBody<RiskProfile>>> {
  return axios.post(postRiskProfileEndpoint, riskProfileCreationBody);
}

export function putRiskProfile(
  riskProfileId: number,
  riskProfileUpdateBody: RiskProfileUpdateBody
): Promise<AxiosResponse<CreateResponseBody<RiskProfile>>> {
  return axios.put(
    putRiskProfileEndpoint(riskProfileId),
    riskProfileUpdateBody
  );
}

export function deleteRiskProfile(
  riskProfileId: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteRiskProfileEndpoint(riskProfileId));
}

export function postUser(
  username: string,
  email: string
): Promise<AxiosResponse<CreateResponseBody<User>>> {
  return axios.post(postUserEndpoint, { username: username, email: email });
}

export function postToken(
  tokenName: string
): Promise<AxiosResponse<ReadResponseBody<string>>> {
  return axios.post(postTokenEndpoint, { tokenName });
}
export function deleteToken(tokenName: string) {
  return axios.delete(deleteTokenEndpoint, { data: { tokenName } });
}
