import { Trade } from "@backend/entities/trade";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TradeState = {
  status: string;
  trade: Trade | null;
};

const initialState = {
  status: "idle",
  trade: null,
} as TradeState;

export const tradeStateSlice = createSlice({
  name: "tradeState",
  initialState,
  reducers: {
    setTrade(state, action: PayloadAction<Trade>) {
      state.trade = action.payload;
      state.status = "succeeded";
    },
    fetchingTrade(state) {
      state.trade = null;
      state.status = "loading";
    },
  },
});

export const tradeState = tradeStateSlice.reducer;
export const { setTrade, fetchingTrade } = tradeStateSlice.actions;
