import React, { useEffect, useState } from "react";
import "./seasonal-searcher-from.scss";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon } from "../icons/search-icon";
import { Tooltip } from "../tooltip/tooltip";
import { getTradesAction } from "../../actions/general-actions";
import { RootState } from "../..";
import { Market } from "@backend/entities/market";
import { TradeCriteria } from "@backend/objects/trade-criteria";
import MarketsSelect from "../selects/markets-select";
import { MultiValue } from "react-select";
import MarketsSelectNew from "../selects/markets-select-new";
import ReactDatePicker from "react-datepicker";
import CalendarIcon from "../icons/calender-icon";

interface IForm {
  startDateRangeStartDay: number;
  startDateRangeStartMonth: number;
  startDateRangeStartYear: number;
  startDateRangeEndDay: number;
  startDateRangeEndMonth: number;
  startDateRangeEndYear: number;
  endDateRangeStartDay: number;
  endDateRangeStartMonth: number;
  endDateRangeEndDay: number;
  endDateRangeEndMonth: number;
  minPlRatio: number;
  minWinPercentage: number;
  minNumberOfTrades: number;
  minAvgBars: number;
  targetYear: number;
}

export function SeasonalSearcherForm({
  toggleFormExtended,
}: {
  toggleFormExtended: any;
}) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const { register, handleSubmit, setValue, formState } = useForm<IForm>({
    defaultValues: cookies.form,
  });
  const [advanced, setAdvanced] = useState(false);
  const dispatch = useDispatch();
  const targetYear = useSelector(
    (state: RootState) => state.settingsState.targetYear
  );

  const [selectedMarketOptions, setSelectedMarketOptions] = useState<
    MultiValue<{ label: string; value: number }>
  >([]);

  function onSubmit(data: any) {
    console.log(data);
    setCookie("form", data);
    if (advanced) {
      dispatch(
        getTradesAction(
          Object.assign({}, data, {
            markets: selectedMarketOptions.map((option) => option.value),
          })
        )
      );
      toggleFormExtended();
    } else {
      let simpleData = {
        startDateRangeStartDay: parseInt(data.startDateRangeStartDay),
        startDateRangeStartMonth: parseInt(data.startDateRangeStartMonth),
        startDateRangeStartYear: parseInt(data.startDateRangeStartYear),
        startDateRangeEndDay: parseInt(data.startDateRangeEndDay),
        startDateRangeEndMonth: parseInt(data.startDateRangeEndMonth),
        startDateRangeEndYear: parseInt(data.startDateRangeEndYear),
        endDateRangeStartDay: parseInt(data.endDateRangeStartDay),
        endDateRangeStartMonth: parseInt(data.endDateRangeStartMonth),
        endDateRangeEndDay: parseInt(data.endDateRangeEndDay),
        endDateRangeEndMonth: parseInt(data.endDateRangeEndMonth),
        minPlRatio: parseFloat(data.minPlRatio),
        minWinPercentage: parseFloat(data.minWinPercentage),
        minNumberOfTrades: parseFloat(data.minNumberOfTrades),
        minAvgBars: parseFloat(data.minAvgBars),
        targetYear,
      };
      simpleData = Object.assign({}, data, simpleData);

      console.log(simpleData);
      dispatch(
        getTradesAction(
          Object.assign({}, simpleData, {
            markets: selectedMarketOptions.map((option) => option.value),
          } as TradeCriteria)
        )
      );
      toggleFormExtended();
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={"seasonal-searcher-form"}
    >
      <div className={"input-label-pair"}>
        <label>Markets</label>
        <MarketsSelectNew
          selectedMarketOptions={selectedMarketOptions}
          setSelectedMarketOptions={setSelectedMarketOptions}
        />
      </div>
      <div className={"input-label-pair date-range"}>
        <label>Start Date Range</label>
        <div></div>
        <input
          placeholder={"day"}
          type={"number"}
          {...register("startDateRangeStartDay", { required: true })}
          required
        />
        <input
          {...register("startDateRangeStartMonth", { required: true })}
          placeholder={"month"}
          type={"number"}
          required
        />
        <input
          {...register("startDateRangeStartYear", { required: true })}
          placeholder={"year"}
          type={"number"}
          required
        />
        <ReactDatePicker
          customInput={
            <div className={"datepicker"}>
              <CalendarIcon />
            </div>
          }
          onChange={(date) => {
            if (date) {
              setValue("startDateRangeStartMonth", date.getMonth() + 1);
              setValue("startDateRangeStartDay", date.getDate());
              setValue("startDateRangeStartYear", date.getFullYear());
            }
          }}
          // placeholderText={"date picker"}
        />
        until
        <input
          {...register("startDateRangeEndDay", { required: true })}
          placeholder={"day"}
          type={"number"}
          required
        />
        <input
          {...register("startDateRangeEndMonth", { required: true })}
          placeholder={"month"}
          type={"number"}
          required
        />
        <input
          {...register("startDateRangeEndYear", { required: true })}
          placeholder={"year"}
          type={"number"}
          required
        />
        <ReactDatePicker
          customInput={
            <div className={"datepicker"}>
              <CalendarIcon />
            </div>
          }
          onChange={(date) => {
            if (date) {
              setValue("startDateRangeEndMonth", date.getMonth() + 1);
              setValue("startDateRangeEndDay", date.getDate());
              setValue("startDateRangeEndYear", date.getFullYear());
            }
          }}
        />
      </div>
      <div className={"input-label-pair date-range"}>
        <label>End Date Range (disabled)</label>
        <input
          disabled
          name={"endDateRangeStartDay"}
          placeholder={"day"}
          type={"number"}
        />
        <input
          disabled
          name={"endDateRangeStartMonth"}
          placeholder={"month"}
          type={"number"}
        />
        until
        <input
          disabled
          name={"endDateRangeEndDay"}
          placeholder={"day"}
          type={"number"}
        />
        <input
          disabled
          name={"endDateRangeEndMonth"}
          placeholder={"month"}
          type={"number"}
        />
      </div>
      <div className={"input-label-pair"}>
        <label>Min pl ratio</label>
        <input
          {...register("minPlRatio", { required: true })}
          type={"number"}
          required
        />
      </div>
      <div className={"input-label-pair"}>
        <label>Min win percentage</label>
        <input
          {...register("minWinPercentage", { required: true })}
          type={"number"}
          required
        />
      </div>
      <div className={"input-label-pair"}>
        <label>Min number of trades</label>
        <input
          {...register("minNumberOfTrades", { required: true })}
          type={"number"}
          required
        />
      </div>
      <div className={"input-label-pair"}>
        <label>Min avgBars</label>
        <input
          {...register("minAvgBars", { required: true })}
          type={"number"}
          required
        />
      </div>
      <div
        className={"advanced-toggler"}
        onClick={() => setAdvanced(!advanced)}
      >
        {advanced ? (
          <label>Advanced &uarr;</label>
        ) : (
          <label>Advanced &darr;</label>
        )}
      </div>
      {/* {advanced && ( */}
      {/*   <> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>longs</label> */}
      {/*       <input */}
      {/*                       name={"longs"} ref={register} type={"checkbox"} /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>shorts</label> */}
      {/*       <input name={"shorts"} ref={register} type={"checkbox"} /> */}
      {/*     </div> */}

      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>Min loss streak</label> */}
      {/*       <input name={"minLossStreak"} ref={register} type={"number"} /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>Min avg r ratio</label> */}
      {/*       <input name={"minAvgRRatio"} ref={register} type={"number"} /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>Min payout rate</label> */}
      {/*       <input name={"minPayoutRate"} ref={register} type={"number"} /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>Max biggest loss streak</label> */}
      {/*       <input */}
      {/*         name={"maxBiggestLossStreak"} */}
      {/*         ref={register} */}
      {/*         type={"number"} */}
      {/*       /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label> */}
      {/*         Max stop loss 100 */}
      {/*         <Tooltip */}
      {/*           tooltipText={"The max value the stoploss of a trade can be "} */}
      {/*         /> */}
      {/*       </label> */}

      {/*       <input name={"maxStopLoss100"} ref={register} type={"number"} /> */}
      {/*     </div> */}
      {/*     <div className={"input-label-pair"}> */}
      {/*       <label>Min avg profit percentage</label> */}
      {/*       <input */}
      {/*         name={"minAvgProfitPercentage"} */}
      {/*         ref={register} */}
      {/*         type={"number"} */}
      {/*       /> */}
      {/*     </div> */}
      {/*   </> */}
      {/* )} */}
      <input type={"submit"} />
    </form>
  );
}
