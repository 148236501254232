import { useSelector } from "react-redux";
import { RootState } from "..";

export default function useIsAdmin() {
  const admin = useSelector((state: RootState) => {
    if (state.loginState.user) {
      return state.loginState.user.role === "admin";
    }
    return false;
  });

  return admin;
}
