import React, { useEffect } from "react";
import { TabWindow } from "../tab-window/tab-window";
import { ColumnSettings } from "./tabs/column-settings/column-settings";
import { useSelector } from "react-redux";
import "./settings.scss";
import axios from "axios";
import MrSheetTokenButton from "./tabs/mr-sheet-token-button";
import RiskSettings from "./tabs/risk-settings/risk-settings";
import { CorrelationSettings } from "./tabs/correlation-settings/correlation-settings";
import HomePageSettings from "./tabs/home-page-settings/home-page-settings";
import { RootState } from "../..";
import Tokens from "../tokens/tokens";

const tabs = [
  {
    span: "Column Settings",
    component: <ColumnSettings />,
  },
  {
    span: "Risk settings",
    component: <RiskSettings />,
  },
  {
    span: "Correlation settings",
    component: <CorrelationSettings />,
  },
  {
    span: "Home Page Settings",
    component: <HomePageSettings />,
  },
  {
    span: "Tokens",
    component: <Tokens />,
  },
];

export function Settings() {
  const user = useSelector((state: RootState) => state.loginState.user);

  return (
    <div className={"settings-component"}>
      {/*{user.username}*/}
      {user && user.role === "admin" && <MrSheetTokenButton />}
      <TabWindow tabs={tabs} />
    </div>
  );
}
