import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTradeWithRelationsAction } from "../../actions/general-actions";
import ReactJson from "react-json-view";
import { SpinnerIcon } from "../icons/spinner-icon";
import { RootState } from "../..";

export function TradeDetail() {
  const { tradeId } = useParams();
  const dispatch = useDispatch();

  // const [currentYear,setCurrentYear] = useState();
  const tradeState = useSelector((state: RootState) => state.tradeState);
  // const [quotes,setQuotes] = useState();

  useEffect(() => {}, []);

  useEffect(() => {
    if (tradeId) dispatch(getTradeWithRelationsAction(tradeId));
  }, [tradeId]);
  //
  // useEffect(()=>{
  //     console.log(tradeState)
  //     if(tradeState.trade){
  //         let year = tradeState.trade.years[2001];
  //         let quotes = year.quotes.map(entry=>{
  //             let quote = entry;
  //             quote["time"] = quote.date;
  //             delete quote["date"];
  //             return quote
  //
  //         })
  //
  //         setQuotes(quotes);
  //
  //
  //     }
  //     // barSeries.setData()
  // },[tradeState,currentYear])

  return (
    <div>
      {tradeState.trade && tradeState.status === "succeeded" ? (
        <ReactJson
          src={tradeState.trade}
          displayDataTypes={false}
          collapsed={true}
        />
      ) : (
        <SpinnerIcon />
      )}
    </div>
  );
}
