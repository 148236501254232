import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountDataPointReactTable from "../tables/account-data-point-react-table";
import LineChart from "../charts/line-chart";
import { getAccountDataPointsByAccountNumber } from "../../requests/account-data-point-requests";
import { AccountDataPoint } from "@backend/entities/account-data-point";
import { LineData } from "lightweight-charts";
import { accountDataPointsReduceToLastPerDay } from "../../functions/account-data-point-funtions";
import { getAccountTradesByAccountNumber } from "../../requests/account-trade-requests";
import { AccountTrade } from "@backend/entities/account-trade";
import { SortableTable } from "../table/sortable-table";

export default function MetatraderAccount() {
  const { accountNumber } = useParams();

  const [accountDataPoints, setAccountDataPoints] = useState<
    AccountDataPoint[]
  >([]);
  const [accountTrades, setAccountTrades] = useState<AccountTrade[]>([]);

  useEffect(() => {
    if (accountNumber) {
      getAccountDataPointsByAccountNumber(parseInt(accountNumber)).then(
        (response) => {
          setAccountDataPoints(response.data.data);
        }
      );
      getAccountTradesByAccountNumber(parseInt(accountNumber))
        .then((response) => setAccountTrades(response.data.data))
        .catch((error) => console.error(error));
    }
  }, [accountNumber]);

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <h1>Account: {accountNumber}</h1>
      <h2>Account Data Points</h2>
      <h3>equity,marginLevel,accountTradeValue</h3>
      <div style={{ display: "flex" }}>
        {" "}
        <LineChart
          data={accountDataPointsReduceToLastPerDay(accountDataPoints)
            .reverse()
            .filter((accountDataPoint) => !!accountDataPoint.equity)
            .map((accountDataPoint) =>
              Object.assign({}, {
                time: new Date(accountDataPoint.timestamp).getTime() / 1000,
                value: accountDataPoint.equity,
              } as LineData)
            )}
          width={"30%"}
          height={"400px"}
        />
        <LineChart
          data={accountDataPointsReduceToLastPerDay(accountDataPoints)
            .reverse()
            .map((accountDataPoint) =>
              Object.assign({}, {
                time: new Date(accountDataPoint.timestamp).getTime() / 1000,
                value: accountDataPoint.marginLevel,
              } as LineData)
            )}
          width={"30%"}
          height={"400px"}
        />
        <LineChart
          data={accountDataPointsReduceToLastPerDay(accountDataPoints)
            .reverse()
            .filter((accountDataPoint) => !!accountDataPoint.accountTradeValue)
            .map((accountDataPoint) =>
              Object.assign({}, {
                time: new Date(accountDataPoint.timestamp).getTime() / 1000,
                value: accountDataPoint.accountTradeValue,
              } as LineData)
            )}
          width={"30%"}
          height={"400px"}
        />
      </div>

      <div style={{ height: 500, overflow: "scroll" }}>
        {accountDataPoints.length > 0 && (
          <SortableTable data={accountDataPoints} />
        )}
      </div>
      <h2>Account trades</h2>
      {accountTrades.length > 0 && <SortableTable data={accountTrades} />}
    </div>
  );
}
