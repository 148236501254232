import React, { useState } from "react";
import { postToken } from "../../requests/users-requests";

export default function CreateNewTokenButton() {
  const [stage, setStage] = useState(0);
  const [tokenName, setTokenName] = useState("");
  const [token, setToken] = useState("");

  if (stage === 2)
    return (
      <>
        <span>{token}</span>
        <button
          onClick={() => {
            navigator.clipboard.writeText(token);

            // Alert the copied text
            alert("Copied the text: " + token);
            setStage(0);
            setToken("");
            setTokenName("");
          }}
        >
          copy to clipboard
        </button>
      </>
    );
  if (stage === 1)
    return (
      <>
        <input
          value={tokenName}
          onChange={(event) => setTokenName(event.target.value)}
        />
        <button
          onClick={(event) => {
            postToken(tokenName)
              .then((response) => {
                setToken(response.data.data);
                setStage(2);
              })
              .catch((error) => console.error(error));
          }}
        >
          create
        </button>
      </>
    );
  return (
    <button
      onClick={(event) => {
        setStage(1);
      }}
    >
      create new token
    </button>
  );
}
