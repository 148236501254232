import React from "react";
import { SearchBarComponent } from "../search-bar/search-bar-component";
import AccountStats from "../sheets-stats/account-stats";
import MarketsLastUpdatedLabel from "../informational-labels/markets-last-updated-label";
import "./top-bar.scss";
import MenuButton from "./menu-button";
import TargetYearInput from "../inputs/target-year-input";

export default function TopBar({ setMenuOpen }: any) {
  return (
    <div className={"top-bar"}>
      <MenuButton onClick={() => setMenuOpen((old: any) => !old)} />
      <div className={"middle"}>
        <div className={"middle-items"}>
          <SearchBarComponent />
          <AccountStats />
          <MarketsLastUpdatedLabel />
          <TargetYearInput />
        </div>
      </div>

      <div className={"right"}></div>
    </div>
  );
}
