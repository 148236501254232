import React, { useEffect, useState } from "react";
import { flattenObject } from "../../functions/flatten-object";
import DelayedInput from "../inputs/delayed-input";
import { fuzzyObjectMatch } from "../quote-collections/quote-collections";

type Column<T> = {
  label: string;
  sortBy?: keyof T;
  render: (value: any) => React.ReactNode;
};

type SortState<T> = {
  column: keyof T | null;
  direction: "asc" | "desc";
};

type Button<T> = {
  label: string;
  onClick: (entry: T) => void;
};

type TableWithButtonsProps<T> = {
  data: T[];
  buttons?: Button<T>[];
  hideFilterInput?: boolean;
};

export function SortableTable<T extends Object>({
  data,
  buttons = [],
  hideFilterInput = false,
}: TableWithButtonsProps<T>) {
  const [filter, setFilter] = useState("");

  const [filteredData, setFilteredData] = useState<T[]>(data);

  useEffect(() => {
    if (filter && data && data.length > 0)
      setFilteredData(
        data.filter((entry) => {
          return checkObjectValuesForSubstringJSON(entry, filter);
        })
      );
    else setFilteredData(data);
  }, [filter, data]);

  const [sort, setSort] = useState<SortState<T>>({
    column: null,
    direction: "asc",
  });

  const columns: Column<T>[] =
    data && data.length > 0
      ? Object.keys(data[0]).map((key) => {
          return {
            label: key,
            sortBy: key as keyof T,
            render: (item) => <>{item[key]}</>,
          };
        })
      : [];

  const sortedData = filteredData.slice().sort((a, b) => {
    if (sort.column === null) {
      return 0;
    }

    const sortBy = columns.find((column) => column.sortBy === sort.column);
    if (!sortBy) {
      return 0;
    }

    const aValue = a[sort.column];
    const bValue = b[sort.column];

    if (aValue < bValue) {
      return sort.direction === "asc" ? -1 : 1;
    } else if (aValue > bValue) {
      return sort.direction === "asc" ? 1 : -1;
    } else {
      return 0;
    }
  });

  const handleColumnClick = (column: keyof T | undefined) => {
    if (column === undefined) return;
    if (sort.column === column) {
      setSort({
        ...sort,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({
        column,
        direction: "asc",
      });
    }
  };

  return (
    <>
      <DelayedInput
        value={filter}
        type={"text"}
        onChange={(value) => setFilter(value)}
        timeoutInMs={2000}
        placeholder={"filter..."}
      />
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.label}
                onClick={() => handleColumnClick(column.sortBy)}
              >
                {column.label}
                {sort.column === column.sortBy && (
                  <span>{sort.direction === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.label}>{column.render(item)}</td>
              ))}
              {buttons.map((button, index) => (
                <td key={index}>
                  <button onClick={() => button.onClick(item)}>
                    {button.label}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function checkObjectValuesForSubstring(
  obj: Record<string, any>,
  substr: string
): boolean {
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === "string" && value.includes(substr)) {
      return true;
    } else if (typeof value === "object") {
      if (checkObjectValuesForSubstring(value, substr)) {
        return true;
      }
    }
  }
  return false;
}
function checkObjectValuesForSubstringJSON(
  obj: Record<string, any>,
  substr: string
): boolean {
  const jsonString = JSON.stringify(obj);
  return jsonString.includes(substr);
}
