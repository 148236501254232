import { updateMeUser } from "../reducers/login-reducer";
import { setUsers } from "../reducers/users-reducer";
import {
  getUsers,
  getMe,
  putUserMe,
  putUser,
} from "../requests/users-requests";
import { Dispatch } from "@reduxjs/toolkit";
import { User } from "@backend/entities/user";

export function putUserMeAction(user: User) {
  return (dispatch: Dispatch) => {
    putUserMe(user)
      // .then((response) => dispatch(updateMeUser(response.data.data)))
      .catch((error) => console.log(error));
  };
}

export function putUserAction(id: number, user: User) {
  return (dispatch: Dispatch) => {
    putUser(id, user).then((response) => console.log("put user success"));
  };
}

export function getUsersAction() {
  return (dispatch: Dispatch) => {
    getUsers()
      .then((response) => dispatch(setUsers(response.data.data)))
      .catch((error) => console.error(error));
  };
}

export function getMeAction() {
  return (dispatch: Dispatch) => {
    getMe()
      .then((response) => dispatch(updateMeUser(response.data.data)))
      .catch((error) => console.error(error));
  };
}
