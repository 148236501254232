import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import axios, { AxiosResponse } from "axios";
import {
  getParsedSimpleFxMarketsViaBackendEndpoint,
  getSimpleFxMarketsViaBackendEndpoint,
} from "./endpoints";

export function getSimpleFxMarkets(): Promise<
  AxiosResponse<ReadResponseBody<any>>
> {
  return axios.get(getSimpleFxMarketsViaBackendEndpoint);
}

export function getParsedSimpleFxMarkets(): Promise<
  AxiosResponse<ReadResponseBody<ParsedSimpleFxMarket[]>>
> {
  return axios.get(getParsedSimpleFxMarketsViaBackendEndpoint);
}

// export const [getParsedSimpleFxMarketsSuccess,getParsedSimpleFxMarketsFailed,getParsedSimpleFxMarketsRequest] = createActionTriangle("GET_PARSED_SIMPLE_FX_MARKETS");
//
// export function getParsedSimpleFxMarkets(){
//
//     return dispatch=>{
//         axios.get(getParsedSimpleFxMarketsEndpoint)
//             .then(response=>dispatch(getParsedSimpleFxMarketsSuccess(response.data)))
//             .catch(error=>{
//                 dispatch(getParsedSimpleFxMarketsFailed(error))
//                 console.log(error);
//                 console.error(error);
//             })
//     }
// }
