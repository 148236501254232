import axios, { AxiosResponse } from "axios";
import {
  calculateMarketsEndpoint,
  deleteMarketEndpoint,
  getAverageAbsolutePercentageChangeEndpoint,
  getAveragePercentageChangeEndpoint,
  getBestTradesOfMarketEndpoint,
  getMarketEndpoint,
  getMarketsEndpoint,
  getMarketsLastUpdatedEndpoint,
  getMarketsWithQuotesEndpoint,
  postMarketsEndpoint,
} from "./endpoints";
import { Market } from "@backend/entities/market";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { CreateResponseBody } from "@backend/response-bodies/create-response-body";
import { DeleteResponseBody } from "@backend/response-bodies/delete-response-body";
import { Trade } from "@backend/entities/trade";

export function getMarkets(): Promise<
  AxiosResponse<ReadResponseBody<Market[]>>
> {
  return axios.get(getMarketsEndpoint);
}

export function getAveragePercentageChange(
  marketId: number,
  length: number
): Promise<AxiosResponse<ReadResponseBody<number>>> {
  return axios.get(getAveragePercentageChangeEndpoint(marketId, length));
}
export function getAverageAbsolutePercentageChange(
  marketId: number,
  length: number
): Promise<AxiosResponse<ReadResponseBody<number>>> {
  return axios.get(
    getAverageAbsolutePercentageChangeEndpoint(marketId, length)
  );
}

export function getBestTradesOfMarketPromise(
  marketId: number
): Promise<AxiosResponse<ReadResponseBody<Trade[]>>> {
  return axios.get(getBestTradesOfMarketEndpoint(marketId));
}

export function createMarket(
  market: Market
): Promise<AxiosResponse<CreateResponseBody<Market>>> {
  return axios.post(postMarketsEndpoint, market);
}

export function getMarket(
  marketId: number
): Promise<AxiosResponse<ReadResponseBody<Market>>> {
  return axios.get(getMarketEndpoint(marketId));
}

export function getMarketsWithQuotes(): Promise<
  AxiosResponse<ReadResponseBody<Market[]>>
> {
  return axios.get(getMarketsWithQuotesEndpoint);
}

export function getMarketsLastUpdated(): Promise<
  AxiosResponse<ReadResponseBody<Date>>
> {
  return axios.get(getMarketsLastUpdatedEndpoint);
}

export function deleteMarket(
  id: number
): Promise<AxiosResponse<DeleteResponseBody>> {
  return axios.delete(deleteMarketEndpoint(id));
}
