import React from "react";
import { useSelector } from "react-redux";
import { getHowLongAgoString } from "../../functions/date-functions";

export default function MarketsLastUpdatedLabel() {
  const marketsLastUpdated = useSelector(
    (state) => state.informationalState.marketsLastUpdated
  );

  if (marketsLastUpdated) {
    return (
      <label>
        Markets last updated: {getHowLongAgoString(marketsLastUpdated)}
      </label>
    );
  }
  return <div></div>;
}
