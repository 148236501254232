import React, { useState } from "react";

export function TabWindow({
  tabs,
}: {
  tabs: { span: string; component: JSX.Element }[];
}) {
  const [selectedComponent, setSelectedComponent] = useState(<div></div>);

  return (
    <div className={"tab-window-component"}>
      <div className={"tab-window-nav"}>{renderTabs()}</div>
      {selectedComponent}
    </div>
  );

  function renderTabs() {
    return tabs.map((tab, index) => {
      return (
        <div
          className={"tab-window-nav-button"}
          onClick={() => {
            setSelectedComponent(tab.component);
          }}
          key={index}
        >
          {tab.span}
        </div>
      );
    });
  }
}
