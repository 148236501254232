export class DividendCreationBody {
  date: Date;
  // label: string;
  // adjDividend: number;
  dividend: number;
  // recordDate: Date;
  // paymentDate: Date;
  // declarationDate: Date;

  marketId: number;
}
