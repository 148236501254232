import {
  setCalculateCorrelationsState,
  setCorrelations,
  setCustomCorrelations,
} from "../reducers/correlations-reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  calculateCorrelations,
  getCorrelations,
  getCustomCorrelations,
} from "../requests/correlation-requests";

export function getCorrelationsAction() {
  return (dispatch: Dispatch) => {
    getCorrelations().then((response) =>
      dispatch(setCorrelations(response.data.data))
    );
  };
}

export function calculateCorrelationsAction() {
  return (dispatch: Dispatch) => {
    dispatch(setCalculateCorrelationsState("loading"));
    calculateCorrelations()
      .then((response) => dispatch(setCalculateCorrelationsState("succeeded")))
      .catch((error) => console.log(error));
  };
}

export function getCustomCorrelationsAction() {
  return (dispatch: Dispatch) => {
    getCustomCorrelations()
      .then((response) => dispatch(setCustomCorrelations(response.data)))
      .catch((error) => console.log(error));
  };
}
