import { Swarm } from "@backend/entities/swarm";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SwarmsState = {
  swarms: Swarm[];
};

const initialState = {
  swarms: [],
} as SwarmsState;

export const swarmsStateSlice = createSlice({
  name: "swarmsState",
  initialState,
  reducers: {
    setSwarms(state, action: PayloadAction<Swarm[]>) {
      state.swarms = action.payload;
    },
  },
});

export const swarmsState = swarmsStateSlice.reducer;
export const { setSwarms } = swarmsStateSlice.actions;
