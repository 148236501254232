import React, { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noNullValues } from "../functions/object-functions";
let wsUrl = "ws://localhost:3000/";

if (process.env.REACT_APP_ENV === "production") {
  wsUrl = "wss://www.boybou.nl/api/";
} else if (process.env.REACT_APP_ENV === "testing") {
  wsUrl = "wss://www.boybou.nl:5001/api/";
}

export default function useWebsocket(): [
  string[],
  React.Dispatch<SetStateAction<string[]>>
] {
  const [messages, setMessages] = useState<string[]>([]);
  useEffect(() => {
    const socket = new WebSocket(wsUrl);
    socket.addEventListener("open", (event) => {
      // console.log(event);
    });
    socket.addEventListener("message", (event) => {
      setMessages((old) => old.concat(event.data));
    });
    return () => socket.close();
  }, []);

  return [messages, setMessages];
}
