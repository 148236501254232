import React from "react";
import CopyIcon from "../icons/copy-icon";
import "./label-with-copy.scss";

export default function LabelWithCopy({ string }: { string: string }) {
  return (
    <label
      className={"label-with-copy"}
      onClick={() => {
        navigator.clipboard.writeText(string);
      }}
    >
      {string}
      <CopyIcon />
    </label>
  );
}
