import React, { useEffect, useRef, useState } from "react";
import {
  CandlestickData,
  createChart,
  LineData,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import { getQuoteCollection } from "../../requests/quote-collections-requests";
import { sortByDateAsc } from "../../functions/sort-functions";
import { TALib } from "talib.ts";
import { Quote } from "@backend/entities/quote";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { getDistinctAccountNumbers } from "../../requests/account-data-point-requests";
import { getSwapSymbols } from "../../requests/swap-requests";

export default function Charting() {
  const divRef = useRef<HTMLDivElement | null>(null);

  const [ohlc, setOhlc] = useState<Quote[]>([]);

  const marketSymbols = useSelector((state: RootState) => {
    return state.marketsState.markets.map((market) => market.symbol);
  });

  const [accounts, setAccounts] = useState<number[]>([]);
  const [swapSymbols, setSwapSymbols] = useState<string[]>([]);

  useEffect(() => {
    getDistinctAccountNumbers().then((response) => {
      setAccounts(response.data.data);
    });
    getSwapSymbols().then((response) => {
      setSwapSymbols(response.data.data);
    });
  }, []);

  useEffect(() => {
    getQuoteCollection(13, true).then((response) =>
      setOhlc(response.data.data.quotes)
    );
  }, []);

  async function chartSwap(symbol: string) {}

  async function chartMarket(symbol: string) {}

  async function chartAccount(accountNumber: number) {}

  useEffect(() => {
    if (divRef.current !== null && ohlc.length > 0) {
      console.log(divRef.current);
      console.log({
        width: divRef.current.clientWidth,
        height: divRef.current.clientHeight,
      });
      const chart = createChart(divRef.current, {
        width: divRef.current.clientWidth,
        height: divRef.current.clientHeight,
      });

      const candlestickSeries = chart.addCandlestickSeries();
      const line1 = chart.addLineSeries({ lineWidth: 1 });
      const line2 = chart.addLineSeries({ lineWidth: 1 });
      const line3 = chart.addLineSeries({ lineWidth: 1 });
      const line4 = chart.addLineSeries({ lineWidth: 1 });

      let sortedOhlc = ohlc.sort((a, b) => sortByDateAsc(a.date, b.date));
      let highs = sortedOhlc.map<number>((quote) => quote.high);
      let lows = sortedOhlc.map<number>((quote) => quote.low);
      let closes = sortedOhlc.map<number>((quote) => quote.close);
      let opens = sortedOhlc.map<number>((quote) => quote.open);

      let sma8Close = TALib.sma(closes, 8).sma.getValue();
      let sma8Open = TALib.sma(opens, 8).sma.getValue();
      let sma3High = TALib.sma(highs, 3).sma.getValue();
      let sma3Low = TALib.sma(lows, 3).sma.getValue();

      let candleStickData: CandlestickData[] = [];
      let sma8CloseLineSeries: LineData[] = [];
      let sma8OpenLineSeries: LineData[] = [];
      let sma3HighLineSeries: LineData[] = [];
      let sma3LowLineSeries: LineData[] = [];
      sortedOhlc.forEach((quote, index) => {
        let date = new Date(quote.date);
        let time = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
        candleStickData.push(Object.assign({}, quote, { time }));

        let sma3HighValue = sma3High[index];
        let sma3LowValue = sma3Low[index];
        let sma8OpenValue = sma8Open[index];
        let sma8CloseValue = sma8Close[index];

        if (sma3HighValue)
          sma3HighLineSeries.push({ time, value: sma3HighValue });
        if (sma3LowValue) sma3LowLineSeries.push({ time, value: sma3LowValue });
        if (sma8CloseValue)
          sma8CloseLineSeries.push({ time, value: sma8CloseValue });
        if (sma8OpenValue)
          sma8OpenLineSeries.push({ time, value: sma8OpenValue });
      });

      candlestickSeries.setData(candleStickData);
      line1.setData(sma3HighLineSeries);
      line2.setData(sma3LowLineSeries);
      line3.setData(sma8OpenLineSeries);
      line4.setData(sma8CloseLineSeries);

      const handleResize = () => {
        if (divRef.current !== null) {
          chart.resize(divRef.current.clientWidth, divRef.current.clientHeight);
        }
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chart.remove();
      };
    }
  }, [divRef, ohlc]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", height: "10%" }}></div>
      <div style={{ width: "100%", height: "90%" }} ref={divRef}></div>
    </div>
  );
}
