import React from "react";
import Table from "../table/table";

const columns = [
  {
    Header: "date",
    accessor: "date", // accessor is the "key" in the data
  },
  {
    Header: "q1_open",
    accessor: "q1_open",
  },
  {
    Header: "q2_open",
    accessor: "q2_open",
  },
  {
    Header: "d_open",
    accessor: "d_open",
    Cell: ({ value }: any) => {
      let color = value !== 0 ? "red" : "";
      return <span style={{ color: color }}>{value.toPrecision(5)}</span>;
    },
  },
  {
    Header: "q1_high",
    accessor: "q1_high",
  },
  {
    Header: "q2_high",
    accessor: "q2_high",
  },
  {
    Header: "d_high",
    accessor: "d_high",
    Cell: ({ value }: any) => {
      let color = value !== 0 ? "red" : "";
      return <span style={{ color: color }}>{value.toPrecision(5)}</span>;
    },
  },
  {
    Header: "q1_low",
    accessor: "q1_low",
  },
  {
    Header: "q2_low",
    accessor: "q2_low",
  },
  {
    Header: "d_low",
    accessor: "d_low",
    Cell: ({ value }: any) => {
      let color = value !== 0 ? "red" : "";
      return <span style={{ color: color }}>{value.toPrecision(5)}</span>;
    },
  },
  {
    Header: "q1_close",
    accessor: "q1_close",
  },
  {
    Header: "q2_close",
    accessor: "q2_close",
  },
  {
    Header: "d_close",
    accessor: "d_close",
    Cell: ({ value }: any) => {
      let color = value !== 0 ? "red" : "";
      return <span style={{ color: color }}>{value.toPrecision(5)}</span>;
    },
  },
];

export default function ComparedQuotesReactTable({ comparedQuotes }: any) {
  return <Table columns={columns} data={comparedQuotes} />;
}
