import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CsvDropzone from "../../../csv-dropzone/csv-dropzone";
import Papa from "papaparse";
import Select from "react-select";
import { RootState } from "../../../../index";
import { postQuotes } from "../../../../requests/quote-actions";

const papaparseOptions = {
  header: true,
  skipEmptyLines: true,
  encodig: "",
};

export default function InsertQuotesSubWizard1() {
  const [selectedDataSourceOption, setSelectedDataSourceOption] =
    useState<any>(null);

  const { dataSources, dataSourceOptions } = useSelector((state: RootState) => {
    return {
      dataSources: state.dataSourcesState.dataSources,
      dataSourceOptions: state.dataSourcesState.dataSources.map(
        (dataSource) => {
          return { value: dataSource.id, label: dataSource.name };
        }
      ),
    };
  });

  const [statusText, setStatusText] = useState("");

  const quoteCollections = useSelector(
    (state: RootState) => state.quoteCollectionsState.quoteCollections
  );

  const [selectedQuoteCollectionOption, setSelectedQuoteCollectionOption] =
    useState<any>(null);
  const [quoteCollectionOptions, setQuoteCollectionOptions] =
    useState<any>(null);

  const markets = useSelector((state: RootState) => state.marketsState.markets);

  useEffect(() => {
    if (quoteCollections && selectedDataSourceOption && markets) {
      setQuoteCollectionOptions(
        quoteCollections
          .filter(
            (quoteCollection) =>
              quoteCollection.dataSourceId === selectedDataSourceOption.value
          )
          .map((quoteCollection) => {
            return {
              value: quoteCollection.id,
              label: `${
                dataSources.find(
                  (dataSource) =>
                    dataSource.id === selectedDataSourceOption.value
                )?.name
              },${
                markets.find((market) => market.id === quoteCollection.marketId)
                  ?.symbol
              },${quoteCollection.period}`,
            };
          })
      );
    }
  }, [quoteCollections, selectedDataSourceOption, markets]);

  const [quotes, setQuotes] = useState<any>(null);

  return (
    <div>
      <h2>Select Data Source</h2>
      <Select
        value={selectedDataSourceOption}
        onChange={(selectedOption) => {
          setSelectedDataSourceOption(selectedOption);
          setSelectedQuoteCollectionOption(null);
        }}
        options={dataSourceOptions}
      />
      {selectedDataSourceOption && (
        <>
          <h2>Select Quote Collection</h2>
          <Select
            value={selectedQuoteCollectionOption}
            onChange={(selectedOption) =>
              setSelectedQuoteCollectionOption(selectedOption)
            }
            options={quoteCollectionOptions}
          />
        </>
      )}
      {selectedQuoteCollectionOption && (
        <>
          <h2>Drop Quotes [date,open,high,low,close] plzzzz</h2>
          <CsvDropzone onFilesReceived={cleanQuoteDrop} />
        </>
      )}
      {quotes && (
        <>
          <button onClick={insertQuotes}>Insert Quotes</button>
        </>
      )}
      <div>{statusText}</div>
    </div>
  );

  function cleanQuoteDrop(files: any) {
    let file = files[0];
    let csvContent = Papa.parse(file.data, papaparseOptions);
    let quotes = csvContent.data;
    //make sure to initialize date
    // quotes = quotes.map((quote) => {
    //   return Object.assign({}, quote, {
    //     open:parseI
    //     date: new Date(parseInt(quote.date))
    //
    //   });
    // });
    quotes = quotes.map((quote: any) => {
      return Object.assign({}, quote, {
        quoteCollectionId: selectedQuoteCollectionOption.value,
        date: new Date(parseInt(quote.date)),
      });
    });
    setQuotes(quotes);
  }

  function insertQuotes() {
    postQuotes(quotes)
      .then((response) => {
        setStatusText("quotes inserted successfully");
      })
      .catch((error) => setStatusText(error.message));
  }
}
