import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Preset from "./preset";
import { useSelector } from "react-redux";
import { RootState } from "../../index";
import { RiskProfile } from "@backend/entities/risk-profile";

export default function PresetDownloading() {
  const { tradeIdsString } = useParams();

  const [tradeIds, setTradeIds] = useState<string[]>([]);

  const [shouldPromptDownloadOnce, setShouldPromptDownloadOnce] =
    useState(false);

  const riskProfiles = useSelector((state: RootState) =>
    !!state.loginState.user?.riskProfiles
      ? state.loginState.user.riskProfiles
      : null
  );

  useEffect(() => {
    if (tradeIdsString) setTradeIds(tradeIdsString.split(","));
  }, [tradeIdsString]);

  return (
    <div>
      {riskProfiles &&
        riskProfiles.map((riskProfile, index) => {
          return (
            <>
              <h3>Risk Profile {riskProfile.id}</h3>

              <h4>
                account size: {riskProfile.accountSize}{" "}
                {riskProfile.accountCurrency}
              </h4>
              <h4>
                risk:{" "}
                {(riskProfile.preferredRiskPercentagePerTrade / 100) *
                  riskProfile.accountSize}{" "}
                {riskProfile.accountCurrency} (
                {riskProfile.preferredRiskPercentagePerTrade}%){" "}
              </h4>
              <h4></h4>
              {/* <button onClick={() => setShouldPromptDownloadOnce(true)}> */}
              {/*   Download All */}
              {/* </button> */}
              <table>
                <thead>
                  <tr>
                    <th>tradeId</th>
                    <th>type</th>
                    <th>market</th>
                    <th>risk</th>
                    <th>stoploss</th>
                    <th>lotSize</th>
                    <th>useAvgProfitPercentage</th>
                    <th>warnings</th>
                  </tr>
                </thead>
                <tbody>{renderPresetComponents(riskProfile)}</tbody>
              </table>
            </>
          );
        })}
    </div>
  );

  function renderPresetComponents(riskProfile: RiskProfile) {
    return tradeIds.map((tradeId) => {
      return (
        <Preset
          tradeId={tradeId}
          shouldPromptDownloadOnce={shouldPromptDownloadOnce}
          riskProfile={riskProfile}
        />
      );
    });
  }
}
