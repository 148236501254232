import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveSheetTradesWithMarkets } from "../../functions/selector-functions";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import SheetTradeManager from "../sheet-trades/sheet-trade-manager";
import { sheets } from "../trade-selection/trade-selection";
import { BlackSwanDisplay } from "../black-swan-display/black-swan-display";

export default function Dashboard() {
  const activeSheetTrades = useSelector(selectActiveSheetTradesWithMarkets);

  return (
    <div>
      <h1>Dashboard</h1>
      {/* {activeSheetTrades.length > 0 && ( */}
      <div style={{ display: "grid" }}>
        {sheets.map((sheet) => {
          let sheetTrades = activeSheetTrades.filter(
            (sheetTrade) => sheetTrade.sheetId === sheet.id
          );
          let trades = sheetTrades.map((sheetTrade) => sheetTrade.trade);
          return (
            <div style={{ gridColumn: 1 }}>
              <h3>{sheet.name}</h3>
              <div style={{ maxWidth: "600px" }}>
                <TradeDistributionBarChart activeSheetTrades={sheetTrades} />
              </div>
              {trades.length > 0 && (
                <BlackSwanDisplay trades={trades} riskPercentage={5} />
              )}
            </div>
          );
        })}
        {/* <div style={{ gridColumn: 2 }}> */}
        {/*   <h3>Sheet D</h3> */}
        {/*   <div style={{ maxWidth: "600px" }}> */}
        {/*     <TradeDistributionBarChart */}
        {/*       activeSheetTrades={activeSheetTrades.filter( */}
        {/*         (sheetTrade) => sheetTrade.sheetId === sheetDId */}
        {/*       )} */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}

        {/* {activeSheetTrades.map((sheetTrade) => { */}
        {/*   return ( */}
        {/*     <div> */}
        {/*       {sheetTrade.tradeId} {sheetTrade.tradeStage} */}
        {/*     </div> */}
        {/*   ); */}
        {/* })} */}
      </div>
      <div>
        <SheetTradeManager />
      </div>
      {/* )} */}
    </div>
  );
}

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Trade Distribution Chart",
    },
  },
  onAnimationComplete: function () {
    var ctx = this.chart.ctx;
    ctx.font = this.scale.font;
    ctx.fillStyle = this.scale.textColor;
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";

    this.datasets.forEach(function (dataset) {
      dataset.bars.forEach(function (bar) {
        ctx.fillText(bar.value, bar.x, bar.y - 5);
      });
    });
  },
};

function TradeDistributionBarChart({ activeSheetTrades }) {
  const [data, setData] = useState(null);
  useEffect(() => {
    let labels = activeSheetTradesToLabels(activeSheetTrades);
    let shorts = labels.map((security) => {
      return activeSheetTrades.filter((sheetTrade) => {
        if (sheetTrade.trade.market.security.toLowerCase().includes("forex")) {
          let firstPart = sheetTrade.trade.market.symbol.substr(0, 3);
          let secondPart = sheetTrade.trade.market.symbol.substr(3, 3);
          if (firstPart === security && sheetTrade.trade.type === "Short")
            return true;
          else if (secondPart === security && sheetTrade.trade.type === "Long")
            return true;
        } else if (
          sheetTrade.trade.market.security
            .toLowerCase()
            .includes("commodities") &&
          sheetTrade.trade.market.symbol === security &&
          sheetTrade.trade.type === "Short"
        )
          return true;
        return (
          sheetTrade.trade.market.security === security &&
          sheetTrade.trade.type === "Short"
        );
      }).length;
    });

    let longs = labels.map((security) => {
      return activeSheetTrades.filter((sheetTrade) => {
        if (sheetTrade.trade.market.security.toLowerCase().includes("forex")) {
          let firstPart = sheetTrade.trade.market.symbol.substr(0, 3);
          let secondPart = sheetTrade.trade.market.symbol.substr(3, 3);
          if (firstPart === security && sheetTrade.trade.type === "Long")
            return true;
          else if (secondPart === security && sheetTrade.trade.type === "Short")
            return true;
        } else if (
          sheetTrade.trade.market.security
            .toLowerCase()
            .includes("commodities") &&
          sheetTrade.trade.market.symbol === security &&
          sheetTrade.trade.type === "Long"
        )
          return true;
        return (
          sheetTrade.trade.market.security === security &&
          sheetTrade.trade.type === "Long"
        );
      }).length;
    });
    let data = {
      labels,
      datasets: [
        {
          label: "Short",
          data: shorts,
          backgroundColor: `red`,
        },
        {
          label: "Long",
          data: longs,
          backgroundColor: `blue`,
        },
      ],
    };
    setData(data);
  }, [activeSheetTrades]);
  function activeSheetTradesToLabels(activeSheetTrades) {
    let securities = [];
    for (let sheetTrade of activeSheetTrades) {
      if (sheetTrade.trade.market.security.toLowerCase().includes("forex")) {
        let firstPart = sheetTrade.trade.market.symbol.substr(0, 3);
        let secondPart = sheetTrade.trade.market.symbol.substr(3, 3);
        securities.push(firstPart, secondPart);
      } else if (
        sheetTrade.trade.market.security.toLowerCase().includes("commodities")
      )
        securities.push(sheetTrade.trade.market.symbol);
      else securities.push(sheetTrade.trade.market.security);
    }

    return Array.from(new Set(securities));
  }
  if (data) return <Bar options={options} data={data} />;
  return <div />;
}
