import { RootState } from "..";

export function selectActiveSheetTradesWithMarkets(state: RootState) {
  let activeSheetTrades = [...state.sheetState.sheetTrades].filter(
    (sheetTrade) => {
      return sheetTrade.tradeStage !== 0 && sheetTrade.tradeStage !== 4;
    }
  );
  let markets = [...state.marketsState.markets];
  return activeSheetTrades.map((sheetTrade) => {
    let matchedMarket = markets.find(
      (market) => market.id === sheetTrade.trade.marketId
    );
    let trade = { ...sheetTrade.trade };
    if (matchedMarket) trade.market = matchedMarket;
    return Object.assign({}, sheetTrade, { trade });
  });
}
