import React from "react";
import { useSelector } from "react-redux";
import { MultiValue } from "react-select";
import Select from "react-select";
import { RootState } from "../..";

const forexSecurities = ["Forex", "Forex Exotic"];
export default function MarketsSelectNew({
  selectedMarketOptions,
  setSelectedMarketOptions,
}: {
  selectedMarketOptions: MultiValue<{ label: string; value: number }>;
  setSelectedMarketOptions: (
    marketIds: MultiValue<{ label: string; value: number }>
  ) => any;
}) {
  const marketOptions = useSelector((state: RootState) => {
    let markets = state.marketsState.markets;
    let marketOptions: { label: string; value: number | number[] }[] =
      state.marketsState.markets.map((market) => {
        return { label: market.symbol, value: market.id };
      });
    let forexSymbols = state.marketsState.markets
      .filter((market) => forexSecurities.includes(market.security))
      .map((market) => market.symbol);
    let splitForexSymbols: string[] = [];
    forexSymbols.forEach((symbol) => {
      splitForexSymbols.push(symbol.substring(0, 3));
      splitForexSymbols.push(symbol.substring(3, 6));
    });

    let uniqueCurrencies = Array.from(new Set(splitForexSymbols));
    uniqueCurrencies.forEach((currency) => {
      marketOptions.push({
        value: markets
          .filter((market) => market.symbol.includes(currency))
          .map((market) => market.id),
        label: currency,
      });
    });
    let uniqueSecurities = state.marketsState.markets
      .map((market) => market.security)
      .filter((security, index, self) => self.indexOf(security) === index);
    uniqueSecurities.map((security) =>
      marketOptions.push({
        value: markets
          .filter((market) => security === market.security)
          .map((market) => market.id),
        label: security,
      })
    );
    marketOptions.push({
      value: markets.map((market) => market.id),
      label: "ALL",
    });
    marketOptions = marketOptions.reverse();
    return marketOptions;
  });
  return (
    <Select
      value={selectedMarketOptions.map<{
        label: string;
        value: number | number[];
      }>((marketOption) => {
        return { label: marketOption.label, value: marketOption.value };
      })}
      isMulti
      options={marketOptions}
      onChange={onChange}
    />
  );

  function onChange(
    newValue: MultiValue<{ label: string; value: number | number[] }>
  ) {
    let newOptions: { label: string; value: number }[] = [];
    //flatten values
    for (let value of newValue) {
      if (Array.isArray(value.value)) {
        for (let marketId of value.value) {
          let matchedOption = marketOptions.find(
            (marketOption) => marketOption.value === marketId
          );
          if (matchedOption && !Array.isArray(matchedOption.value)) {
            let numberValue: number = matchedOption.value;
            newOptions.push({
              label: matchedOption.label,
              value: numberValue,
            });
          }
        }
      } else {
        let numberValue: number = value.value;
        newOptions.push({ label: value.label, value: numberValue });
      }
    }
    //remove dupes
    newOptions = newOptions.reduce<{ label: string; value: number }[]>(
      (list, value, index) => {
        if (!list.find((innerOption) => innerOption.value === value.value))
          list.push(value);
        return list;
      },
      []
    );
    setSelectedMarketOptions(newOptions);
  }
}
