import React, { Component, ReactElement, useEffect, useState } from "react";

export type BEDVConfig = {
  changeableFields: string[];
  editable: boolean;
  saveFunction: (changedEntity: any) => Promise<boolean>;
  maxArrayLength: number;
};

const standardConfig = {
  changeableFields: [],
  // changeableFieldsExample:["symbol","security"],
  editable: false,
  saveFunction: async (changedEntity: any) => {
    return true;
  },
  maxArrayLength: 100,
} as BEDVConfig;

export default function BasicEditableDetailView({
  entity,
  config = standardConfig,
}: {
  entity: any;
  config: BEDVConfig;
}) {
  const [editing, setEditing] = useState<boolean>(false);
  const [entityCopy, setEntityCopy] = useState<any>(entity);

  useEffect(() => {
    setEntityCopy(entity);
  }, [entity]);

  return (
    <div>
      <button hidden={editing} disabled={!config.editable} onClick={edit}>
        Edit
      </button>
      <button hidden={!editing} onClick={cancelEditing}>
        cancel
      </button>
      <button hidden={!editing} onClick={save}>
        save
      </button>
      {editing ? renderForm() : renderView()}
    </div>
  );

  function renderForm() {
    return (
      <form>
        {Object.keys(entity).map((key: string) => {
          if (config.changeableFields.includes(key)) {
            let type = typeof entity[key];
            let onChangeFunction = (newValue: any) => {
              let temp = { ...entityCopy };
              temp[key] = newValue;
              setEntityCopy(temp);
            };
            let value = entityCopy[key];

            if (type === "string" || !value)
              return (
                <div>
                  <label>{key}</label>
                  <input
                    type={"text"}
                    value={value}
                    onChange={(event) => onChangeFunction(event.target.value)}
                  />
                </div>
              );
            else if (type === "number")
              return (
                <div>
                  <label>{key}</label>
                  <input
                    type={"number"}
                    value={value}
                    onChange={(event) => onChangeFunction(event.target.value)}
                  />
                </div>
              );
            else if (type === "boolean")
              return (
                <div>
                  <label>{key}</label>
                  <input
                    type={"checkbox"}
                    checked={value}
                    onChange={(event) => onChangeFunction(event.target.checked)}
                  />
                </div>
              );
          }
          return <div>{key}: ?</div>;
        })}
      </form>
    );
  }

  function renderView() {
    return <div>{recursiveRender("root", entity)}</div>;
  }

  function recursiveRender(key: string, value: any) {
    let jsx: ReactElement[] = [];
    let type = typeof value;
    if (!value) snb();
    else if (type === "object" && value.length) array();
    else if (type === "object" && Object.keys(value)) object();
    else if (type === "string" || type === "number" || type === "boolean")
      snb();

    return jsx;

    function array() {
      let counter = 0;
      for (let innerValue of value) {
        if (counter >= config.maxArrayLength) break;
        jsx = jsx.concat(recursiveRender(key, innerValue));
        counter++;
      }
    }

    function object() {
      let keys = Object.keys(value);
      for (let key of keys) {
        let innerValue = value[key];
        let innerType = typeof innerValue;
        jsx = jsx.concat(recursiveRender(key, innerValue));
      }
    }

    function snb() {
      jsx.push(
        <div>
          {key}: {value}
        </div>
      );
    }
  }

  function edit() {
    setEditing(true);
  }

  function cancelEditing() {
    setEditing(false);
    setEntityCopy(entity);
  }

  async function save() {
    await config.saveFunction(entityCopy);
    setEditing(false);
  }
}
