export function getHowLongAgoString(date: Date) {
  let currentDate = new Date();
  date = new Date(date);

  let deltaTimeInMs = currentDate.getTime() - date.getTime();

  let deltaTimeInS = deltaTimeInMs / 1000;

  let deltaTimeInMin = deltaTimeInS / 60;

  let deltaTimeInHours = deltaTimeInMin / 60;

  if (deltaTimeInHours > 24) {
    return `${(deltaTimeInHours / 24).toPrecision(2)} days ago`;
  } else if (deltaTimeInHours < 1) {
    return `${deltaTimeInMin.toPrecision(2)} minutes ago`;
  } else {
    return `${deltaTimeInHours.toPrecision(2)} hours ago`;
  }
}

const DAY_IN_MILLISECONDS = 86400000;

export function dateToNonWeekendDayDate(date: Date) {
  let correctDate;
  if (date.getDay() === 0)
    correctDate = new Date(date.getTime() + DAY_IN_MILLISECONDS);
  else if (date.getDay() === 6)
    correctDate = new Date(date.getTime() + 2 * DAY_IN_MILLISECONDS);
  else correctDate = date;
  return correctDate;
}

export function secondsToHour(seconds: number) {
  return Math.floor(seconds / 60 / 60);
}

export function secondsToRestMinutes(seconds: number) {
  return (seconds / 60) % 60;
}

export function fixLeadingZerosForFaultyISO8601DateString(string: string) {
  let [datePart, timePart] = string.split("T");

  let [year, month, day] = datePart.split("-");
  let [hour, minutes, seconds] = timePart.split(":");

  let hadZ = false;
  if (seconds.includes("Z")) {
    hadZ = true;
    seconds = seconds.replace("Z", "");
  }

  month = addLeadingZero(2, month);
  day = addLeadingZero(2, day);

  hour = addLeadingZero(2, hour);
  minutes = addLeadingZero(2, minutes);
  seconds = addLeadingZero(2, seconds);

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}${
    hadZ === true ? "Z" : ""
  }`;
}

export function addLeadingZero(requiredLength: number, string: string) {
  let newString = string;

  while (newString.length < requiredLength) {
    newString = `0${newString}`;
  }

  return newString;
}

export function secondsToHourMinuteTimeString(seconds: number) {
  let minutes = Math.floor((seconds / 60) % 60);
  let hours = Math.floor(seconds / 60 / 60);
  return `${addLeadingZero(2, hours.toString())}:${addLeadingZero(
    2,
    minutes.toString()
  )}`;
}

export function dateToBusinessDay(date: Date) {
  date = new Date(date);
  let bDay = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
  return bDay;
}

export function diffInDays(date1: Date, date2: Date): number {
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return Math.abs(Math.round(diffInDays));
}
