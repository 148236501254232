import React, {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  useState,
} from "react";
import "./delayed-input.scss";

let timeout: any;

export default function DelayedInput({
  value,
  type,
  onChange,
  timeoutInMs,
  placeholder,
  style = {},
}: {
  value: any;
  type: HTMLInputTypeAttribute;
  onChange: (value: any) => any;
  timeoutInMs: number;
  placeholder: string;
  style?: CSSProperties;
}) {
  const [bufferedValue, setBufferedValue] = useState(value);
  const [changing, setChanging] = useState(false);

  return (
    <input
      className={changing ? "loading" : ""}
      value={bufferedValue}
      type={type}
      onChange={handleOnChange}
      placeholder={placeholder}
      style={style}
    />
  );

  function handleOnChange(event: ChangeEvent<any>) {
    let tempValue = event.target.value;
    setBufferedValue(tempValue);
    if (changing && timeout) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        onChange(tempValue);
        setChanging((old) => !old);
      }, timeoutInMs);
    } else {
      setChanging((old) => !old);
      timeout = setTimeout(() => {
        onChange(tempValue);
        setChanging((old) => !old);
      }, timeoutInMs);
    }
  }
}
