import { Quote } from "@backend/entities/quote";
import React from "react";
import Table from "../table/table";

const columns = [
  {
    Header: "Date",
    accessor: "date", // accessor is the "key" in the data
  },
  {
    Header: "Open",
    accessor: "open",
  },
  {
    Header: "High",
    accessor: "high",
  },
  {
    Header: "Low",
    accessor: "low",
  },
  {
    Header: "Close",
    accessor: "close",
  },
];

export default function QuotesReactTable({ quotes }: { quotes: Quote[] }) {
  return <Table columns={columns} data={quotes} />;
}
