import { AccountDataPoint } from "@backend/entities/account-data-point";
import { sortByDateDesc } from "./sort-functions";

export function accountDataPointsReduceToLastPerDay(
  accountDataPoints: AccountDataPoint[]
) {
  let sortedAccountDataPoints = accountDataPoints.sort((a, b) =>
    sortByDateDesc(a.timestamp, b.timestamp)
  );
  let reducedAccountDataPoints = sortedAccountDataPoints.reduce<
    AccountDataPoint[]
  >((accountDataPoints, accountDataPoint, index) => {
    let dateMatch = accountDataPoints.find((innerAccountDataPoint) => {
      let dateA = new Date(accountDataPoint.timestamp);
      let dateB = new Date(innerAccountDataPoint.timestamp);
      return (
        dateA.getDate() === dateB.getDate() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getFullYear() === dateB.getFullYear()
      );
    });
    if (!dateMatch) return accountDataPoints.concat(accountDataPoint);
    return accountDataPoints;
  }, []);
  return reducedAccountDataPoints;
}
