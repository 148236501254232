import { Market } from "@backend/entities/market";
import { Trade } from "@backend/entities/trade";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import {
  getSheetDTradeSelection,
  getSheetSouhaibV1TradeSelection,
  getSouhaibV1TradeSelection,
} from "../../requests/trade-selection-requests";
import { Sheet } from "../../types/sheet";
import { SpinnerIcon } from "../icons/spinner-icon";
import DelayedInput from "../inputs/delayed-input";
import { SeasonalDisplayComponent } from "../seasonal-displays/seasonal-display-component";
import SheetIdSelect from "../selects/sheet-id-select";

export function SheetSouhaibV1({ sheet }: { sheet: Sheet }) {
  // const currentSheetTrades = useSelector((state: RootState) => {
  //   return state.sheetState.sheetTrades.filter(
  //     (sheetTrade) => sheetTrade.tradeStage === 1
  //   );
  // });

  const [date, setDate] = useState<Date>(new Date());
  const [blackListedTradeIds, setBlackListedTradeIds] = useState<string>(
    localStorage.getItem(`blackListedTradeIds${sheet.id}`) || ""
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [tradesForToday, setTradesForToday] = useState<Trade[]>([]);
  const [futureTrades, setFutureTrades] = useState<Trade[]>([]);
  const markets = useSelector((state: RootState) => state.marketsState.markets);
  const sheetTrades = useSelector((state: RootState) => {
    return state.sheetState.sheetTrades;
  });

  const [stage1Trades, setStage1Trades] = useState<Trade[]>([]);

  // useEffect(() => {
  //   let stage1TradesWithMarkets = [];
  //   let marketsMap = new Map<number, Market>(
  //     markets.map((market) => [market.id, market])
  //   );
  //   for (let trade of sheetTrades
  //     .filter(
  //       (sheetTrade) =>
  //         sheetTrade.sheetId === sheet.id && sheetTrade.tradeStage === 1
  //     )
  //     .map((sheetTrade) => sheetTrade.trade)) {
  //     let tradeCopy = { ...trade };
  //     let market = marketsMap.get(trade.marketId);
  //     if (market) {
  //       tradeCopy.market = market;
  //       stage1TradesWithMarkets.push(tradeCopy);
  //     }
  //   }
  //   setStage1Trades(stage1TradesWithMarkets);
  // }, [markets, sheetTrades]);

  useEffect(() => {
    localStorage.setItem(`blackListedTradeIds${sheet.id}`, blackListedTradeIds);
  }, [blackListedTradeIds, sheet]);
  useEffect(() => {
    setLoading(true);
    getSheetSouhaibV1TradeSelection(date, sheet, blackListedTradeIds)
      .then((response) => {
        setTradesForToday(response.data.data.tradesForToday);
        setFutureTrades(response.data.data.futureTrades);
        setStage1Trades(response.data.data.activeTrades);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [sheet, date, blackListedTradeIds]);
  return (
    <div>
      <h1>{sheet.name}</h1>
      <ReactDatePicker
        selected={date}
        onChange={(date) => {
          if (date) setDate(date);
        }}
      />
      <div>Black listed trades :</div>

      <div>
        (Either match the id or use{" "}
        <a
          href={
            "https://www.postgresql.org/docs/current/functions-matching.html"
          }
          target={"_blank"}
        >
          {" "}
          SQL(postgres) LIKE pattern matching
        </a>
        )
      </div>
      <DelayedInput
        value={blackListedTradeIds}
        onChange={(event) => setBlackListedTradeIds(event)}
        type={"text"}
        placeholder={"SPX5001609250945200420222022,SPX500%,etc..."}
        timeoutInMs={3000}
        style={{ width: 600 }}
      />
      {loading ? (
        <SpinnerIcon />
      ) : (
        <>
          <SeasonalDisplayComponent
            name={"stage 1 trades"}
            data={stage1Trades}
            hiddenByDefault={true}
          />
          <SeasonalDisplayComponent
            name={"trades for today"}
            data={tradesForToday}
          />
          <SeasonalDisplayComponent
            name={"future trades"}
            data={futureTrades}
            hiddenByDefault={true}
          />
        </>
      )}
      <div>{error}</div>
    </div>
  );
}
