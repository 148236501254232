import { Trade } from "@backend/entities/trade";
import React, { useEffect, useState } from "react";
import useMarket from "../../hooks/use-market";

export default function SwapAvgProfitRatioColumn({
  jsonString,
}: {
  jsonString: string;
}) {
  const [trade, setData] = useState<Trade>(JSON.parse(jsonString));

  const { market, simpleFxMarket } = useMarket(trade.marketId);

  const [swapRatio, setSwapRatio] = useState<number>(0);

  useEffect(() => {
    setData(JSON.parse(jsonString));
  }, [jsonString]);

  useEffect(() => {
    if (market && simpleFxMarket && trade) {
      let days = trade.avgBars + Math.floor(trade.avgBars / 5) * 2;

      let swap;
      if (trade.type === "Long") {
        swap =
          simpleFxMarket.swapFeeLongPerDayPerLotAndPriceInQuoteCurrency *
          simpleFxMarket.stepSize *
          days;
      } else if (trade.type === "Short") {
        swap =
          simpleFxMarket.swapFeeShortPerDayPerLotAndPriceInQuoteCurrency *
          simpleFxMarket.stepSize *
          days;
      }

      let swapRatio;
      let avgProfitPerStep;
      if (market.security.includes("Equities")) {
        avgProfitPerStep =
          (trade.avgProfitPercentage / 100) *
          (trade.type === "Long" ? simpleFxMarket.bid : simpleFxMarket.ask) *
          simpleFxMarket.stepSize *
          simpleFxMarket.contractSize;
      } else {
        avgProfitPerStep =
          trade.avgProfit *
          simpleFxMarket.stepSize *
          simpleFxMarket.contractSize;
      }
      if (!swap) return;
      if (swap > 0) {
        swapRatio = 0;
      } else {
        swapRatio = avgProfitPerStep / Math.abs(swap);
      }

      setSwapRatio(swapRatio);
    }
  }, [market, simpleFxMarket, trade]);

  return <div>{swapRatio === null ? "" : `${swapRatio}`}</div>;
}
