import React, { FormEvent, useState } from "react";
import { postDataSource } from "../../requests/data-source-requests";

export default function CreateDataSource() {
  const [statusText, setStatusText] = useState("");

  const [name, setName] = useState<string>("");
  const [link, setLink] = useState<string>("");

  return (
    <div>
      <h1>Create Data Source</h1>
      <form onSubmit={createDataSource}>
        <label>Name</label>
        <input
          type={"text"}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <label>Link (to website)</label>
        <input
          type={"text"}
          value={link}
          onChange={(event) => setLink(event.target.value)}
        />
        <input disabled={!!!name} type={"submit"} value={"create"} />
        <div>{statusText}</div>
      </form>
    </div>
  );

  function createDataSource(event: FormEvent) {
    event.preventDefault();
    let dataSourceCreationBody = {
      name,
      link,
    };
    postDataSource(dataSourceCreationBody)
      .then((response) => {
        setStatusText("data source created");
      })
      .catch((error) => setStatusText(error.message));
  }
}
