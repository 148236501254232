import React, { useEffect } from "react";
import Home from "./components/home";
import { useSelector } from "react-redux";
import { Login } from "./components/login/login";
import { RootState } from ".";

function App() {
  const loginState = useSelector((state: RootState) => state.loginState);

  if (loginState.user) {
    return <Home />;
  } else {
    return <Login />;
  }
}

export default App;
