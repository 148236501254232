import { getQuoteCollectionsHealthEndpoint } from "./endpoints";
import axios, { AxiosResponse } from "axios";
import { ReadResponseBody } from "@backend/response-bodies/read-response-body";
import { User } from "@backend/entities/user";

export function getQuoteCollectionsHealth(): Promise<
  AxiosResponse<ReadResponseBody<any>>
> {
  return axios.get(getQuoteCollectionsHealthEndpoint);
}
