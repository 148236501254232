import { RiskProfile as RiskProfileEntity } from "@backend/entities/risk-profile";
import { Currency } from "@backend/enums/currency";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import {} from "../../../../actions/users-actions";
import {
  deleteRiskProfile,
  putRiskProfile,
} from "../../../../requests/users-requests";

export default function RiskProfile({
  riskProfile,
}: {
  riskProfile: RiskProfileEntity;
}) {
  const [accountCurrency, setAccountCurrency] = useState<Currency>(
    riskProfile.accountCurrency
  );
  const dispatch = useDispatch();

  const [preferredRiskPercentagePerTrade, setPreferredRiskPercentagePerTrade] =
    useState(riskProfile.preferredRiskPercentagePerTrade);
  const [accountSize, setAccountSize] = useState(riskProfile.accountSize);

  const user = useSelector((state: RootState) => state.loginState.user);
  return (
    <form onSubmit={onSubmit}>
      <span>risk profile: {riskProfile.id} </span>
      <label>Account Currency</label>
      <input
        type={"number"}
        value={accountSize}
        onChange={(event) => setAccountSize(parseFloat(event.target.value))}
      />
      <label>Risk Percentage Per Trade</label>
      <input
        type={"number"}
        value={preferredRiskPercentagePerTrade}
        onChange={(event) =>
          setPreferredRiskPercentagePerTrade(parseFloat(event.target.value))
        }
      />
      <select
        value={accountCurrency}
        onChange={(event) => setAccountCurrency(event.target.value as Currency)}
      >
        <option value={"LTC"}>LTC</option>
        <option value={"BTC"}>BTC</option>
        <option value={"USD"}>USD</option>
        <option value={"EUR"}>EUR</option>
      </select>
      {(riskProfile.accountSize !== accountSize ||
        riskProfile.accountCurrency !== accountCurrency ||
        riskProfile.preferredRiskPercentagePerTrade !==
          preferredRiskPercentagePerTrade) && (
        <input type={"submit"} value={"SAVE"} />
      )}
      <button type={"button"} onClick={deleteThisRiskProfile}>
        DELETE
      </button>
    </form>
  );
  async function onSubmit(event: FormEvent) {
    event.preventDefault();
    await putRiskProfile(riskProfile.id, {
      accountCurrency,
      accountSize,
      preferredRiskPercentagePerTrade,
    });
  }
  async function deleteThisRiskProfile() {
    await deleteRiskProfile(riskProfile.id);
  }
}
