import { Market } from "@backend/entities/market";
import { ParsedSimpleFxMarket } from "@backend/objects/simple-fx-market";
import { useSelector } from "react-redux";
import { RootState } from "..";

export default function useMarket(marketId: number | undefined): {
  market: Market | null;
  simpleFxMarket: ParsedSimpleFxMarket | null;
} {
  const market = useSelector((state: RootState) =>
    state.marketsState.markets.find((market) => market.id === marketId)
  );
  const simpleFxMarket = useSelector((state: RootState) =>
    state.simpleFxState.parsedSimpleFxMarkets.find(
      (simpleFxMarket) => simpleFxMarket.symbol === market?.symbol
    )
  );

  if (!marketId) return { market: null, simpleFxMarket: null };
  return {
    market: !!market ? market : null,
    simpleFxMarket: !!simpleFxMarket ? simpleFxMarket : null,
  };
}
