import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { getUsersAction } from "../../../actions/users-actions";
import { postUser } from "../../../requests/users-requests";
import UserDetail from "./user-detail";

const tabs = {};

export default function Users() {
  const dispatch = useDispatch();

  const users = useSelector((state: RootState) => state.usersState.users);

  useEffect(() => {
    dispatch(getUsersAction());
  }, []);

  return (
    <div>
      <button
        onClick={async () => {
          postUser("initial", "initial@email.com");
        }}
      >
        create user
      </button>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>email</th>
            <th>username</th>
            <th>role</th>
            <th>password</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderUsers()}</tbody>
      </table>
    </div>
  );

  function renderUsers() {
    if (users) {
      return users.map((user) => <UserDetail user={user} />);
    }
    return <tr></tr>;
  }
}
