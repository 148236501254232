import React from "react";
import { useSelector } from "react-redux";
import {
  checkIfPrecisionIsAvailable,
  getPossibleColumnOptions,
} from "../../../../functions/table-column-functions";
import { ArrowUpIcon } from "../../../icons/arrow-up-icon";
import { ArrowDownIcon } from "../../../icons/arrow-down-icon";
import Select from "react-select";

export function ColumnSettingsSelectComponent({
  onChange,
  columnString,
  index,
  deleteFunction,
  moveItemUp,
  moveItemDown,
}: any) {
  const tradeTableColumnOptions = getPossibleColumnOptions();

  function onNumberInputUpdate(event: any) {
    let precision = parseInt(event.target.value);
    if (precision) {
      onChange(index, `${columnString.split(":")[0]}:${event.target.value}`);
    } else {
      onChange(index, `${columnString.split(":")[0]}`);
    }
  }

  function onSelectInputUpdate(selectedOption: any) {
    let precision = parseInt(columnString.split(":")[1]);
    if (precision) {
      onChange(index, `${selectedOption.value}:${columnString.split(":")[1]}`);
    } else {
      onChange(index, `${selectedOption.value}`);
    }
  }

  return (
    <tr className={"column-settings-select-component"}>
      <td className={"column-select"}>
        <Select
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          value={{
            value: columnString.split(":")[0],
            label: columnString.split(":")[0],
          }}
          options={tradeTableColumnOptions}
          onChange={onSelectInputUpdate}
        />
      </td>
      <td>
        {checkIfPrecisionIsAvailable(columnString.split(":")[0]) && (
          <input
            placeholder={"precision"}
            value={columnString.split(":")[1]}
            type={"number"}
            min={1}
            onChange={onNumberInputUpdate}
          />
        )}
      </td>
      <td
        className={"up-button"}
        onClick={() => {
          moveItemUp(index);
        }}
      >
        <ArrowUpIcon />
      </td>
      <td
        className={"down-button"}
        onClick={() => {
          moveItemDown(index);
        }}
      >
        <ArrowDownIcon />
      </td>
      <td
        className={"delete-button"}
        onClick={() => {
          deleteFunction(index);
        }}
      >
        X
      </td>
    </tr>
  );
}
