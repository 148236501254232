import React, { useCallback, useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import {} from "../../actions/general-actions";
import "./seasonal-table.scss";
import { Link } from "react-router-dom";
import { ArrowDownIcon } from "../icons/arrow-down-icon";
import { ArrowUpIcon } from "../icons/arrow-up-icon";
import { Trade } from "@backend/entities/trade";

export default function SeasonalTableOld({
  columns,
  data,
}: {
  columns: any;
  data: any;
}) {
  const [shownRows, setShownRows] = useState(40);

  window.onscroll = function (ev) {
    setShownRows((window.innerHeight + window.scrollY) / 31 + 10);
    // console.log((window.innerHeight + window.scrollY))
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //     // you're at the bottom of the page
    // }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Trade>(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <>
      <table className={"seasonal-table"} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            if (i < shownRows) {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    if (cell.column.id === "id") {
                      return (
                        <td
                          onClick={(e) => {
                            if (e.ctrlKey) {
                              let myjson = JSON.stringify(
                                row.original,
                                null,
                                2
                              );
                              let x = window.open();
                              if (!x) return;
                              x.document.open();
                              x.document.write(
                                "<html><body><pre>" +
                                  myjson +
                                  "</pre></body></html>"
                              );
                              x.document.close();
                            }
                          }}
                          {...cell.getCellProps()}
                        >
                          <Link to={`/trade/${row.original.id}`}>
                            {cell.render("Cell")}
                          </Link>
                        </td>
                      );
                    }
                    // else if(cell.column.id === "Add"){
                    //     if(selectedTrades.find(entry =>{return row.original.id === entry.id})){
                    //         return <td {...cell.getCellProps()} className={"remove-from-cart"} onClick={()=>{dispatch(removeTradeFromCart(row.original.id))}}>
                    //             <RemoveFromCartIcon/>
                    //         </td>
                    //     }else{
                    //         return <td {...cell.getCellProps()} className={"add-to-cart"} onClick={()=>{dispatch(addTradeToCart(row.original))}}>
                    //             <AddToCartIcon/>
                    //         </td>
                    //     }
                    //
                    // }
                    else if (cell.column.id === "type") {
                      if (cell.value === "Short") {
                        return (
                          <td className={"red"} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      } else if (cell.value === "Long") {
                        return (
                          <td className={"green"} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    } else if (cell.column.id === "winPercentage") {
                      if (cell.value >= 80) {
                        return (
                          <td
                            className={"eighty-percent"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}%
                          </td>
                        );
                      } else if (cell.value >= 70) {
                        return (
                          <td
                            className={"seventy-percent"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}%
                          </td>
                        );
                      }
                    } else if (
                      cell.column.id === "avgProfitPercentage" ||
                      cell.column.id === "stopLoss100" ||
                      cell.column.id === "stopLoss100Close"
                    ) {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}%</td>
                      );
                    }
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      {/*<button onClick={()=>{setShownRows(shownRows+20)}}>more rows</button>*/}
    </>
  );
}
